<template>
    <div>
        <a href="#" @click.prevent="showSwipe"><slot></slot></a>
        <swipe :is-active="isActive" main-class="mobile-menu" @close="hideSwipe">
            <header class="grid">
                <div class="row logo"><img src="/build/images/logo-simple.png" alt=""></div>
                <div class="row swipe-close" @click="hideSwipe"><i class="icon icon-swipe-close-white"></i></div>
            </header>
            <tabs class="mobile-menu-tabs">
                <tab name="Каталог" :selected="true">
                    <div class="list-scrollbar" v-height>
                        <div v-if="expand">
                            <a href="#" @click.prevent="goBack" class="back-link icon-wrapper">
                                <i class="icon icon-back-white"></i>
                                <span>Назад</span>
                            </a>
                            <ul>
                                <li>
                                    <a :href="current.url" class=""><span>{{ prettyName(current.name, true) }}</span></a>
                                </li>
                            </ul>
                        </div>
                        <ul :class="{ 'child-list': expand }">
                            <li v-for="category in categories">
                                <template v-if="canShowList(category.id)">
                                    <a :href="category.url" @click="goTo(category.id, $event)">
                                        <span>{{ prettyName(category.name) }}</span>
                                    </a>
                                </template>
                                <template v-else>
                                    <a :href="category.url">
                                        <span>{{ prettyName(category.name) }}</span>
                                    </a>
                                </template>
                            </li>
                        </ul>
                    </div>
                </tab>
                <tab name="Инфо">
                    <div class="list-scrollbar" v-height>
                        <slot name="infoList"></slot>
                    </div>
                </tab>
            </tabs>
        </swipe>
    </div>
</template>

<script type="text/babel">
    import Swipe from '../components/swipe.vue';
    import Tabs from '../tabs/tabs.vue';
    import Tab from '../tabs/tab.vue';
    import VueScrollbar from 'vue2-scrollbar-fork';

    export default {
        name: "MobileMenu",
        components: {
            Swipe,
            Tabs,
            Tab,
            VueScrollbar
        },
        data() {
            return {
                isActive: false,
                current: null,
                categories: []
            };
        },
        props: {
            data: { required: true, type: Array },
        },
        computed: {
            expand() {
                return this.current !== null;
            }
        },
        methods: {
            showSwipe() {
                // scroll to top due swipe height issue
                window.scrollTo(0, 0);

                this.isActive = true;
                disableScroll();
            },
            hideSwipe() {
                if (this.isActive) {
                    this.isActive = false;
                    enableScroll();
                }
            },
            findCategory(id) {
                return this.data.find((el) => el.id == id);
            },
            canShowList(parentId) {
                const category = this.findCategory(parentId);
                if (category) {
                    return category.hasOwnProperty('children') || category.hasOwnProperty('brands');
                }
                return false;
            },
            getList(parentId) {
                let parentCategory = this.findCategory(parentId);
                if (parentId && parentCategory) {
                    this.current = parentCategory;
                    if (this.current.brands) {
                        this.categories = [];
                        Object.keys(this.current.brands).forEach((letter) => {
                            this.categories.push({
                                id: null,
                                name: letter,
                                url: '/category/brand/#' + letter
                            });
                        });
                    } else {
                        this.categories = this.current.children;
                    }
                } else {
                    this.current = null;
                    this.categories = this.data;
                }
                // fix scrollbar for list update
                // setTimeout(() => this.$refs.catalogScrollbar.calculateSize(), 10);
            },
            prettyName(name, force = false) {
                if (! this.expand || force) {
                    return name.toUpperCase();
                }
                return name;
            },
            goTo(id, e) {
                if (! this.expand) {
                    e.preventDefault();
                    this.getList(id);
                }
            },
            goBack() {
                this.getList();
            }
        },
        directives: {
            /*
            * Directive for setting max-height to scroller with dynamic content
            */
            height (el) {
                const height = window.innerHeight - 187;
                el.style['max-height'] = height + 'px';
            }
        },
        created() {
            this.getList();
        }
    }
</script>