<template>
    <div class="form-container">
        <form action="" @submit.prevent="onSubmit" class="grid" @keydown="model.errors.clear()">
            <input type="text" v-model="model.email" placeholder="Введите Ваш E-mail">
            <button-loading class="btn btn-inverse" :loading="loading">ПОДПИСАТЬСЯ</button-loading>
        </form>
        <p class="help-block--success" v-if="isSuccess">Спасибо! Вы подписались на получение новостей сайта.</p>
        <p class="help-block--error" v-if="model.errors.has('email')" v-text="model.errors.get('email')"></p>
        <p>Подписавшись, Вы подтверждаете ознакомнение с "<a href="#">Правилами Конфиденциальности</a>"</p>
    </div>
</template>

<script type="text/babel">
    import { ActiveForm } from '../entities/active_form';

    export default {
        name: "Subscribe",
        props: {},
        data: () => ({
            isSuccess: false,
            loading: false,
            model: new ActiveForm({
                email: ''
            })
        }),
        computed: {},
        methods: {
            onSubmit() {
                this.loading = true;
                this.isSuccess = false;
                this.model.post('/subscribe/index/')
                    .then(r => {
                        if (r.success) {
                            this.model.reset();
                            this.isSuccess = true;
                        }
                        this.loading = false;
                    })
                    .catch(e => {
                        console.log(e);
                        this.loading = false;
                    })
            }
        },
        mounted() {
        
        },
        created() {
            
        },
    }
</script>