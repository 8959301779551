<template>
    <div class="progress-bar-container" v-show="canShow">
        <div class="hint" v-if="isDeliveryFree">
            Доставка бесплатная!
        </div>
        <div class="grid hint" v-else>
            <div class="part">До бесплатной доставки осталось</div>
            <div class="part"><span>{{ needToPay | currency }}</span></div>
        </div>
        <progressbar :init-percent="percent"></progressbar>
    </div>
</template>

<script type="text/babel">
    import Config from '../../config';
    import Progressbar from '../components/progressbar.vue';
    import MobileDetect from '../mixins/mobile_detect';

    export default {
        components: {
            Progressbar
        },
        mixins: [ MobileDetect ],
        name: "CartDeliveryProgress",
        props: {
            initCost: { required: true },
        },
        data() {
            return {
                totalCost: Config.freeDeliveryCost
            };
        },
        computed: {
            canShow() {
                if (this.isPhone && this.isDeliveryFree) {
                    return false;
                }

                return true;
            },
            cost() {
                return this.initCost;
            },
            needToPay() {
                return this.totalCost - this.cost;
            },
            percent() {
                if (this.isDeliveryFree)
                    return 100;

                return this.cost / this.totalCost * 100;
            },
            isDeliveryFree() {
                return this.needToPay <= 0;
            }
        },
    }
</script>