<template>
    <div>
        <div class="alert-danger" v-if="errorMessage">
            <header>Ой, йо-йо-й!</header>
            <p>Возникла ошибочка :(</p>
            <pre>{{ errorMessage }}</pre>
        </div>
        <form-wizard
                @on-complete="complete"
                @on-loading="setLoading"
                @on-change="handleChangeStep"
                :hide-buttons="true"
                :color="''"
                :error-color="''"
                ref="wizard">
            <template slot="title">
                <h4>ОФОРМЛЕНИЕ ЗАКАЗА</h4>
            </template>

            <!--<template slot="step" scope="props">-->
                <!--<wizard-step :tab="props.tab"-->
                             <!--:transition="props.transition"-->
                             <!--:key="props.tab.title"-->
                             <!--:index="props.index">-->
                <!--</wizard-step>-->
            <!--</template>-->

            <tab-content title="Личные данные" icon="icon icon-order-personal-data" :before-change="validateStep">
                <header>
                    <h5>ЛИЧНЫЕ ДАННЫЕ</h5>
                </header>
                <order-user :model="model" :init-loading="loadingWizard" @submit="nextTab" @back="prevTab"></order-user>
            </tab-content>

            <tab-content title="Доставка и оплата" icon="icon icon-order-delivery" :before-change="validateStep">
                <header>
                    <h5>ДОСТАВКА И ОПЛАТА</h5>
                </header>
                <order-delivery-payment :model="model" :init-loading="loadingWizard" @submit="nextTab" @back="prevTab"></order-delivery-payment>
            </tab-content>

            <!--<tab-content title="Вам понадобится" icon="icon icon-order-average" :before-change="validateStep">-->
                <!--<header>-->
                    <!--<h5>ВОЗМОЖНО ВАМ ПОНАДОБИТСЯ</h5>-->
                <!--</header>-->
                <!--<order-average :model="model" :init-loading="loadingWizard" @submit="nextTab" @back="prevTab"></order-average>-->
            <!--</tab-content>-->

            <!--<tab-content title="Подтверждение" icon="icon icon-order-complete" :before-change="validateStep">-->
                <!--<header>-->
                    <!--<h5>ПОДТВЕРЖДЕНИЕ</h5>-->
                <!--</header>-->
                <!--<div class="error-message" v-if="errorMessage">-->
                    <!--<h5>Ой йо йой!</h5>-->
                    <!--<p>Возникла ошибка, сообщите ее нам пожалуйста:</p>-->
                    <!--<code v-text="errorMessage"></code>-->
                <!--</div>-->
                <!--<order-summary :model="model" :init-loading="loadingWizard" @submit="nextTab" @back="prevTab"></order-summary>-->
            <!--</tab-content>-->

            <!--<order-totals :model="model" v-show="! isLastStep"></order-totals>-->
            <order-totals :model="model"></order-totals>
        </form-wizard>
    </div>
</template>

<script type="text/babel">
    import { FormWizard, WizardStep, TabContent } from 'vue-form-wizard';
    import { ActiveForm } from '../entities/active_form';
    import Config from '../mixins/config';
    import { mapGetters } from 'vuex';
    import CustomCheckbox from '../components/custom_checkbox.vue';
//    import OrderDelivery from './delivery.vue';
    import OrderDeliveryPayment from './delivery_payment.vue';
    import OrderUser from './user.vue';
//    import OrderPaysystem from './paysystem.vue';
    import OrderTotals from './totals.vue';
    // import OrderAverage from './average.vue';
    // import OrderSummary from './summary.vue';
    import MobileDetect from '../mixins/mobile_detect';

    export default {
        name: "OrderForm",
        components: {
            OrderDeliveryPayment,
            // OrderSummary,
            // OrderAverage,
            OrderTotals,
//            OrderPaysystem,
            OrderUser,
//            OrderDelivery,
            CustomCheckbox,
            FormWizard,
            WizardStep,
            TabContent,
        },
        mixins: [ Config, MobileDetect ],
        props: {

        },
        data: () => ({
            errorMessage: null,
            loadingWizard: false,
            isLastStep: false,
            model: new ActiveForm({
                step: 1,
                phone: '',
                alternativePhone: '',
                firstname: '',
                lastname: '',
                email: '',
                isRecipientNotMe: false,
                recipientPhone: '',
                recipientFirstname: '',
                recipientLastname: '',
                isGift: false,

                deliveryProfileId: -1,
                cityId: '',
                deliveryId: '',
                deliveryComment: '',
                paysystemId: '',
                notCallback: false,
            }),
        }),
        computed: {
            ...mapGetters(['isLogged'])
        },
        methods: {
            prevTab() {
                this.$refs.wizard.prevTab();
            },
            nextTab() {
                this.$refs.wizard.nextTab();
            },
            setLoading(value) {
                this.loadingWizard = value;
            },
            validateStep() {
                this.errorMessage = null;
                return new Promise((resolve, reject) => {
                    this.model.step = this.$refs.wizard.activeTabIndex + 1;
                    this.model.post('/order/api/validate/').then(r => {
                        if (r.success) {
                            resolve(true);
                        } else {
                            reject('Исправьте ошибки');
                        }

                        if (r.exception) {
                            this.loadingWizard = false;
                            this.errorMessage = r.exception;
                        }
                    })
                });
            },
            handleChangeStep(prevIndex, nextIndex) {
                // очистим все ошибки с прошлых шагов
                this.model.errors.clear();

                const lastIndex = this.$refs.wizard.tabs.length - 1;
                this.isLastStep = lastIndex == nextIndex;
                Event.$emit('updateOrderSummary');

                if (this.isMobile) {
                    this.$scrollTo('.wizard-tab-content');
                }

                // this.wizardStepIndex = nextIndex;
            },
            complete() {
                this.errorMessage = null;
                this.loadingWizard = true;
                this.model.post('/order/api/confirm/')
                    .then(r => {
                        if (r.exception) {
                            this.errorMessage = r.exception;
                            this.loadingWizard = false;
                        }
//                        this.loadingWizard = false;
                        if (r.redirect) {
                            window.location.href = r.redirect;
                        }
                    })
                    .catch(e => {
                        this.errorMessage = e;
                        this.loadingWizard = false;
                    });
            },
        },
        mounted() {

        },
        created() {

        }
    }
</script>