<template>
    <div class="popup" v-show="isActive" v-center="autoCenter">
        <slot></slot>
    </div>
</template>

<script type="text/babel">
    export default {
        name: "Popup",
        props: {
            isActive: {
                required: true,
                type: Boolean,
                default: false
            },
            autoCenter: {
                type: Boolean,
                default: false
            }
        },
        directives: {
            center: {
                update(el, binding) {
                    if (binding.value) {
                        const containerWidth = el.previousElementSibling.offsetWidth;
                        const styles = getComputedStyle(el);
                        const padding = parseInt(styles.paddingLeft) + parseInt(styles.paddingRight);
                        el.style.left = ((padding + el.offsetWidth - containerWidth) / 2 * -1) + 'px';
                    }
                }
            }
        }
    }
</script>