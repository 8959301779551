<template>
    <div class="city-chooser-container" v-on-clickaway="hideChoose">
        <div class="city-chooser">
            <!--В <span @click="showConfirm">{{ city.name }}</span> доставим сегодня-->
            <span @click="showConfirm">{{ city.name }}</span>
        </div>

        <popup :is-active="initConfirmModal" class="popup-city-confirm">
            <i class="icon icon-popup-close popup-close" @click.prevent="hideConfirm"></i>
            <p>Ваш город - <span>{{ city.name }}?</span></p>
            <div class="grid">
                <div class="row"><button class="btn btn-secondary" @click="confirm">Да</button></div>
                <div class="row"><a href="#" @click.prevent="showChoose">Выбрать другой населенный пункт</a></div>
            </div>
        </popup>

        <popup :is-active="initChooserModal" class="popup-city-chooser">
            <div>
                <input type="text" v-model.trim="citySearchName" placeholder="Введите ваш город или выберите из списка" autocomplete="off">
                <clip-loader :loading="loading" :color="'#4998cc'" :size="'8px'"></clip-loader>
            </div>
            <ul v-if="cityList.length">
                <li v-for="city in cityList"><a href="#" @click.prevent="setCity(city.id)">{{ city.name }}</a></li>
            </ul>
            <p v-else>Такой город не найден</p>
        </popup>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Popup from '../components/popup.vue';
    import _debounce from 'lodash/debounce';

    export default {
        name: 'CityChooser',
        components: {
            Popup
        },
        data() {
            return {
                cities: [],
                citySearchName: '',
                confirmed: false,
                initConfirmModal: false,
                initChooserModal: false,
                loading: false
            }
        },
        computed: {
            test() {
                return 1;
            },
            cityList() {
                if (this.cities.length > 0)
                    return this.cities;

                return this.getCityPrimaryList;
            },
            ...mapGetters(['city', 'getCityPrimaryList'])
        },
        methods: {
            confirm() {
                this.setCity(this.city.id);
                this.hideConfirm();
            },
            showConfirm() {
                this.initConfirmModal = true;
            },
            hideConfirm() {
                this.initConfirmModal = false;
            },
            showChoose() {
                this.initChooserModal = true;
                this.hideConfirm();
            },
            hideChoose() {
                this.initChooserModal = false;
            },
            getCities: _debounce(function() {
                let vm = this;
                this.loading = true;
                this.axios.get('/cities/', {
                    params: {
                        by_name: vm.citySearchName
                    }
                }).then(r => {
                    vm.cities = r.data;
                    vm.loading = false;
                });
            }, 500),
            setCity(cityId) {
                this.axios.get('/set-city/', {
                    params: {
                        city_id: cityId
                    }
                }).then(r => {
                    const confirmed = r.data.confirmed;
                    if (confirmed) {
                        this.confirmed = confirmed;
                        this.citySearchName = '';
//                        this.city = r.data.city;
                        this.$store.dispatch('setCity', r.data.city);
                        this.$cookie.set('city_id', r.data.city.id, { expires: '1M' });
                        this.hideChoose();
                    } else {
                        alert('Ошибка выбора города');
                    }
                });
            }
        },
        watch: {
            citySearchName() {
                this.getCities();
            }
        },
        mounted() {
            // use cookie here
            const cityId = this.$cookie.get('city_id');

            if (cityId) {
                this.setCity(cityId);
                this.confirmed = true;
                this.hideConfirm();
            } else {
//                 this.axios.get('http://ip-api.com/json?lang=ru&fields=city')
//                     .then(r => {
//                         return r.data.city;
//                     }).then(cityName => {
//                         this.axios.get('/cities/', {
//                             params: {
//                                 by_name: cityName
//                             }
//                         }).then(r => {
// //                            this.city = r.data[0];
//                             this.$store.dispatch('setCity', r.data[0]);
//                             this.showConfirm();
//                         });
//                     });
                this.axios.get('/cities/', {
                    params: {
                        by_name: 'Киев'
                    }
                }).then(r => {
                    this.$store.dispatch('setCity', r.data[0]);
                    this.showConfirm();
                });
            }

//            this.getCities();
        },
        created() {
            this.initConfirmModal = false;
            this.initChooserModal = false;
        }
    }
</script>