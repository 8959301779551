<template>
    <div class="offers-chooser" v-if="spinnerActive">
        <div class="simple" >
            <ul class="simple__list">
                <li class="simple__list-item is-disabled ">
                    <loader :loading="spinnerActive"></loader>
                </li>
            </ul>
        </div>
    </div>

    <div class="offers-chooser" v-else>


        <div class="simple" v-if="simpleChoose">
            <ul class="simple__list">
                <li v-for="offer in offers" @click="setOffer(offer.id)" class="simple__list-item" :class="listItemClass(offer)">{{ offerValue(offer) | decode }}</li>
            </ul>
        </div>
        <div class="extended" v-else>
            <span>{{ offerCutValue | decode }}</span>
            <div class="extended__overlay" v-if="showOverlay"></div>
            <a href="#" @click.prevent="showModal" class="extended__btn-choose icon-wrapper" v-if="offersCount > 1">
                <i class="icon icon-product-offer-choose"></i>
            </a>

            <modal :init-modal="externalModalActive" class="modal-offer-choose" :with-close="true" @close="hideModal">
                <div class="modal-title grid">
                    <div class="row names">
                        <h3>{{ names.productName | decode | uppercase }}</h3>
                        <h4>{{ names.originalName | decode | uppercase }}</h4>
                    </div>
                    <div class="row info">
                        <div class="code">Код товара: <span v-text="currentOffer.code"></span></div>
                        <div class="stars"><rating-widget :rating="rating.stars" :reviews-count="rating.reviewsCount" :url="currentOffer.url"></rating-widget></div>
                    </div>
                </div>
                <div class="chooser grid" itemprop="offers" itemscope="" itemtype="http://schema.org/Offer">
                    <div class="row preview">
                        <img :src="currentOffer.image.thumb" alt="">
                    </div>
                    <div class="row list-vertical" v-if="! isMobile">
                        <vue-scrollbar classes="list-scrollbar" ref="scrollbar">
                            <ul>
                                <li v-for="offer in offers" @click="setOffer(offer.id)" :class="listItemClass(offer)">
                                    <img :src="offer.image.preview" alt="" v-if="variant.preview">
                                    <span>{{ offerValue(offer) | decode }}</span>
                                    <span class="icon-container" v-if="isDiscount(offer)"><i class="icon icon-label-discount-mini"></i></span>
                                </li>
                            </ul>
                        </vue-scrollbar>
                    </div>
                    <div class="list-horizontal" :class="{ 'is-scrollable': offersCount > breakPointCount }" v-else>
                        <ul>
                            <li v-for="offer in offers" @click="setOffer(offer.id)" :class="listItemClass(offer)">
                                <span class="image"><img :src="offer.image.preview" alt=""></span>
                                <span>{{ offerValue(offer) | decode }}</span>
                                <span class="icon-container" v-if="isDiscount(offer)"><i class="icon icon-label-discount-mini"></i></span>
                            </li>
                        </ul>
                    </div>
                    <div class="current-offer-fullname" v-if="variant.preview && isMobile" v-text="offerValue(currentOffer, true)"></div>
                </div>
                <template v-if="currentOffer.canBuy">
                    <div v-if="currentOffer.isAvailable">
                        <div class="prices grid" v-if="currentOffer.isAvailable">
                            <div class="price-old" v-if="currentOffer.hasDiscount"><span v-text="currentOffer.price.old | price"></span> грн</div>
                            <div class="price-current"><span v-text="currentOffer.price.current | price"></span> грн</div>
                            <div class="discount" v-if="currentOffer.hasDiscount">экономия <span v-html="currentOffer.price.percent"></span></div>
                        </div>
                        <div><button class="btn btn-secondary btn-block" @click="addToCart">В КОРЗИНУ</button></div>
                    </div>
                    <div v-else>
                        <div class="unavailable-text">Нет в наличии</div>
                        <available-informer :init-offer="currentOffer" btn-class="btn btn-grey btn-block">УВЕДОМИТЬ О НАЛИЧИИ</available-informer>
                    </div>
                </template>
            </modal>
        </div>
    </div>
</template>

<script type="text/babel">
    import VueScrollbar from 'vue2-scrollbar-fork';
    import RatingWidget from '../review/rating_widget.vue';
    import AvailableInformer from './available_informer.vue';
    import { decode } from '../entities/helpers';

    export default {
        name: "OffersChooser",
        components: {
            VueScrollbar,
            RatingWidget,
            AvailableInformer
        },
        props: {
            currentOfferId: {
                required: true,
                default: 0
            },
            initOffers: {
                required: true,
                type: Array,
                default: []
            },
            maxCount: {
                type: Number,
                default: 3
            },
            onlyActiveOffers: {
                type: Boolean,
                default: true
            },
            names: {
                type: Object,
                default() {
                    return {
                        productName: '',
                        originalName: ''
                    };
                }
            },
            rating: {
                type: Object,
                default() {
                    return {
                        stars: 0,
                        reviewsCount: 0
                    };
                }
            }
        },
        data() {
            return {
                externalModalActive: false,
                spinnerActive: false,
                offerValueMaxLength: {
                    short: 23,
                    full: 40
                },
            };
        },
        watch: {
            initOffers: function(offers) {
                //this.$forceUpdate(); // simplest way for computed props BUT spinner helps
            }
        },
        computed: {
            offers() {
                if (this.onlyActiveOffers) {
                    return this.initOffers.filter(item => item.isAvailable);
                }
                return this.initOffers;
            },
            currentOffer() {
                return this.offers.find(item => item.id == this.currentOfferId);
            },
            offersCount() {
                return this.offers.length;
            },
            variant() {
                let firstOffer = this.currentOffer;
                if (! firstOffer) {
                    throw new Error('Не могу получить первое торговое предложение');
                }

                return firstOffer.cartVariant;
            },
            simpleChoose() {
                if (this.offersCount <= this.maxCount && this.variant.simple) {
                    return true;
                }
                return false;
            },
            variantCode() {
                return this.variant.code;
            },
            offerCutValue() {
                let cartProperty = this.currentOffer.cartProperty;
                if (cartProperty) {
                    let value = decode(cartProperty.value);
                    const max = this.offerValueMaxLength.short;
                    if (value.length > max) {
                        value = value.substring(0, max);
                    }
                    return value;
                }
                return 'null';
            },
            showOverlay() {
                let value = this.offerValue(this.currentOffer);
                const max = this.offerValueMaxLength.short;
                return value.length > max;
            },
            isMobile() {
                return this.$root.isMobile;
            },
            breakPointCount() {
                let screen = this.$root.getScreenWidth();
                return screen > 460 ? 6 : 3;
            }
        },
        methods: {
            setOffer(id) {
                this.$emit('setOffer', id);
            },
            showSpinner() {
                this.spinnerActive = true;
            },
            hideSpinner() {
                this.spinnerActive = false;
            },
            offerValue(offer, full = false) {
                let cartProperty = offer.cartProperty;
                if (cartProperty) {
                    let value = decode(cartProperty.value);
                    if (full) return value;

                    if (this.variant.preview && this.isMobile) {
                        // TODO: улучшить логику "расскройки" тона торгового предложения
                        value = value.substring(0, 3);
                    } else {
                        const max = this.offerValueMaxLength.full;
                        if (value.length > max) {
                            value = value.substring(0, max) + '...';
                        }
                    }
                    return value;
                }
                return 'null';
            },
            showModal() {
                // load external data
                this.externalModalActive = true;
                // fix scrollbar for modal show
                // only for non mobile devices
                if (! this.isMobile) setTimeout(() => this.$refs.scrollbar.calculateSize(), 10);
            },
            hideModal() {
                this.externalModalActive = false;
            },
            listItemClass(offer) {
                let classes = [];
                if (!offer.isAvailable) classes.push('is-disabled');
                if (offer.id == this.currentOfferId) classes.push('is-active');
                return classes;
            },
            isDiscount(offer) {
                return offer.getLabel('discount').active;
            },
            addToCart() {
                // pixel track code
                fbq('track', 'AddToCart', {
                    content_ids: (this.currentOffer.product_id + '_' + this.currentOffer.id),
                    content_type: 'product'
                });

                this.externalModalActive = false;
                this.$emit('addToCart');
            },
        }
    };
</script>