<template>
    <div class="pagination" :class="{ 'empty': false }" v-if="canShow">
        <ul>
            <li v-if="canPrev"><a :href="firstLink.url" @click.prevent="setPage(firstLink)">{{ firstLink.page + 1 }}</a></li>
            <li v-if="canPrev">...</li>
            <li v-for="link in activeLinks" :class="{ 'is-active': isCurrent(link.page) }">
                <a :href="link.url" v-if="! isCurrent(link.page)" @click.prevent="setPage(link)">{{ link.page + 1 }}</a>
                <span v-else="">{{ link.page + 1 }}</span>
            </li>
            <li v-if="canNext">...</li>
            <li v-if="canNext"><a :href="lastLink.url" @click.prevent="setPage(lastLink)">{{ lastLink.page + 1 }}</a></li>
        </ul>
    </div>
</template>

<script type="text/babel">
    export default {
        name: "Pagination",
        props: {
            pages: {
                required: true,
                type: [Object, Array]
            },
            page: {
                required: true,
                type: [Number, String]
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            supportState: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                current: this.page,
                firstLink: null,
                lastLink: null,
                simpleMode: false,
            };
        },
        computed: {
            hidden() {
                return this.disabled;
            },
            canShow() {
                return ! this.hidden && this.activeLinks.length > 0;
            },
            last() {
                return this.pages.length;
            },
            total() {
                return this.last - 1;
            },
            canPrev() {
                return this.current >= 3 && ! this.simpleMode;
            },
            canNext() {
                return (this.total - this.current) >= 3 && ! this.simpleMode;
            },
            activeLinks() {
                let links = this.pages;
                const last = this.last;
                const total = this.total;
                const current = this.current;
                // если только одна страница, то вообще не выводим пагинацию
                if (total == 0) {
                    return [];
                }

                // если не хватает страниц для полного отображения крутой пагинации, то выводим все что есть
                if (total < 8) {
                    this.simpleMode = true;
                    return links.slice(0, last);
                }

                this.simpleMode = false;
                this.firstLink = links[0];
                this.lastLink = links[total];

                // первая страница
                if (current == 0) {
                    return links.slice(0, 3);
                }

                // 2-3 страница
                if (current < 3) {
                    return links.slice(0, 4);
                }

                // последня страница
                if (total == current) {
                    return links.slice(-3);
                }

                // предпоследние две страницы
                if ((total - current) < 3) {
                    return links.slice(-4);
                }

                return links.slice(current - 1, current + 2);
            }
        },
        methods: {
            isCurrent(page) {
                return this.current == page;
            },
            /**
             * Set active pagination link and update location.href
             * @param index
             */
            setPage(link) {
                if (this.current != link.page) {
                    this.current = link.page;

                    this.$emit('updatePageIndex', link.page);

                    if (this.supportState) {
                        history.pushState(null, document.title, link.url);
                        Event.$emit('pushState', {url: link.url});
                    }
                }
            },
        },
        watch: {
            page(val) {
                this.current = val;
            }
        }
    }
</script>