/* Класс для работы с товаром и его торговыми предложениями */
class Base {
    getPropertyByCode(code) {
        return this.properties.find((el) => el.code == code);
    }

    isEmptyImage() {
        return this.images.length == 0;
    }
}

export class Product extends Base {
    constructor(data) {
        super();
        this.data = data;
        this.offers = [];

        for (let field in data) {
            if (field == 'offers') {
                data[field].forEach((offer) => {
                    this.offers.push(new Offer(offer));
                });
            } else {
                this[field] = data[field];
            }
        }

        this.labelsCollection = null;
    }

    getOffer() {
        // if (this.currentOfferIndex in this.offers) {
        //     return this.offers[this.currentOfferIndex];
        // }
        let offer = this.offers.find(item => item.id == this.currentOfferId);
        if (offer) {
            return offer;
        } else {
            return this.offers[0];
        }
    }

    getLabels() {
        if (this.labelsCollection) {
            return this.labelsCollection;
        }

        const offer = this.getOffer();
        let labels = this.labels.concat(offer.labels);
        return labels;
    }

    getLabel(key) {
        const labels = this.getLabels();
        if (labels.length > 0) {
            return labels.find((el) => el.key == key );
        }
        return;
    }

    setOffer(id) {
        this.currentOfferId = id;
        this.labelsCollection = null;
    }
}

export class Offer extends Base {
    constructor(data) {
        super();
        this.data = data;

        for (let field in data) {
            this[field] = data[field];
        }
    }

    // hasDiscount() {
    //     return Boolean(this.hasDiscount);
    // }

    getLabel(key) {
        return this.labels.find((el) => el.key == key );
    }
}