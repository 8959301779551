<template>
    <div class="addressbook-container profile-container">
        <header>АДРЕСНАЯ КНИГА</header>
        <p class="label" v-if="primaryProfileUpdated">Профиль по умолчанию обновлен</p>
        <p class="label" v-if="profileUpdateMessage" v-text="profileUpdateMessage"></p>
        <div class="category-loader" v-if="loading">
            <loader :loading="loading"></loader>
        </div>
        <div class="profiles-list-container"v-if="! isPhone">
            <div class="profiles-list">
                <delivery-profile :profile="profile" :key="profile.id" v-for="profile in activeProfiles" @setPrimary="setPrimary" @showEditForm="showEditForm"></delivery-profile>
                <div class="profile-item profile-item-add">
                    <a href="#" @click.prevent="showNewForm">
                        <p><i class="icon icon-profile-delivery-profile-add"></i></p>
                        <p>Добавить профиль</p>
                    </a>
                </div>
            </div>
        </div>
        <div class="profiles-list-select" v-else>
            <div class="form-group">
                <label>Профили:</label>
                <custom-select :values="activeProfilesList" v-model="currentProfileId" class="form-control"></custom-select>
            </div>
            <div class="form-group">
                <button class="btn btn-block btn-primary" @click="setPrimary(currentProfileId)" :disabled="currentProfileId < 0 || currentProfile.primary">
                <span class="icon-wrapper">
                    <span>ВЫБРАТЬ ПО УМОЛЧАНИЮ</span>
                    <i class="icon icon-profile-addressbook-edit"></i>
                </span>
                </button>
            </div>
            <div class="form-group">
                <button class="btn btn-block btn-secondary" @click="showEditForm(currentProfile)" :disabled="currentProfileId < 0">
                <span class="icon-wrapper">
                    <span>РЕДАКТИРОВАТЬ ПРОФИЛЬ</span>
                    <i class="icon icon-profile-addressbook-set-primary"></i>
                </span>
                </button>
            </div>
            <div class="form-group">
                <button class="btn btn-block btn-link" @click="showNewForm">
                <span class="icon-wrapper">
                    <span>ДОБАВИТЬ ПРОФИЛЬ</span>
                    <i class="icon icon-profile-addressbook-add"></i>
                </span>
                </button>
            </div>
        </div>

        <div class="edit-form" v-if="isEditFormShown">
            <p class="label">{{ model.id ? 'Редактирование профиля' : 'Создание профиля' }}</p>

            <form action="" @submit.prevent="handleSubmit" @keydown="model.errors.clear($event.target.name)">
                <div class="grid">
                    <div class="row">
                        <div class="form-group">
                            <label>Название профиля:</label>
                            <input type="text" name="name" v-model="model.name" class="form-control" placeholder="Введите название профиля">
                            <p class="help-block-error" v-if="model.errors.has('name')" v-text="model.errors.get('name')"></p>
                        </div>
                        <div class="form-group">
                            <label>Город:</label>
                            <custom-select :values="cityList" :autocomplete="true" :auto-choose="true" @autocomplete="setCityName"
                                           v-model="model.cityId" class="form-control"
                                           @keydown="model.errors.clear('cityId')"
                                           @change="getDeliveryList" ref="citySelect"></custom-select>
                            <p class="help-block-error" v-if="model.errors.has('cityId')" v-text="model.errors.get('cityId')"></p>
                        </div>
                        <div class="form-group">
                            <label>Способ доставки:</label>
                            <custom-select :values="deliveryList" v-model="model.deliveryId"
                                           class="form-control" @keydown="model.errors.clear('deliveryId')"
                                           v-on:keydown="getDeliveryFields"
                                           :auto-choose="true" :disabled="! model.cityId" ref="deliverySelect"></custom-select>
                            <p class="help-block-error" v-if="model.errors.has('deliveryId')" v-text="model.errors.get('deliveryId')"></p>
                        </div>
                    </div>
                    <div class="row">
                        <template v-for="field in deliveryFields">
                            <div class="form-group" :class="'field-' + field.name + '-container'" v-show="! field.hidden">
                                <label v-if="field.label">{{ field.label }}<span class="required" v-if="field.required">*</span>:</label>

                                <template v-if="field.type == 'text'">
                                    <input type="text" :name="field.name" v-model="model[field.name]" class="form-control" :placeholder="field.placeholder">
                                </template>
                                <template v-else-if="field.type == 'select'">
                                    <custom-select :values="field.values" v-model="model[field.name]" class="form-control" :auto-choose="field.options.autoChoose" :live-search="field.options.liveSearch" :limit="field.options.limit" :placeholder="field.placeholder" @keydown="model.errors.clear(field.name)"></custom-select>
                                </template>

                                <p class="help-block-error" v-if="model.errors.has(field.name)" v-text="model.errors.get(field.name)"></p>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="form-group">
                    <button-loading :loading="deliveryFieldsLoading" class="btn btn-primary btn-big btn-block">СОХРАНИТЬ</button-loading>
                </div>
                <div class="form-group" v-if="model.id && ! model.primary">
                    <button type="button" class="btn btn-block btn-link" @click="handleRemove">Удалить профиль</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script type="text/babel">
    import DeliveryProfile from './delivery_profile.vue';
    import { ActiveForm } from '../entities/active_form';
    import City from '../mixins/city';
    import MobileDetect from '../mixins/mobile_detect';
    import {mapGetters} from 'vuex';

    export default {
        components: {
            DeliveryProfile,
        },
        name: "ProfileAddressbook",
        mixins: [ City, MobileDetect ],
        props: {},
        data: () => ({
            loading: false,
            profiles: [],
            primaryProfileUpdated: false,
            profileUpdateMessage: '',
            isEditFormShown: false,
            model: new ActiveForm({
                id: '',
                name: '',
                primary: false,
                cityId: '',
                deliveryId: '',
            }),
            currentProfileId: -1,
            deliveryFieldsLoading: false,
            deliveryList: [],
            deliveryFields: [],
        }),
        computed: {
            activeProfiles() {
                return this.profiles.filter(item => ! item.isRemoved);
            },
            activeProfilesList() {
                return this.activeProfiles.map(item => ({
                    value: item.id,
                    text: item.name,
                }));
            },
            currentProfile() {
                return this.activeProfiles.find(item => item.id == this.currentProfileId);
            },
            ...mapGetters(['city'])
        },
        methods: {
            setPrimary(profileId) {
                let query = new ActiveForm({
                    profile_id: profileId
                });

                query.post('/profile/addressbook/set-primary/')
                    .then(r => {
                        if (r.success) {
                            this.primaryProfileUpdated = true;
                            this.activeProfiles.forEach(item => {
                                if (item.id == profileId) {
                                    item.primary = true;
                                } else {
                                    item.primary = false;
                                }
                            });
                            setTimeout(() => this.primaryProfileUpdated = false, 3000);
                        }
                    })
            },
            showNewForm() {
                this.isEditFormShown = true;
                setTimeout(() => {
                    this.model.id = '';
                    this.model.name = '';
                    this.model.primary = false;
                    this.model.cityId = this.city.id;
                    this.$refs.citySelect.$emit('change', this.city.id);
                    this.model.deliveryId = '';
                    // this.$refs.deliverySelect.$emit('change', this.model.deliveryId);
                }, 100);
            },
            showEditForm(profile) {
                this.isEditFormShown = true;
                this.activeProfiles.forEach(item => item.isActive = false);
                profile.isActive = true;
                setTimeout(() => {
                    this.model.id = profile.id;
                    this.model.name = profile.name;
                    this.model.primary = profile.primary;
                    this.model.cityId = profile.cityId;
                    this.getDeliveryList()
                        .then(() => {
                            this.model.deliveryId = profile.deliveryId;
                            return this.getDeliveryFields();
                        })
                        .then(() => {
                            this.removeFieldsFromModel(this.deliveryFields);
                            this.addFieldsToModel(profile.fields);
                            this.deliveryFields = profile.fields;
                        })
                }, 100);
            },
            getDeliveryList() {
                let query = new ActiveForm({
                    cityId: this.model.cityId,
                });
                return query.post('/order/api/delivery-list/')
                    .then(r => {
                        this.deliveryList = r;
                        this.$refs.deliverySelect.$emit('keydown');

                        return new Promise(resolve => resolve());
                    });
            },
            getDeliveryFields() {
                this.deliveryFieldsLoading = true;
                return this.model.post('/order/api/delivery/')
                    .then(r => {
                        this.removeFieldsFromModel(this.deliveryFields);
                        this.addFieldsToModel(r);
                        this.deliveryFields = r;
                        this.deliveryFieldsLoading = false;
                        return new Promise(resolve => resolve());
                    });
            },
            addFieldsToModel(fields) {
                fields.forEach(f => {
                    this.model.addField(f.name);
                    this.$set(this.model, f.name, f.value);
                });
            },
            removeFieldsFromModel(fields) {
                fields.forEach(f => {
                    this.model.removeField(f.name);
                    this.$delete(this.model, f.name);
                });
            },
            handleSubmit() {
                this.model.post('/profile/addressbook/update/')
                    .then(r => {
                        this.profileUpdateMessage = '';
                        if (r.success) {
                            let profile = this.activeProfiles.find(item => item.id == this.model.id);
                            if (profile) {
                                profile = Object.assign(profile, r.profile);
                                profile.isActive = false;
                                this.profileUpdateMessage = 'Профиль успешно обновлен';
                            } else {
                                this.profiles.push(r.profile);
                                this.profileUpdateMessage = 'Профиль успешно добавлен';
                            }
                            this.isEditFormShown = false;

                        }
                        if (r.exceptions) {
                            this.profileUpdateMessage = r.exceptions;
                        }
                        setTimeout(() => this.profileUpdateMessage = '', 3000);
                    })
            },
            handleRemove() {
                this.model.post('/profile/addressbook/remove/')
                    .then(r => {
                        this.profileUpdateMessage = '';
                        if (r.success) {
                            let profile = this.activeProfiles.find(item => item.id == this.model.id);
                            if (profile) {
                                profile.isActive = false;
                                profile.isRemoved = true;
                            }
                            this.isEditFormShown = false;
                            this.profileUpdateMessage = 'Профиль успешно удален';
                        }
                        if (r.exceptions) {
                            this.profileUpdateMessage = r.exceptions;
                        }
                        setTimeout(() => this.profileUpdateMessage = '', 3000);
                    });
            },
        },
//        watch: {
//            city(object) {
//                this.model.cityId = object.id;
//            }
//        },
        mounted() {
            this.loading = true;
            this.axios.get(window.location.href)
                .then(r => {
                    this.profiles = r.data.map(item => {
                        item.isRemoved = false;
                        item.isActive = false;
                        return item;
                    });
                    this.loading = false;
                });
        },
        created() {
            
        },
    }
</script>