<template>
    <div class="zoomer" @mousemove="zoomIn" @click="enable" v-on-clickaway="disable">
        <i class="icon icon-zoom-in plus" @click="enable"></i>
        <div class="img normal" v-lazy:background-image="image"></div>
        <div class="img zoom" :style="'background-image:url( ' + image + ' )'"></div>
    </div>
</template>

<script type="text/babel">
    export default {
        name: "Zoomer",
        props: {
            image: {
                required: true,
            },
            inModal: {
                type: Boolean,
                default: false,
            }
        },
        data() {
            return {
                enabled: false
            };
        },
        methods: {
            zoomIn(ev) {
//                if (! this.enabled) return false;

                let container = this.$el,
                    imgZoom = container.getElementsByClassName('zoom')[0];

                const e = {
                    w: imgZoom.offsetWidth,
                    h: imgZoom.offsetHeight
                };

                let containerOffset = {
                    left: container.offsetLeft,
                    top: container.offsetTop
                }
                if (this.inModal) {
                    const modalContainer = this.$parent.$el.getElementsByClassName('modal-content')[0];
                    const containerCS = getComputedStyle(container);

                    const cs = getComputedStyle(modalContainer);

                    var offsetX = parseInt(cs.paddingLeft) + parseInt(cs.paddingRight) + parseInt(containerCS.marginLeft) + parseInt(containerCS.marginRight);
                    var offsetY = parseInt(cs.paddingTop) + parseInt(cs.paddingBottom) + parseInt(containerCS.marginTop) + parseInt(containerCS.marginBottom);

                    containerOffset.left = modalContainer.offsetLeft - containerOffset.left + offsetX;
//                    containerOffset.top = modalContainer.offsetTop - containerOffset.top + offsetY;
                    containerOffset.top = modalContainer.offsetTop + containerOffset.top;
                } else {
                    containerOffset.left -= window.scrollX;
                    containerOffset.top -= window.scrollY;
                }

                const c = {
                    x: Math.round((ev.clientX - containerOffset.left) / (e.w / 100)),
                    y: Math.round((ev.clientY - containerOffset.top) / (e.h / 100))
                };

                imgZoom.style.backgroundPosition = c.x + '% ' + c.y + '%';
            },
            enable() {
                this.enabled = true;
            },
            disable() {
                this.enabled = false;
            }
        }
    }
</script>