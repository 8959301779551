<template>


    <div :class="{ 'is-discontinued': product.isDiscontinued }">

        <!--<modal :init-modal="showWishlistModal !== 0" @close2="showWishlistModal = 0" :with-close="false">
            <div v-if="showWishlistModal === 1">
                <loader :loading="showWishlistModal === 1"></loader>
            </div>
            <div v-if="showWishlistModal === 2 && offer.inWishList">
                Товар успешно добавлен в "Список желаний"
            </div>
            <div v-if="showWishlistModal === 2 && !offer.inWishList">
                Товар убран из "Списка желаний"
            </div>

        </modal>-->

        <slot name="header"></slot>

        <a :href="labelStock.stock.url" class="stock-single-item stock-single-item--slim stock-single-item--centered" v-if="product.isAvailable && labelStock.active">
            <span class="stock-single-item__icon"><i class="icon icon-stock-alt-big"></i></span>
            <span class="stock-single-item__content">
                <span class="stock-single-item__description" v-html="labelStock.stock.descriptionShort"></span>
            </span>
        </a>

        <div class="grid">
            <div class="row properties">
                <slot name="properties"></slot>
            </div>
            <div class="row image">
                <div class="label-container" v-if="product.isAvailable && ! product.isDiscounted">
                    <div v-if="labelStock.active"><i class="icon icon-label-stock-big" v-tooltip.right="labelStock.name"></i></div>
                    <div v-else-if="labelTop.active"><i class="icon icon-label-top-big" v-tooltip.right="labelTop.name"></i></div>
                    <div v-else-if="labelNew.active"><i class="icon icon-label-new-big" v-tooltip.right="labelNew.name"></i></div>
                </div>
                <carousel
                        :per-page="1"
                        :pagination-padding="4"
                        :pagination-size="10"
                        :pagination-active-color="'#4998cc'"
                        :infinite-loop="true"
                        @pageChange="handleSlideChange"
                        ref="pictureSlider">
                    <template v-if="hasImages">
                        <slide :key="index" v-for="image,index in images">
                            <a href="#" @click.prevent="showModal">
                                <img :src="image.full" :alt="imageAlt">
                                <!--<img v-lazy="image.full" :alt="imageAlt">-->
                                <!--<div v-lazy:background-image="image.full"></div>-->
                            </a>
                        </slide>
                    </template>
                    <template v-else>
                        <slide :key="index">
                            <a href="#" @click.prevent="showModal">
                                <!--<img :src="product.image.full" :alt="imageAlt">-->
                                <img v-lazy="product.image.thumb" :alt="imageAlt">
                                <!--<div v-lazy:background-image="product.image.thumb"></div>-->
                            </a>
                        </slide>
                    </template>
                </carousel>
            </div>
            <div class="row actions">
                <div class="grid info">
                    <div class="row rating"><rating-widget :rating="product.reviewsStats.totalRating" :reviews-count="product.reviewsStats.totalCount" :url="product.url" :prevent="true" @onClick="showReviews"></rating-widget></div>
                    <div class="row wish" v-if="! product.isDiscontinued">



                        <a href="#" class="icon-wrapper wishlist-link" @click.prevent="toggleWishList" v-if="isLogged && offer.canBuy">
                            <i class="icon" :class="{ 'icon-wishlist-add': ! offer.inWishList, 'icon-wishlist-remove': offer.inWishList }"></i>
                            <span>{{ offer.inWishList ? 'В избранном' : 'В избранное' }}</span>
                        </a>
                    </div>
                    <div class="row code">
                        <div class="code-inner">Код товара: <span v-text="offer.code"></span></div>
                    </div>
                    <div class="row ask" v-if="! product.isDiscontinued">
                        <a href="#" @click.prevent="showQuestionForm" class="icon-wrapper">
                            <i class="icon icon-product-help"></i>
                            <span>Вопрос по товару</span>
                        </a>
                    </div>
                </div>
                <template v-if="product.isDiscontinued">
                    <div class="discontinued-text"><span>СНЯТ С ПРОИЗВОДСТВА</span></div>
                </template>
                <template v-else>
                    <offers-chooser
                            :current-offer-id="currentOfferId"
                            :init-offers="product.offers"
                            :names="names"
                            :rating="rating"
                            :only-active-offers="showOnlyActiveOffers"
                            @setOffer="setOffer"
                            v-on:setOffer="setUrl"
                            @addToCart="addToCart">
                    </offers-chooser>
                    <template v-if="offer.isAvailable">
                        <div class="product-price product-price--large" :class="{ 'has-discount': offer.hasDiscount }">
                            <div class="product-price__current"><span>{{ offer.price.current | price }}</span> грн</div>
                            <div class="product-price__old" v-if="offer.hasDiscount"><span>{{ offer.price.old | price }}</span> грн</div>
                            <div class="product-bonus product-bonus--slim icon-wrapper" v-if="product.isAvailable && bonus">
                                <span class="product-bonus__value" v-text="bonus"></span>
                                <i class="icon icon-bonus-mini"></i>
                            </div>
                            <div class="product-price__economy" v-if="offer.hasDiscount">экономия {{ offer.price.discount | currency }}</div>
                        </div>
                        <div class="discount-reason-block icon-wrapper" v-if="labelMarkdown.active">
                            <i class="icon icon-label-markdown"></i>
                            <span v-text="labelMarkdown.name"></span>
                        </div>
                        <div class="product-without-discount" v-if="labelMarkdown.active && labelMarkdown.product">
                            <i class="icon icon-label-without-discount"></i>
                            <div>
                                <a :href="labelMarkdown.product.url">Этот товар без уценки</a>
                                <p>{{ labelMarkdown.product.hasManyOffers ? 'от ' : '' }}<span>{{ labelMarkdown.product.price | price }}</span> грн</p>
                            </div>
                        </div>
                        <div class="product-without-discount" v-if="labelHasMarkdown.active && labelHasMarkdown.product">
                            <i class="icon icon-label-markdown"></i>
                            <div>
                                <a :href="labelHasMarkdown.product.url">Этот товар с уценкой</a>
                                <p>{{ labelHasMarkdown.product.hasManyOffers ? 'от ' : '' }}<span>{{ labelHasMarkdown.product.price | price }}</span> грн</p>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="unavailable">
                            <div class="unavailable__text">НЕТ В НАЛИЧИИ</div>
                            <div class="unavailable__last-price">Последняя цена: <span>{{ offer.price.current | price }}</span> грн</div>
                        </div>
                    </template>

                    <template v-if="offer.canBuy">
                        <template v-if="offer.isAvailable">
                            <div class="buy-container grid">
                                <button class="btn btn-orange" @click="addToCart">В КОРЗИНУ</button>
                            </div>
                            <div class="store-info" v-if="offer.needWait">доставка в течении 2-3 дней</div>
                        </template>
                        <template v-else>
                            <available-informer :init-offer="offer" btn-class="btn btn-block btn-light-grey text-center">УВЕДОМИТЬ О НАЛИЧИИ</available-informer>
                        </template>
                    </template>

                    <template v-if="product.isAvailable">
                        <div class="info-block grid">
                            <div class="row icon-wrapper"><i class="icon icon-product-delivery"></i></div>
                            <div class="row">
                                <p class="title">Доставка для г. {{ city.name }}</p>
                                <p v-if="city.id == 417">Курьером <span>{{(300 >= offer.price.current | 300 > price) ? '30' : '15'}} грн</span></p>
                                <p>Новая Почта до отделения <span>{{(300 >= offer.price.current | 300 > price) ? '30' : '15'}} грн</span></p>
                                <p>УкрПочта <span>{{(300 >= offer.price.current | 300 > price) ? '30' : '15'}} грн</span></p>
                                <p v-if="city.id == 417">Самовывоз <span>(бесплатно)</span></p>
                            </div>
                        </div>
                        <div class="info-block grid">
                            <div class="row icon-wrapper"><i class="icon icon-product-payment"></i></div>
                            <div class="row">
                                <p class="title">Оплата</p>
                                <p v-if="city.id == 417">Наличный расчет</p>
                                <p>Приват24 / VISA / MASTERCARD</p>
                                <p>Наложенный платеж</p>
                            </div>
                        </div>
                    </template>
                </template>
            </div>
        </div>

        <div class="alternative-products" v-if="product.isDiscontinued">
            <header>АЛЬТЕРНАТИВНЫЙ ТОВАР</header>
            <div class="grid">
                <product-informer :data="alternative" :key="alternative.id" v-for="alternative in product.alternative"></product-informer>
            </div>
        </div>

        <div class="scroll-spy-container">
            <spy-block v-if="isDesktop" ref="scrollBlock">
                <div class="product-spy-content">
                    <div class="grid header">
                        <div class="row rating"><rating-widget :rating="rating.stars" :reviews-count="rating.reviewsCount" :url="product.url" :prevent="true" @onClick="showReviews"></rating-widget></div>
                        <div class="row wish" v-if="! product.isDiscontinued">
                            <a href="#" class="icon-wrapper wishlist-link" @click.prevent="toggleWishList" v-if="isLogged && offer.canBuy">
                                <i class="icon" :class="{ 'icon-wishlist-add': ! offer.inWishList, 'icon-wishlist-remove': offer.inWishList }"></i>
                                <span>{{ offer.inWishList ? 'В избранном' : 'В избранное' }}</span>
                            </a>
                        </div>
                    </div>
                    <div class="grid info">
                        <div class="row image"><img :src="offer.image.thumb" alt=""></div>
                        <div class="row names">
                            <p class="product-name">{{ productName | decode}}</p>
                            <p class="original-name">{{ originalName | decode | uppercase }}</p>
                            <p class="code">Код товара: <span v-text="offer.code"></span></p>
                            <div class="ask" v-if="! product.isDiscontinued">
                                <a href="#" @click.prevent="showQuestionForm" class="icon-wrapper">
                                    <i class="icon icon-product-help"></i>
                                    <span>Вопрос по товару</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <template v-if="product.isDiscontinued">
                        <div class="discontinued-text"><span>СНЯТ С ПРОИЗВОДСТВА</span></div>
                    </template>
                    <template v-else>
                        <offers-chooser
                                :current-offer-id="currentOfferId"
                                :init-offers="product.offers"
                                :names="names"
                                :rating="rating"
                                :only-active-offers="showOnlyActiveOffers"
                                @setOffer="setOffer"
                                v-on:setOffer="setUrl"
                                @addToCart="addToCart">
                        </offers-chooser>
                        <template v-if="offer.canBuy">
                            <div v-if="offer.isAvailable">
                                <div class="product-price product-price--large" :class="{ 'has-discount': offer.hasDiscount }">
                                    <div class="product-price__current"><span>{{ offer.price.current | price }}</span> грн</div>
                                    <div class="product-price__old" v-if="offer.hasDiscount"><span>{{ offer.price.old | price }}</span> грн</div>
                                    <div class="product-bonus icon-wrapper" v-if="product.isAvailable && bonus">
                                        <span class="product-bonus__value" v-text="bonus"></span>
                                        <i class="icon icon-bonus-mini"></i>
                                    </div>
                                    <div class="product-price__economy" v-if="offer.hasDiscount">экономия {{ offer.price.discount | currency }}</div>
                                </div>
                                <div class="buy-container grid" v-if="offer.canBuy">
                                    <button class="btn btn-orange" @click="addToCart">В КОРЗИНУ</button>
                                </div>
                                <div class="store-info" v-if="offer.isFakeStore">доставка в течении 2-3 дней</div>
                            </div>
                            <div v-else>
                                <div class="unavailable">
                                    <div class="unavailable__text">НЕТ В НАЛИЧИИ</div>
                                    <div class="unavailable__last-price">Последняя цена: <span>{{ offer.price.current | price }}</span> грн</div>
                                </div>

                                <available-informer :init-offer="offer" btn-class="btn btn-block btn-light-grey text-center">УВЕДОМИТЬ О НАЛИЧИИ</available-informer>
                            </div>
                        </template>
                    </template>
                </div>
            </spy-block>

            <slot name="descriptionTitle"></slot>
            <slot name="description"></slot>
            <slot name="seoTitle"></slot>
            <slot name="seoCategories"></slot>
            <slot name="productWarning"></slot>

            <div class="product-list product-list--similar" v-if="product.similar.length > 0">
                <header class="product-list__title">ТОВАРЫ ИЗ ЛИНИИ</header>
                <carousel :per-page-custom="[[320, 1], [768, 2], [992, 2]]"
                          :pagination-padding="4"
                          :pagination-size="10"
                          :pagination-active-color="'#4998cc'">
                    <slide :key="index" v-for="similarProduct,index in product.similar">
                        <product-informer :key="similarProduct.id" :data="similarProduct"></product-informer>
                    </slide>
                </carousel>
            </div>

            <tabs id="reviews" class="product-review-tabs" @tabSelected="refreshScrollBlockPosition" ref="reviewsTab">
                <tab name="ОТЗЫВЫ ПОКУПАТЕЛЕЙ" :selected="true">
                    <reviews :product="product" :review-hash="this.reviewHash"></reviews>
                </tab>
                <tab :name="questionsTabLabel">
                    <questions :product-id="product.reviewProductId" ref="questions" @init="questionsLoaded"></questions>
                </tab>
            </tabs>
        </div>

        <div class="product-list" v-if="product.related.length > 0">
            <header class="product-list__title">ПОХОЖИЕ ТОВАРЫ</header>
            <carousel :per-page-custom="[[320, 1], [768, 2], [992, 3]]"
                      :pagination-padding="4"
                      :pagination-size="10"
                      :pagination-active-color="'#4998cc'">
                <slide :key="index" v-for="relatedProduct,index in product.related">
                    <product-informer :key="relatedProduct.id" :data="relatedProduct"></product-informer>
                </slide>
            </carousel>
        </div>

        <div class="product-list" v-if="currentViewed.length > 0">
            <header class="product-list__title">НЕДАВНО ПРОСМОТРЕННЫЕ</header>
            <carousel :per-page-custom="[[320, 1], [768, 2], [992, 3]]"
                      :pagination-padding="4"
                      :pagination-size="10"
                      :pagination-active-color="'#4998cc'" ref="reviewsProducts">
                <slide :key="index" v-for="productViewed,index in currentViewed">
                    <product-informer :key="productViewed.id" :data="productViewed" ></product-informer>
                </slide>
            </carousel>
        </div>

        <slot name="instagramPosts"></slot>

        <modal class="modal-zoom" :init-modal="isModalActive" :show-title="false" :with-close="true" @close="hideModal" v-if="hasImages">
            <div class="header grid">
                <div class="row names">
                    <h2>{{ productName | decode }}</h2>
                    <h3>{{ originalName | decode | uppercase }}</h3>
                </div>
                <div class="row actions grid" v-if="offer.isAvailable">
                    <div class="price" :class="{ 'has-discount': offer.hasDiscount }"><span>{{ offer.price.current | price }}</span> грн</div>
                    <div v-if="offer.canBuy"><button class="btn btn-medium btn-orange btn-buy" @click="addToCart">В КОРЗИНУ</button></div>
                </div>
            </div>
            <zoomer :image="images[currentPictureIndex].original" :in-modal="true"></zoomer>
            <div class="zoomer-carousel">
                <carousel
                        :per-page="4"
                        :pagination-enabled="false"
                        :infinite-loop="true"
                        ref="zoomSlider">
                    <slide :key="index" v-for="image,index in images">
                        <a href="#" :class="{ 'active': index == currentPictureIndex }" @click.prevent="setPictureIndex(index)">
                            <img :src="image.thumb">
                        </a>
                    </slide>
                </carousel>
            </div>
        </modal>
    </div>
</template>

<script type="text/babel">
    import { mapGetters } from 'vuex';

    import Config from '../../config';
    import Zoomer from '../components/zoomer.vue';
    import SpyBlock from './spy_block.vue';
    import Reviews from '../review/reviews.vue';
    import ProductMixin from '../mixins/product';
    import MobileDetect from '../mixins/mobile_detect';
    import AvailableInformer from '../catalog/available_informer.vue';
    import Tabs from '../tabs/tabs.vue';
    import Tab from '../tabs/tab.vue';
    import Questions from '../review/questions.vue';

    export default {
        components: {
            Questions,
            Tabs,
            Tab,
            Reviews,
            SpyBlock,
            Zoomer,
            AvailableInformer
        },
        mixins: [ ProductMixin, MobileDetect ],
        name: "ProductPage",
        props: {
            onlyActiveOffers: {
                type: Boolean,
                default: true
            },
            userFirstName: {
                type: [String, Number],
                default: '',
            },
            open: {
                type: [Boolean, Number],
                default: false,
            },
            reviewHash: {
                type: String
            }
        },
        data() {
            return {
                showWishlistModal: 0,
                isModalActive: false,
                currentPictureIndex: 0,
                freeDeliveryCost: Config.freeDeliveryCost,
                viewed: [],
            };
        },
        computed: {
            ...mapGetters(['city', 'isLogged']),
            productName() {
                return this.product.name.toUpperCase();
            },
            productCode() {
                const property = this.product.getPropertyByCode('code');
                if (property) {
                    return property.value;
                }
                return;
            },
            showOnlyActiveOffers() {
                if (! this.product.isAvailable) {
                    return false;
                }

                return this.onlyActiveOffers;
            },
            images() {
                return this.product.images.concat(this.offer.images);
            },
            hasImages() {
                return Boolean(this.images.length);
            },
            isFreeDelivery() {
                return parseInt(this.offer.price.current) >= this.freeDeliveryCost;
            },
            currentViewed() {
                return this.viewed.filter(item => item.id != this.product.id);
            },
            imageAlt() {
                return 'Фото ' + this.names.productName.toLowerCase() + ' ' + this.names.originalName.toLocaleLowerCase();
            },
            questionsTabLabel() {
                let label = 'ВОПРОСЫ ПОКУПАТЕЛЕЙ';
                if (this.product.questionsCount > 0) {
                    return `${label} <span>${this.product.questionsCount}</span>`;
                }

                return label;
            }
        },
        methods: {
            setOffer(id) {
                // fix image index
                this.currentPictureIndex = 0;

                this.product.setOffer(id);
            },
            showModal() {
                if (this.hasImages)
                    this.isModalActive = true;
            },
            hideModal() {
                this.isModalActive = false;
            },
            setPictureIndex(index) {
                this.currentPictureIndex = index;
                this.$refs.pictureSlider.currentPage = index;
            },
            handleSlideChange(index) {
                this.currentPictureIndex = index;
            },
            getProductsViewed() {
                this.axios.get('/product/viewed/').then(r => {
                    this.viewed = r.data;
                });
            },
            setUrl() {
//                let url = document.location.href;
//                let newUrl;
//                if (/\/#\/offer\/\d+/.test(url)) {
//                    newUrl = url.replace(/\/#\/offer\/\d+/, '/#/offer/' + this.offer.code);
//                } else {
//                    newUrl = url + '#/offer/' + this.offer.code;
//                }

                history.pushState({}, document.title, this.offer.url);
            },
            refreshScrollBlockPosition() {
                this.$refs.scrollBlock.$emit('refresh');
            },
            showReviews() {
                this.$refs.reviewsTab.selectTabByIndex(0);
                this.$scrollTo('#reviews');
            },
            showQuestionForm() {
                this.$refs.reviewsTab.selectTabByIndex(1);
                this.$scrollTo('#reviews');
                this.$refs.questions.showModal();
            },
            questionsLoaded() {
                if (/\#question-\d+/.test(window.location.hash)) {
                    this.$refs.reviewsTab.selectTabByIndex(1);
                    setTimeout(() => this.$scrollTo(window.location.hash), 300)
                }
            }
        },
        watch: {
            isModalActive(active) {
                if (active) {
                    setTimeout(() => this.$Lazyload.lazyLoadHandler(), 10);
                }
            }
        },
        created() {
            // set offer from url
            const url = document.location.href;
            if (/\/#\/offer\/\d+/.test(url)) {
                let match = url.match(/\/#\/offer\/(\d+)/);
                const offer = this.product.offers.find(t => t.code == match[1]);
                this.setOffer(offer.id)
            }

            this.getProductsViewed();
        }
    }
</script>