<template>
    <div>
        <swipe :is-active="swipeActive" main-class="mobile-filter filter-wrapper" @close="hideSwipe" v-height>
            <header class="grid">
                <div class="row logo"><img src="/build/images/logo-simple.png" alt=""></div>
                <div class="row swipe-close" @click="hideSwipe"><i class="icon icon-swipe-close"></i></div>
            </header>
            <!--<div v-bar>-->
            <div class="scrolled">
                <div>
                    <div class="child-categories">
                        <ul>
                            <li v-for="child in childCategories"><a :href="child.url" v-html="child.name" :class="{ 'important': child.important }"></a></li>
                        </ul>
                    </div>

                    <category-filter :data="filters" :init-disabled="disabled"></category-filter>
                </div>
            </div>
        </swipe>

        <aside class="hidden-lg-down" :class="{ 'is-loading': ! init }">
            <div class="aside-content filter-wrapper">
                <div class="child-categories" v-bar>
                    <div>
                        <slot name="childCategories"></slot>
                    </div>
                </div>

                <category-filter class="filter-group-wrapper" :data="filters" :init-disabled="disabled"></category-filter>
            </div>

            <div class="fb-page" data-href="https://www.facebook.com/avocado.ua" data-tabs="timeline" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/avocado.ua" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/avocado.ua">Avocado.ua</a></blockquote></div>
        </aside>
        <section>
            <slot name="categoryData"></slot>

            <sort-panel id="marker" :data="sort"></sort-panel>

            <filter-panel :options-data="activeOptions"></filter-panel>

            <div class="category-loader" v-if="loading">
                <loader :loading="loading"></loader>
            </div>
            <div class="error" v-else-if="errorMessage">
                <p class="error__title">Ой йо йой!</p>
                <p class="error__message">Возникла проблема :(</p>
                <p class="error__message">Попробуйте обновить страницу. Если это не поможет, сообщите нам об этом!</p>
            </div>
            <div class="products-container" v-else>
                <template v-for="product, index in products">
                    <product-item :data="product" :only-active-offers="false"></product-item>
                    <template v-if="hasBanner(index)">
                        <div class="category-banner"><a :href="banners[index].url" target="_blank"><img :src="banners[index].image" alt=""></a></div>
                    </template>
                </template>
                <show-more :init-page="page" :init-page-size="pageSize" :init-page-count="pageCount" :init-total-count="totalCount"></show-more>
            </div>

            <pagination :pages="pages" :page="page" :support-state="true" ref="pagination" @updatePageIndex="updateStartPage" v-scroll-to="'#marker'" v-show="! loading"></pagination>

            <div class="empty-products" v-if="products.length == 0 && ! loading">
                Товары не найдены
            </div>

            <slot name="seoText"></slot>
        </section>
    </div>
</template>

<script type="text/babel">
    import { dimension } from '../entities/helpers';
    import { ActiveForm } from '../entities/active_form';
    import MobileDetect from 'mobile-detect';

    import FilterPanel from './filter_panel.vue';
    import SortPanel from './sort_panel.vue';
    import Pagination from '../components/pagination.vue';
    import CategoryFilter from './filter.vue';
    import ShowMore from './show_more.vue';
    import Swipe from '../components/swipe.vue';

    export default {
        name: "Category",
        components: {
            Swipe,
            ShowMore,
            CategoryFilter,
            FilterPanel,
            SortPanel,
            Pagination
        },
        props: {
            childCategories: {
                type: [Array, Object]
            },
            initFilters: Array,
            initSort: Array,
            initProducts: Array,
            initBanners: {
                type: Object,
                default() {
                    return {};
                }
            },
            initPages: Array,
            initPage: Number,
            initPageSize: Number,
            initPageCount: Number,
            initTotalCount: Number,
        },
        data() {
            return {
                init: false,
                loading: false,
                filters: this.initFilters,
                products: this.initProducts,
                pages: this.initPages,
                page: this.initPage,
                pageSize: this.initPageSize,
                pageCount: this.initPageCount,
                totalCount: this.initTotalCount,
                sort: this.initSort,
                startPage: this.initPage,
                banners: this.initBanners,
                swipeActive: false,
                errorMessage: '',
            };
        },
        computed: {
            activeOptions() {
                if (this.filters.length == 0) return [];

                let activeFilters = [];
                this.filters.forEach(item => {
                    item.options.forEach(option => {
                        if (option.active) {
                            activeFilters.push(option);
                        }
                    });
                });

                return activeFilters;
            },
            disabled() {
                return this.loading;
            },
            isPhone() {
                let md = new MobileDetect(window.navigator.userAgent);
                return Boolean(md.phone());
            },
        },
        methods: {
            getData(url = window.location.href, userConfig = {}) {
                // clear error message
                this.errorMessage = '';

                // hide filter swipe on phone
                if (this.isPhone)
                    this.hideSwipe();

                const config = Object.assign({
                    appendProducts: false
                }, userConfig);

                if (! config.appendProducts)
                    this.loading = true;

                let form = new ActiveForm({
                    append: config.appendProducts,
                    startPage: this.startPage,
                });
                form.post(url)
                    .then(response => {
                        if (config.appendProducts) {
                            this.products = this.products.concat(response.products);

                            if (response.banners)
                                this.banners = Object.assign({}, this.banners, response.banners);
                        } else {
                            this.products = response.products;
                            this.filters = response.filters;
                            this.sort = response.sort;

                            this.banners = {};
                            if (response.banners)
                                this.banners = Object.assign({}, response.banners);
                        }

                        this.pages = response.pages;
                        this.page = response.page;
                        this.pageSize = response.pageSize;
                        this.pageCount = response.pageCount;
                        this.totalCount = response.totalCount;

                        // задаем стартовую страницу
                        // if (! this.init)
                        //     this.startPage = this.page;

                        this.init = true;

                        if (! config.appendProducts)
                            this.loading = false;
                    })
                    .catch(e => {
                        this.errorMessage = e;
                        this.loading = false;
                    });
            },
            updateStartPage(page) {
                this.startPage = page;
            },
            hideSwipe() {
                this.swipeActive = false;
            },
            hasBanner(position) {
                return this.banners.hasOwnProperty(position);
            }
        },
        directives: {
            /*
            * Directive for setting max-height to scroller with dynamic content
            */
            height (el) {
                const headerHeight = dimension(el.getElementsByTagName('header')[0]);
//                const headerHeight = 116;
                const height = window.innerHeight - headerHeight;
                const swipe = el.getElementsByClassName('swipe-content')[0];
                const scroller = swipe.getElementsByClassName('scrolled')[0];
//                const scroller = swipe.getElementsByClassName('vb-content')[0];
                if (scroller)
                    scroller.style['max-height'] = height + 'px';
            }
        },
        created() {
            // this.getData();

            Event.$on('pushState', (event) => {
                this.getData(event.url, event.config);
            });

            window.addEventListener('popstate', (event) => {
                this.getData(document.location.href);
            }, false);

            // subscribe for event
            Event.$on('showFilter', () => this.swipeActive = true);
        }
    }
</script>