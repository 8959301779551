<template>
    <div class="rating-widget">
        <stars-rating :rating="rating" :read-only="true"></stars-rating>
        <template v-if="prevent">
            <a :href="formattedUrl" @click.prevent="trigger">{{ reviewsCount }} отзывов</a>
        </template>
        <template v-else>
            <a :href="formattedUrl">{{ reviewsCount }} отзывов</a>
        </template>
    </div>
</template>

<script type="text/babel">
    import StarsRating from './stars_rating.vue';

    export default {
        name: "RatingWidget",
        components: {
            StarsRating,
        },
        props: {
            rating: {
                required: true,
                type: Number
            },
            reviewsCount: {
                required: true,
                type: Number
            },
            url: {
                type: String
            },
            prevent: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            formattedUrl() {
                return this.url + '#reviews';
            }
        },
        methods: {
            trigger() {
                this.$emit('onClick');
            }
        }
    }
</script>