<template>
    <div class="reviews">
        <div class="stats grid">
            <div class="row">
                <rating-stats :stats="stats" @addReview="showModal"></rating-stats>
            </div>
            <div class="row comments">
                <header class="title">ЛУЧШИЙ ОТЗЫВ</header>
                <review-item :review="stats.best" :simple="true" v-if="stats.best"></review-item>
                <p class="not-found" v-else>Нет данных</p>
                <header class="title">САМЫЙ ПОЛЕЗНЫЙ ОТЗЫВ</header>
                <review-item :review="stats.useful" :simple="true" v-if="stats.useful"></review-item>
                <p class="not-found" v-else>Нет данных</p>
            </div>
        </div>
        <div id="marker" class="list" :class="{ 'is-loading': loading }">
            <div class="loader text-center" v-if="!init">
                <loader :loading="!init"></loader>
            </div>
            <template v-else>
                <review-item :review="review" :key="review.id" v-for="review in reviews"></review-item>
                <pagination :pages="pages" :page="page" ref="pagination" @updatePageIndex="updatePageIndex" v-scroll-to="'#marker'"></pagination>
            </template>
        </div>

        <modal :init-modal="initModal" class="modal-add-review" title="ОСТАВЬТЕ ОТЗЫВ" @close="hideModal">
            <template v-if="! formSend">
                <header>
                    <p>Все комментарии не касающиеся отзывов о продукте будут удалены!</p>
                    <p>Комментарий будет выведен только после предварительной проверки нашими сотрудниками.</p>
                </header>
                <form action="" @submit.prevent="handleSubmitForm" @keydown="form.errors.clear($event.target.name)">
                    <div class="form-group rating-container">
                        <label for="review-rating">Ваша оценка</label>
                        <stars-rating v-model="form.rating" id="review-rating" @rate="form.errors.clear('rating')"></stars-rating>
                        <p class="help-block-error" v-if="form.errors.has('rating')" v-text="form.errors.get('rating')"></p>
                    </div>
                    <div class="grid">
                        <div class="row form-group">
                            <label for="review-name">Введите имя</label>
                            <input name="name" id="review-name" v-model="form.name" class="form-control" placeholder="Введите имя">
                            <p class="help-block-error" v-if="form.errors.has('name')" v-text="form.errors.get('name')"></p>
                        </div>
                        <div class="row form-group">
                            <label for="review-city">Ваш город</label>
                            <custom-select id="review-city" :auto-choose="true" class="form-control" :values="cityList" v-model="form.city_id"
                                           :autocomplete="true" @autocomplete="setCityName"
                                           @keydown="form.errors.clear('city_id')"></custom-select>
                            <p class="help-block-error" v-if="form.errors.has('city_id')"
                               v-text="form.errors.get('city_id')"></p>
                        </div>
                        <div class="row form-group">
                            <label for="time_of_using">Время использования</label>
                            <custom-select id="time_of_using" class="form-control" :values="getTypeList('time_of_using')" v-model="form.time_of_using"
                                           @keydown="form.errors.clear('time_of_using')"></custom-select>
                            <p class="help-block-error" v-if="form.errors.has('time_of_using')"
                               v-text="form.errors.get('time_of_using')"></p>
                        </div>
                        <div class="row form-group">
                            <label for="skin_type">Тип кожи</label>
                            <custom-select id="skin_type" class="form-control" :values="getTypeList('skin_type')" v-model="form.skin_type"
                                           @keydown="form.errors.clear('skin_type')"></custom-select>
                            <p class="help-block-error" v-if="form.errors.has('skin_type')"
                               v-text="form.errors.get('skin_type')"></p>
                        </div>
                        <div class="row form-group">
                            <label for="age">Возраст</label>
                            <custom-select id="age" class="form-control" :values="getTypeList('age')" v-model="form.age"
                                           @keydown="form.errors.clear('age')"></custom-select>
                            <p class="help-block-error" v-if="form.errors.has('age')"
                               v-text="form.errors.get('age')"></p>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="review-content">Ваш отзыв</label>
                        <textarea name="content" id="review-content" v-model="form.content" class="form-control" placeholder="Опишите" @focus="initCaptcha" v-expandable></textarea>
                        <p class="help-block-error" v-if="form.errors.has('content')"
                           v-text="form.errors.get('content')"></p>
                    </div>
                    <!--<div class="form-group">-->
                        <!--<vue-recaptcha-->
                                <!--name="captcha"-->
                                <!--ref="invisibleRecaptcha"-->
                                <!--:sitekey="getConfig('google.recaptcha.key')"-->
                                <!--size="invisible"-->
                                <!--@verify="verify"-->
                                <!--@expired="expired">-->
                        <!--</vue-recaptcha>-->
                        <!--<p class="help-block-error" v-if="form.errors.has('captcha')">-->
                            <!--<span v-text="form.errors.get('captcha')"></span>-->
                            <!--<a href="#" @click.prevent="retryCaptcha" class="recaptcha">Повторить проверку</a>-->
                        <!--</p>-->
                    <!--</div>-->
                    <div class="form-group files">
                        <transition name="fade">
                            <progressbar :init-percent="uploadProgress" v-if="uploading"></progressbar>
                            <template v-else>
                                <a href="#" @click.prevent="openFiles" class="link icon-wrapper" v-if="canUpload">
                                    <span>Добавить изображение</span>
                                    <i class="icon icon-plus-green"></i>
                                </a>
                            </template>
                        </transition>
                        <div class="images" v-if="form.files.length">
                            <attachment-item :attachment="attachment" v-for="(attachment,i) in form.files" :key="i" @remove="removeAttachment"></attachment-item>
                        </div>
                        <p class="help-block-error" v-if="form.errors.has('files')" v-text="form.errors.get('files')"></p>
                        <input type="file" name="review-files[]" ref="files" @change="handleUploadFiles" accept="image/*" multiple>
                        <p class="help-block-error" v-if="uploadForm.errors.has('attachments')" v-text="uploadForm.errors.get('attachments')"></p>
                    </div>
                    <div class="grid extra-data">
                        <div class="row">
                            <a href="#" @click.prevent="toggleAdvantages" class="link icon-wrapper">
                                <span>Достоинства</span>
                                <i class="icon icon-plus-green"></i>
                            </a>
                        </div>
                        <div class="row">
                            <a href="#" @click.prevent="toggleLimitations" class="link icon-wrapper">
                                <span>Недостатки</span>
                                <i class="icon icon-plus-green"></i>
                            </a>
                        </div>
                    </div>
                    <div class="grid">
                        <div class="row form-group" v-if="isAdvantagesShown">
                            <label for="review-advantages">Достоинства</label>
                            <textarea name="advantages" id="review-advantages" v-model="form.advantages" class="form-control" placeholder="Опишите" v-expandable></textarea>
                            <p class="help-block-error" v-if="form.errors.has('advantages')" v-text="form.errors.get('advantages')"></p>
                        </div>
                        <div class="row form-group" v-if="isLimitationsShows">
                            <label for="review-limitations">Недостатки</label>
                            <textarea name="limitations" id="review-limitations" v-model="form.limitations" class="form-control" placeholder="Опишите" v-expandable></textarea>
                            <p class="help-block-error" v-if="form.errors.has('limitations')" v-text="form.errors.get('limitations')"></p>
                        </div>
                    </div>
                    <div class="grid actions">
                        <div class="row form-group">
                            <label>Рекомендуете данный товар?</label>
                            <div class="grid">
                                <div class="row">
                                    <button type="button" @click="setRecommendation(true)" class="btn btn-block btn-inverse icon-wrapper" :class="{ 'is-active': getRecommendationActive(true) }">
                                        <span>Да</span>
                                        <i class="icon icon-thumb-up"></i>
                                    </button>
                                </div>
                                <div class="row">
                                    <button type="button" @click="setRecommendation(false)" class="btn btn-block btn-inverse icon-wrapper" :class="{ 'is-active': getRecommendationActive(false) }">
                                        <span>Нет</span>
                                        <i class="icon icon-thumb-down"></i>
                                    </button>
                                </div>
                            </div>
                            <p class="help-block-error" v-if="form.errors.has('recommendation')" v-text="form.errors.get('recommendation')"></p>
                        </div>
                        <div class="row">
                            <button-loading :loading="formSubmitLoading" class="btn btn-block btn-secondary" :disabled="form.errors.any()">
                                ОСТАВИТЬ ОТЗЫВ
                            </button-loading>
                        </div>
                    </div>
                </form>
            </template>
            <p class="text-center" v-else>Ваш отзыв успешно отправлен и будет опубликован после проверки</p>
        </modal>
    </div>
</template>

<script type="text/babel">
    import RatingStats from './stats.vue';
    import ReviewItem from './review_item.vue';
    import {ActiveForm} from '../entities/active_form';
    import StarsRating from './stars_rating.vue';
    import Pagination from '../components/pagination.vue';
    import Config from '../mixins/config';
    import City from '../mixins/city';
    import { mapGetters } from 'vuex';
    import Progressbar from '../components/progressbar.vue';
    import AttachmentItem from './attachment_item.vue';
    // import VueRecaptcha from 'vue-recaptcha';

    export default {
        name: "Reviews",
        components: {
            AttachmentItem,
            Progressbar,
            StarsRating,
            ReviewItem,
            RatingStats,
            Pagination,
            // VueRecaptcha
        },
        mixins: [ Config, City ],
        props: {
            product: {
                required: true
            },
            reviewHash: {
                type: String
            }
        },
        data() {
            return {
                init: false,
                loading: false,
                reviews: [],
                pages: [],
                page: 0,
                initModal: Boolean(this.$parent.open),
                form: new ActiveForm({
                    product_id: this.product.reviewProductId,
                    rating: 0,
                    name: null,
                    city_id: -1,
                    time_of_using: -1,
                    skin_type: -1,
                    age: -1,
                    content: '',
                    files: [],
                    advantages: '',
                    limitations: '',
                    recommendation: '',
                    captcha: '',
                    hash: this.reviewHash
                }),
                formSend: false,
                formSubmitLoading: false,
                types: {},
                isAdvantagesShown: false,
                isLimitationsShows: false,
                uploading: false,
                uploadProgress: 0,
                uploadForm: new ActiveForm({
                    attachments: []
                }),
            };
        },
        computed: {
            stats() {
                return this.product.reviewsStats;
            },
            canUpload() {
                return this.form.files.length < 3;
            },
            ...mapGetters(['user'])
        },
        methods: {
            getReviews(url) {
                const correctUrl = url ? url : '/reviews/list/';
                this.loading = true;

                let query = new ActiveForm({
                    product_id: this.product.id
                });

                query.get(correctUrl)
                    .then(r => {
                        this.reviews = r.reviews;
                        this.pages = r.pages;
                        this.loading = false;
                        this.init = true;
                    })
                    .catch(e => {
                        console.log(e);
                        this.init = true;
                        this.loading = false;
                    });
            },
            showModal() {
                this.initModal = true;
            },
            hideModal() {
                this.initModal = false;
            },
            openFiles() {
                this.$refs.files.click();
            },
            handleUploadFiles(e) {
                let files = e.target.files || e.dataTransfer.files;

                if (files.length) {
                    this.uploadForm.errors.clear();
                    this.uploading = true;
                    this.uploadProgress = 0;

                    const config = {
                        onUploadProgress: (progressEvent) => {
                            var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
                            this.uploadProgress = percentCompleted;
                        }
                    }

                    this.uploadForm.attachments = files;
                    this.uploadForm.submit('post', '/reviews/upload/', config)
                        .then(r => {
                            if (r.success) {
                                this.form.files = this.form.files.concat(r.attachments);
                            }
                            this.uploading = false;
                        })
                        .catch(err => {
                            console.log(err)
                            this.uploading = false;
                        })
                }
            },
            removeAttachment(value) {
                const index = this.form.files.indexOf(value);
                if (index > -1) {
                    this.form.files.splice(index, 1);
                    setTimeout(() => Event.$emit('attachmentFix'), 10);
                }
            },
            handleSubmitForm() {
                if (! this.formSubmitLoading) {
                    this.formSubmitLoading = true;
                    this.form.post('/reviews/add/')
                        .then(r => {
                            this.formSubmitLoading = false;
                            if (r.success) {
                                this.formSend = true;
                                this.form.restore();
                                setTimeout(() => {
                                    this.formSend = false;
                                    this.initModal = false;
                                }, 5000);
                            }
                        })
                        .catch(e => {
                            console.log(e);
                            this.formSubmitLoading = false;
                        });
                }
            },
            getTypeList(key) {
                if (this.types.hasOwnProperty(key)) {
                    return this.types[key];
                }
            },
            toggleAdvantages() {
                this.isAdvantagesShown = ! this.isAdvantagesShown;
            },
            toggleLimitations() {
                this.isLimitationsShows = ! this.isLimitationsShows;
            },
            getRecommendationActive(val) {
                if (this.form.recommendation === '')
                    return false;

                return this.form.recommendation === Number(val);
            },
            setRecommendation(val) {
                // clear errors on click
                this.form.errors.clear('recommendation');

                // logic
                if (this.form.recommendation !== '' && Boolean(this.form.recommendation) === val) {
                    this.form.recommendation = '';
                } else {
                    this.form.recommendation = Number(val);
                }
            },
            updatePageIndex(index) {
                this.page = index;
                const url = this.pages[this.page] ? this.pages[this.page].url : '';
                this.getReviews(url);
            },
            retryCaptcha() {
                // this.$refs.invisibleRecaptcha.reset();
                // this.initCaptcha();
            },
            initCaptcha() {
                // if (! this.form.captcha)
                //     this.$refs.invisibleRecaptcha.execute();
            },
            // verify(response) {
            //     this.form.captcha = response;
            //     this.form.errors.clear('captcha');
            // },
            // expired() {
            //     this.form.captcha = '';
            // }
        },
        mounted() {
            this.form.name = this.user.firstname;
            // this.form.city_id = this.cityValue.value;
        },
        created() {
            this.getReviews();

            // get types
            this.axios.get('/reviews/types-list/').then(r => this.types = r.data);
        }
    }
</script>