<template>
    <div class="rating-container">
        <div class="recommendation centered" v-if="hasGoodRecommendation">{{ recommendationPercent }}% покупателей рекомендуют этот товар</div>
        <div class="total centered">{{ totalRating }} из {{ maxRating }}</div>
        <div class="centered"><stars-rating :rating="stats.totalRating" :read-only="true"></stars-rating></div>
        <div class="rating">
            <div class="grid" v-for="rate in ratingList">
                <div class="row"><stars-rating :rating="parseInt(rate)" :read-only="true"></stars-rating></div>
                <div class="row">
                    <progressbar :init-percent="getRatingPercent(rate)"></progressbar>
                </div>
                <div class="row">{{ stats.rating[rate] }}</div>
            </div>
        </div>
        <div><button class="btn btn-light-grey" @click="addReview">Оставить отзыв</button></div>
    </div>
</template>

<script type="text/babel">
    import StarsRating from './stars_rating.vue';
    import Progressbar from '../components/progressbar.vue';

    export default {
        components: {
            StarsRating,
            Progressbar
        },
        name: "RatingStats",
        props: {
            stats: {
                required: true
            }
        },
        computed: {
            ratingList() {
                return Object.keys(this.stats.rating).reverse();
            },
            hasGoodRecommendation() {
                return this.stats.recommendation > 0;
            },
            recommendationPercent() {
                if (! this.hasGoodRecommendation) return 0;
                return Math.round(this.stats.recommendation / this.stats.totalCount * 100);
            },
            totalRating() {
                return Number(this.stats.totalRating).toFixed(1);
            },
            maxRating() {
                return Math.max(...Object.keys(this.stats.rating));
            }
        },
        methods: {
            getRatingPercent(rate) {
                if (this.stats.totalCount == 0)
                    return 0;

                return this.stats.rating[parseInt(rate)] / this.stats.totalCount * 100;
            },
            addReview() {
                this.$emit('addReview');
            }
        }
    }
</script>