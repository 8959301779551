export default {
    name: 'expandable',
    config: {},
    install (Vue) {
        Vue.directive('expandable', {
            bind (el, binding) {
                el.style.overflow = 'hidden';
                el.addEventListener('keydown', (e) => {
                    if (e.keyCode == 13) {
                        let h = binding.value ? binding.value : 16;
                        let currentHeight = el.offsetHeight;
                        el.style.height = "1px";
                        let newHeight = (currentHeight < (el.scrollHeight)) ? (h + el.scrollHeight) : currentHeight;
                        el.style.height = newHeight + "px";
                    }
                });
            }
        })
    }
}