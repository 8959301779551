<template>
    <div class="text-center">
        <button class="btn btn-inverse" @click.prevent="showModal()">СВЯЗЬ С ДИРЕКТОРОМ</button>

        <modal :init-modal="initActive" class="modal-abuse" title="СВЯЗЬ С ДИРЕКТОРОМ" @close="hideModal">
            <form action="" @submit.prevent="handleSubmitForm" v-if="showForm" @keydown="model.errors.clear($event.target.name)">
                <div class="form-group">
                    <input type="text" name="name" v-model="model.name" class="form-control" placeholder="Ваше имя">
                    <p class="help-block-error" v-if="model.errors.has('name')">{{ model.errors.get('name') }}</p>
                </div>
                <div class="form-group">
                    <input type="tel" name="phone" v-mask="getConfig('phone.mask')" v-model="model.phone" class="form-control" placeholder="Номер телефона">
                    <p class="help-block-error" v-if="model.errors.has('phone')">{{ model.errors.get('phone') }}</p>
                </div>
                <div class="form-group">
                    <input type="text" name="email" v-model="model.email" class="form-control" placeholder="Ваш email">
                    <p class="help-block-error" v-if="model.errors.has('email')">{{ model.errors.get('email') }}</p>
                </div>
                <div class="form-group abuse-type-container">
                    <label>Тип обращения:</label>
                    <custom-select :values="abuseTypes" v-model="model.type" class="form-control" @keydown="model.errors.clear('type')"></custom-select>
                </div>
                <div class="form-group">
                    <textarea class="form-control" name="text" v-model="model.text" :placeholder="activeAbuse.text" @focus="initCaptcha"></textarea>
                    <p class="help-block-error" v-if="model.errors.has('text')">{{ model.errors.get('text') }}</p>
                </div>
                <!--<div class="form-group">-->
                    <!--<vue-recaptcha-->
                            <!--name="captcha"-->
                            <!--ref="invisibleRecaptcha"-->
                            <!--:sitekey="getConfig('google.recaptcha.key')"-->
                            <!--size="invisible"-->
                            <!--@verify="verify"-->
                            <!--@expired="expired">-->
                    <!--</vue-recaptcha>-->
                    <!--<p class="help-block-error" v-if="model.errors.has('captcha')">-->
                        <!--<span v-text="model.errors.get('captcha')"></span>-->
                        <!--<a href="#" @click.prevent="retryCaptcha" class="recaptcha">Повторить проверку</a>-->
                    <!--</p>-->
                <!--</div>-->
                <button-loading :loading="loading" :disabled="loading || model.errors.any()" class="btn btn-primary btn-block">ОТПРАВИТЬ</button-loading>
            </form>
            <p class="result text-center" v-else>Спасибо! Вас запрос будет обработан менеджером.</p>
        </modal>
    </div>
</template>

<script type="text/babel">
    import Config from '../mixins/config';
    import { ActiveForm } from '../entities/active_form';
    // import VueRecaptcha from 'vue-recaptcha';

    export default {
        name: "Abuse",
        mixins: [ Config ],
        components: {
            // VueRecaptcha
        },
        data() {
            return {
                loading: false,
                initActive: false,
                showForm: true,
                model: new ActiveForm({
                    name: '',
                    phone: '',
                    email: '',
                    type: 1,
                    text: '',
                    captcha: '',
                }),
                abuseTypes: [
                    {
                        text: 'Жалоба',
                        value: 1,
                    },
                    {
                        text: 'Предложение',
                        value: 2,
                    }
                ]
            };
        },
        computed: {
            activeAbuse() {
                if (! this.model.type)
                    return this.abuseTypes[0];

                return this.abuseTypes.filter((item) => item.value == this.model.type).shift();
            },
        },
        methods: {
            showModal() {
                this.initActive = true;
            },
            hideModal() {
                this.initActive = false;
            },
            handleSubmitForm() {
                this.model.post('/form/abuse/')
                    .then(r => {
                        if (r.success) {
                            this.showForm = false;
                        } else {
                            console.log(r.message);
                        }
                        this.loading = false;
                    })
                    .catch(errors => {
                        this.loading = false;
                        return new Error(errors);
                    })
            },
            retryCaptcha() {
                // this.$refs.invisibleRecaptcha.reset();
                // this.initCaptcha();
            },
            initCaptcha() {
                // if (! this.model.captcha)
                //     this.$refs.invisibleRecaptcha.execute();
            },
            verify(response) {
                // this.model.captcha = response;
                // this.model.errors.clear('captcha');
            },
            expired() {
                // this.model.captcha = '';
            }
        },
        mounted() {

        },
        created() {
            Event.$on('showAbuseModal', () => this.showModal());
        }
    }
</script>