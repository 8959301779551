<template>
    <div v-show="isActive || simpleMode">
        <div class="tab-name" v-if="simpleMode" v-text="name"></div>
        <slot></slot>
    </div>
</template>

<script type="text/babel">
    export default {
        name: "Tab",
        props: {
            name: { required: true },
            selected: { default: false }
        },
        data() {
            return {
                isActive: false
            };
        },
        computed: {
            simpleMode() {
                return this.$parent.simpleMode;
            }
        },
        mounted() {
            this.isActive =  this.selected;
        }
    }
</script>