<template>
    <div>
        <a href="#" @click.prevent="showModal"><slot></slot></a>

        <modal :init-modal="initActive" class="modal-mobile-search" title="ПОИСК" @close="hideModal">
            <form action="/search/query/" method="post" ref="form" @submit="checkSubmit">
                <div class="form-group">
                    <input type="text" name="q" class="form-control" placeholder="Поиск среди 10 000 наименований товаров" autocomplete="off" v-model.trim="model.searchText">
                    <i class="icon icon-mobile-search-input"></i>
                </div>
                <loader :loading="loading"></loader>
                <a :href="product.url" class="search-result-item grid" v-for="product in this.products">
                    <div class="row image"><img :src="product.image" alt=""></div>
                    <div class="row content">
                        <p v-html="product.name"></p>
                        <p class="second-name" v-if="product.name_alias">{{ product.name_alias | decode | uppercase }}</p>
                    </div>
                </a>
                <p v-if="notFound">Ничего не найдено</p>
                <button type="submit" class="btn btn-primary btn-block" v-show="! loading && ! notFound && this.init">ПОКАЗАТЬ ЕЩЕ</button>
            </form>
        </modal>
    </div>
</template>

<script type="text/babel">
    import _debounce from 'lodash/debounce';
    import { ActiveForm } from '../entities/active_form';

    export default {
        name: "MobileSearch",
        components: {
        },
        data() {
            return {
                initActive: false,
                loading: false,
                init: false,
                submited: false,
                model: new ActiveForm({
                    searchText: '',
                }),
                products: []
            };
        },
        computed: {
            notFound() {
                let itemsLength = this.products.length;
                return ! this.loading && itemsLength == 0 && this.model.searchText.length > 0;
            }
        },
        watch: {
            'model.searchText': function() {
                this.submited = false;
                this.getSearchData();
            }
        },
        props: {
            link: { required: true, type: String }
        },
        methods: {
            showModal() {
                this.initActive = true;
            },
            hideModal() {
                this.initActive = false;
            },
            getSearchData: _debounce(function() {
                if (this.model.searchText.length > 0) {
                    this.showSearchResults = true;
                    this.loading = true;
                    this.searchResults = [];
                    let vm = this;

                    this.model.post(vm.link)
                        .then(r => {
                            this.init = true;
                            this.submited = true;
                            if (r.hasOwnProperty('redirect')) {
                                vm.showSearchResults = false;
                                window.location.href = r.redirect;
                            } else {
                                vm.products = r.products;
                            }
                            vm.loading = false;
                        })
                }
            }, 500),
            submitForm() {
                this.$refs.form.submit();
            },
            checkSubmit(e) {
                if (this.model.searchText.length == 0) {
                    e.preventDefault();
                }
            }
        }
    }
</script>