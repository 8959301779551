<template>
    <div class="payments-details-container">
        <div class="grid">
            <div class="row">или получить реквизиты</div>
            <div class="row" v-on-clickaway="hidePopup">
                <div class="popup-container">
                    <button class="btn btn-primary btn-small" @click="showPopup">в SMS</button>
                    <popup :is-active="isActivePopup" :auto-center="true">
                        <div class="grid" v-if="! isSent">
                            <div class="row"><a href="#" @click.prevent="sendSms(true)">На мой номер</a></div>
                            <div class="row" v-if="hasRecipient"><a href="#" @click.prevent="sendSms(false)">На номер получателя</a></div>
                        </div>
                        <div v-else>
                            <div class="icon-wrapper">
                                <i class="icon icon-order-checked"></i>
                                <span>Отправлено на: <b v-text="phone"></b></span>
                            </div>
                        </div>
                    </popup>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/babel">
    import { ActiveForm } from '../entities/active_form';
    import Popup from '../components/popup.vue';

    export default {
        name: "OrderPaymentDetails",
        components: {Popup},
        props: {
            orderId: {
                required: true
            },
            hasRecipient: {
                required: true,
                type: Boolean
            }
        },
        data: () => ({
            isActivePopup: false,
            isSent: false,
            phone: '',
        }),
        computed: {

        },
        methods: {
            showPopup() {
                this.isActivePopup = true;
            },
            hidePopup() {
                this.isActivePopup = false;
            },
            sendSms(sender) {
                let query = new ActiveForm({
                    order_id: this.orderId,
                    sender: sender
                });

                query.post('/order/api/send-payment-details/')
                    .then(r => {
                        this.isSent = true;
                        this.phone = r;
                    })
                    .catch(e => new Error(e));
            }
        }
    }
</script>