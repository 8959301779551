<template>
    <div class="coupon-container">
        <template v-if="askForCoupon || hasCoupon">
            <div class="coupon-form">
                <div class="form-group">
                    <input type="text" v-model="model.coupon" @input="model.errors.clear('coupon')" class="form-control" placeholder="Введите код купона для скидки:" :disabled="couponAssigned">
                    <p class="help-block-error" v-if="model.errors.has('coupon')" v-text="model.errors.get('coupon')"></p>
                </div>
                <template v-if="! couponAssigned">
                    <a href="#" @click.prevent="assign">Использовать</a>
                </template>
                <template v-else>
                    <!--<p class="discount-success">Ваша скидка: <span v-text="discountFormatted"></span></p>-->
                    <p class="discount-success"><span>Купон успешно применен.</span> <a href="#" @click.prevent="removeCoupon">Удалить купон</a></p>
                </template>
            </div>
        </template>
        <template v-else>
            <a href="#" @click.prevent="askForCoupon = true">У меня есть купон</a>
        </template>
    </div>
</template>

<script type="text/babel">
    import {ActiveForm} from "../entities/active_form";

    export default {
        name: "CartCoupon",
        props: {
            initCoupon: {
                required: true
            }
        },
        data() {
            return {
                askForCoupon: false,
                couponAssigned: false,
                model: new ActiveForm({
                    coupon: '',
                }),
//                discount: 0,
            };
        },
        computed: {
//            discountFormatted() {
//                return parseInt(this.discount * 100) + '%';
//            }
            hasCoupon() {
                return this.initCoupon;
            }
        },
        methods: {
            assign() {
//                if (! this.couponAssigned) {
//                    this.discount = 0.15;
//                    this.$emit('assigned', this.discount);
//                }
//                this.couponAssigned = true;
                this.model.post('/cart/apply-coupon/')
                    .then(r => {
                        if (r.success) {
                            this.couponAssigned = true;
                            this.$emit('assigned');
                        }
                    })
            },
            removeCoupon() {
                this.model.post('/cart/remove-coupon/')
                    .then(r => {
                        if (r.success) {
                            this.couponAssigned = false;
                            this.model.coupon = '';
                            this.$emit('assigned');
                        }
                    })
            }
        },
        created() {
            this.couponAssigned = this.initCoupon;
            if (this.initCoupon) {
                this.model.coupon = this.initCoupon;
            }
        }
    }
</script>