import he from 'he';

function randomInteger(min, max) {
    let rand = min - 0.5 + Math.random() * (max - min + 1);
    return parseInt(Math.round(rand));
}

function dimension(elm) {
    let elmHeight, elmMargin;
    if(document.all) {// IE
        elmHeight = parseInt(elm.currentStyle.height);
        elmMargin = parseInt(elm.currentStyle.marginTop, 10) + parseInt(elm.currentStyle.marginBottom, 10);
    } else {// Mozilla
        elmHeight = parseInt(document.defaultView.getComputedStyle(elm, '').getPropertyValue('height'));
        elmMargin = parseInt(document.defaultView.getComputedStyle(elm, '').getPropertyValue('margin-top')) + parseInt(document.defaultView.getComputedStyle(elm, '').getPropertyValue('margin-bottom'));
    }
    return elmHeight + elmMargin;
}

function formatPrice(price) {
    // return new Intl.NumberFormat('uk-UA').format(price);
    return price.toLocaleString();
}

function unescape(value) {
    if (!value) return '';
    return value.replace(/&bq;/gi, '"').replace(/&sq;/gi, '\'')
}

function escape(value) {
    if (!value) return '';
    return value.replace(/\"/gi, '&bq;').replace(/\'/gi, '&sq;')
}

function decode(value) {
    if (!value) return '';
    return unescape(he.decode(value));
}

function encode(value) {
    if (!value) return '';
    return escape(he.encode(value));
}

export {randomInteger, dimension, formatPrice, unescape, escape, decode, encode};
