<template>
    <div class="answers">
        <div class="show-more" :class="{ 'is-active': showFullList}" v-if="canShowMore">
            <a href="#" class="icon-wrapper" @click.prevent="toggleFullList">
                <span>{{ showFullList ? 'Скрыть ответы' : 'Показать ответы' }} ({{ answers.length }})</span>
                <i class="icon icon-answers-show-more"></i>
            </a>
        </div>
        <answer-item :review-id="reviewId" :key="answer.id" :answer="answer" v-for="answer in list" :init-child-show="perPage"></answer-item>
    </div>
</template>

<script type="text/babel">
    import AnswerItem from './answer_item.vue';

    export default {
        components: {
            AnswerItem
        },
        name: "Answers",
        props: {
            reviewId: {
                required: true,
                type: Number
            },
            answers: {
                required: true,
                type: Array
            },
            perPage: {
                type: Number,
                default: 5
            },
            initShow: {
                type: Number,
                default: 2
            }
        },
        data() {
            return {
                showFullList: false,
                page: 1,
            };
        },
        computed: {
            list() {
                // если ответов больше чем задано параметром и они не раскрыты то покажем только нужное количество
                if (this.answers.length > this.initShow && ! this.showFullList) {
                    return this.answers.slice(0, this.initShow);
                }

                // в любом другом случае показываем все что есть
                return this.answers;
            },
            limit() {
                return this.page * this.perPage;
            },
            canShowMore() {
                return this.answers.length > this.initShow;
            }
        },
        methods: {
            toggleFullList() {
                this.showFullList = ! this.showFullList;
            }
        }
    }
</script>