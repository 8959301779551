<template>
    <transition enter-active-class="animated flipInX"
                leave-active-class="animated flipOutX">
        <div class="answer-form" v-if="isFormShown">
            <form action="" @submit.prevent="handleSubmitForm" @keydown="form.errors.clear($event.target.name)" v-if="! formSend" ref="form">
                <div class="grid">
                    <div class="row">
                        <div class="form-group">
                            <label for="answer-name">Ваше имя</label>
                            <input name="name" id="answer-name" class="form-control" v-model="form.name"
                                   placeholder="Введите имя">
                            <p class="help-block-error" v-if="form.errors.has('name')" v-text="form.errors.get('name')"></p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group">
                            <label for="answer-city">Ваш город</label>
                            <custom-select id="answer-city" :auto-choose="true" class="form-control" :values="cityList" v-model="form.city_id"
                                           :autocomplete="true" @autocomplete="setCityName"
                                           @keydown="form.errors.clear('city_id')"></custom-select>
                            <p class="help-block-error" v-if="form.errors.has('city_id')"
                               v-text="form.errors.get('city_id')"></p>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="answer-content">Ваш ответ</label>
                    <textarea name="content" id="answer-content" class="form-control" v-model="form.content"
                              placeholder="Опишите" @focus="initCaptcha" v-expandable></textarea>
                    <p class="help-block-error" v-if="form.errors.has('content')" v-text="form.errors.get('content')"></p>
                </div>
                <!--<div class="form-group">-->
                    <!--<vue-recaptcha-->
                            <!--name="captcha"-->
                            <!--ref="invisibleRecaptcha"-->
                            <!--:sitekey="getConfig('google.recaptcha.key')"-->
                            <!--size="invisible"-->
                            <!--@verify="verify"-->
                            <!--@expired="expired">-->
                    <!--</vue-recaptcha>-->
                    <!--<p class="help-block-error" v-if="form.errors.has('captcha')">-->
                        <!--<span v-text="form.errors.get('captcha')"></span>-->
                        <!--<a href="#" @click.prevent="retryCaptcha">Повторить проверку</a>-->
                    <!--</p>-->
                <!--</div>-->
                <div class="grid actions">
                    <div class="row">
                        <button type="button" class="btn btn-link btn-block btn-big" @click="hideForm">Отменить</button>
                    </div>
                    <div class="row">
                        <button-loading :loading="loading" :disabled="form.errors.any()" type="submit" class="btn btn-secondary btn-block btn-big">
                            ОТВЕТИТЬ
                        </button-loading>
                        <!--<button type="submit" class="btn btn-secondary btn-block btn-big"-->
                                <!--:disabled="form.errors.any()">ОТВЕТИТЬ-->
                        <!--</button>-->
                    </div>
                </div>
            </form>
            <div class="success-message" v-else>
                Ваш ответ успешно отправлен и появится после проверки.
            </div>
        </div>
    </transition>
</template>

<script type="text/babel">
    import {ActiveForm} from '../entities/active_form';
    import Config from '../mixins/config';
    import City from '../mixins/city';
    import { mapGetters } from 'vuex';
    // import VueRecaptcha from 'vue-recaptcha';

    export default {
        name: "AnswerForm",
        components: {
            // VueRecaptcha
        },
        mixins: [ Config, City ],
        props: {
            reviewsId: {
                required: true,
                type: Number
            },
            answerId: {
                type: [Number, String],
                default: ''
            },
            showForm: {
                required: true,
                type: Boolean,
            },
        },
        data() {
            return {
                form: new ActiveForm({
                    review_id: this.reviewsId,
                    answer_id: this.answerId,
                    name: null,
                    city_id: -1,
                    content: '',
                    captcha: '',
                }),
                formSend: false,
                loading: false,
            };
        },
        computed: {
            isFormShown() {
                return this.showForm;
            },
            ...mapGetters(['user'])
        },
        methods: {
            hideForm() {
                this.$emit('close');
            },
            retryCaptcha() {
                // this.$refs.invisibleRecaptcha.reset();
                // this.initCaptcha();
            },
            initCaptcha() {
                // if (! this.form.captcha)
                //     this.$refs.invisibleRecaptcha.execute();
            },
            handleSubmitForm() {
                if (! this.loading) {
                    this.loading = true;
                    this.form.post('/reviews/answer-add/')
                        .then(r => {
                            this.loading = false;
                            if (r.success) {
                                this.formSend = true;
                                this.form.restore();
                                setTimeout(() => {
                                    this.hideForm();
                                    this.formSend = false;
                                }, 3000);
                            }
                        })
                        .catch(e => console.log(e));
                }
            },
            verify(response) {
                // this.form.captcha = response;
                // this.form.errors.clear('captcha');
            },
            expired() {
                // this.form.captcha = '';
            }
        },
        mounted() {
            this.form.name = this.user.firstname;
        },
    }
</script>