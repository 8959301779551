<template>
    <div class="total-container">
        <clip-loader :loading="! init" :color="'#4998cc'" :size="'10px'"></clip-loader>
        <div class="container" v-if="init">
            <header>ВАШ ЗАКАЗ</header>
            <div class="cart-items" v-bar="{preventParentScroll: false}">
                <div>
                    <div class="grid cart-item" v-for="item in cart">
                        <div class="row image">
                            <img :src="item.image.thumb" alt="">
                        </div>
                        <div class="row names">
                            <p><a :href="item.url" v-text="item.name"></a></p>
                            <p class="alias" v-text="item.nameAlias"></p>
                            <div class="grid">
                                <div class="row quantity" v-text="item.quantity"></div>
                                <div class="row separator">x</div>
                                <div class="row price">{{ item.totalPrice | currency }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="cart-carousel">
                <carousel :per-page-custom="[[320, 1], [768, 2], [992, 3]]" :navigation-enabled="true" :pagination-enabled="false">
                    <slide :key="index" v-for="item, index in cart">
                        <div class="grid cart-item">
                            <div class="row image">
                                <img :src="item.image.thumb" alt="">
                            </div>
                            <div class="row names">
                                <p><a :href="item.url">{{ item.name | decode }}</a></p>
                                <p class="alias">{{ item.nameAlias | decode }}</p>
                                <div class="grid">
                                    <div class="row quantity" v-text="item.quantity"></div>
                                    <div class="row separator">x</div>
                                    <div class="row price">{{ item.totalPrice | currency }}</div>
                                </div>
                            </div>
                        </div>
                    </slide>
                </carousel>
            </div>
            <div class="totals">
                <table>
                    <tbody>
                    <tr>
                        <td>Сумма:</td>
                        <td>{{ totals.cart.full | currency }}</td>
                    </tr>
                    <tr v-if="totals.cart.discount > 0">
                        <td>Экономия:</td>
                        <td>{{ totals.cart.discount | currency }}</td>
                    </tr>
                    <tr v-if="totals.deliveryCost > 0">
                        <td>Доставка:</td>
                        <td>{{ totals.deliveryCost | currency }}</td>
                    </tr>
                    <tr v-if="totals.paysystemTax > 0">
                        <td>Комиссия:</td>
                        <td><span>{{ totals.paysystemTax | currency }}</span></td>
                    </tr>
                    </tbody>
                </table>
                <table>
                    <tbody>
                    <tr>
                        <td>Итого:</td>
                        <td>{{ totals.cost | currency }}</td>
                    </tr>
                    <tr>
                        <td>Бонусов:</td>
                        <td>
                            <div class="icon-wrapper bonus-container">
                                <span>+{{ bonus }}</span>
                                <i class="icon icon-bonus-mini"></i>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script type="text/babel">
    import { dimension } from '../entities/helpers';
    import MobileDetect from '../mixins/mobile_detect';

    export default {
        name: "OrderTotals",
        mixins: [ MobileDetect ],
        props: {
            model: {}
        },
        data: () => ({
            init: false,
            cart: [],
            totals: {},
            bonus: 0,
        }),
        computed: {},
        methods: {
            getTotals() {
                this.model.post('/order/api/totals/')
                    .then(r => {
                        this.cart = r.cart;
                        this.totals = r.totals;
                        this.bonus = r.bonus;
                        this.init = true;
                    })
                    .catch(e => console.log(e));
            },
        },
        created() {
            this.getTotals();

            Event.$on('updateOrderTotals', () => this.getTotals());
        }
    }
</script>