<template>
    <div class="show-more" v-if="canMore">
        <a href="#" @click.prevent="loadMore">
            <p class="icon-wrapper" :class="{ 'loading': loading }"><i class="icon icon-plus-big"></i></p>
            <p>ПОКАЗАТЬ</p>
            <p>ЕЩЕ {{ restItems }} ТОВАРА</p>
        </a>
    </div>
</template>

<script type="text/babel">
    export default {
        name: "ShowMore",
        data() {
            return {
//                page: parseInt(this.initPage) + 1,
                loading: false
            };
        },
        props: ['initPage', 'initPageSize', 'initPageCount', 'initTotalCount'],
        computed: {
            page() {
                return parseInt(this.initPage) + 1;
            },
            pageSize() {
                return parseInt(this.initPageSize);
            },
            pageCount() {
                return parseInt(this.initPageCount);
            },
            totalCount() {
                return parseInt(this.initTotalCount);
            },
            restItems() {
                if (this.page == this.pageCount - 1) {
                    return this.totalCount - this.pageSize * this.page;
                }
                return this.pageSize;
            },
            canMore() {
                return this.page != this.pageCount && this.pageCount > 1 && this.restItems > 0;
            }
        },
        watch: {
            initPage(val) {
                this.loading = false;
                // this.page = parseInt(val) + 1;
            }
        },
        methods: {
            loadMore() {
                // skip if loading
                if (this.loading) return;

                const link = this.$parent.pages[this.page];
                const config = {
                    appendProducts: true
                };
                this.loading = true;

                history.pushState(null, document.title, link.url);
                Event.$emit('pushState', {url: link.url, config});
            }
        }
    }
</script>