<template>
    <div>
        <div v-if="! slidesCount">Загружаемся...</div>

        <carousel
                :per-page="1"
                :autoplay="true"
                :autoplay-timeout="duration"
                :autoplay-hover-pause="true"
                :autoplay-loop="true"
                :pagination-padding="4"
                :pagination-size="12"
                :pagination-active-color="'#fff'"
                :infinite-loop="true"
                :navigation-enabled="!isMobile"
                ref="slider">
            <slide :key="index" v-for="slide,index in slides">
                <!--<img :src="slide.image" alt="">-->
                <a :href="slide.url">
                    <picture :title="slide.title">
                        <source :srcset="slide.image.small" media="(max-width: 1170px)">
                        <source :srcset="slide.image.big">
                        <img :src="slide.image.big" :srcset="slide.image.big" alt="">
                    </picture>
                </a>
            </slide>
        </carousel>

<!--        <div class="thumbnails">-->
<!--            <ul>-->
<!--                <li v-for="slide,index in slides" @click="selectSlide(index)" :class="{ 'active': index == carouselIndex() }">-->
<!--                    <img :src="slide.thumbnail" alt="">-->
<!--                </li>-->
<!--            </ul>-->
<!--        </div>-->
    </div>
</template>

<script type="text/babel">
    import MobileDetect from '../mixins/mobile_detect';

    export default {
        name: "Slider",
        data() {
            return {
                currentIndex: 0,
                timer: null,
            };
        },
        mixins: [ MobileDetect ],
        props: {
            startIndex: { default: 0 },
            duration: { default: 5000 },
            slides: { required: true, type: Array }
        },
        computed: {
            slidesCount() {
                return this.slides.length;
            }
        },
        methods: {
            carouselIndex() {
                if (this.$refs.slider) {
                    return this.$refs.slider.$data.currentPage;
                }
                return 0;
            },
            selectSlide(index) {
                this.currentIndex = index;
                this.$refs.slider.goToPage(this.currentIndex)
            },
        },
        mounted() {
            if (this.startIndex) {
                this.currentIndex = this.startIndex;
                this.selectSlide(this.currentIndex);
            }
        }
    }
</script>