<template>
    <div class="user-budget" v-if="userBudget > 0">
        У Вас есть <span class="amount" v-text="userBudget"></span> бонусов, можете
        <span class="container" v-on-clickaway="hidePopup">
            <a href="#" class="link" @click.prevent="showPopup">списать</a>&nbsp;
            <popup :is-active="isPopupActive">
                <form action="" @submit.prevent="handleSubmit" v-if="! isPaid">
                    <div class="form-group">
                        <div class="grid">
                            <label>Сумма:</label>
                            <input type="text" v-model="form.amount" class="form-control">
                            <button-loading :loading="loading" :disabled="loading" class="btn btn-secondary btn-small">Списать</button-loading>
                        </div>
                        <p class="help-block-error" v-if="form.errors.has('amount')" v-text="form.errors.get('amount')"></p>
                        <div v-if="hasExceptions">
                            <p class="help-block-error" v-for="exception in exceptions" v-text="exception"></p>
                        </div>
                    </div>
                </form>
                <div v-else>Вы успешно списали {{ form.amount }} бонусов</div>
            </popup>
        </span>
        их в счет заказа
    </div>
</template>

<script type="text/babel">
    import { ActiveForm } from '../entities/active_form';
    import Popup from '../components/popup.vue';

    export default {
        name: "UserBudgetForm",
        components: {
            Popup
        },
        props: {
            orderId: {
                required: true,
            },
            userBudget: {
                required: true
            }
        },
        data: () => ({
            isPopupActive: false,
            loading: false,
            form: new ActiveForm({
                amount: 0
            }),
            isPaid: false,
            exceptions: [],
        }),
        computed: {
            hasExceptions() {
                return this.exceptions.length > 0;
            }
        },
        methods: {
            showPopup() {
                this.isPopupActive = true;
            },
            hidePopup() {
                this.isPopupActive = false;
            },
            handleSubmit() {
                this.loading = true;
                const url = '/order/payment/part-pay/' + this.orderId + '/';
                this.form.post(url)
                    .then(r => {
                        this.loading = false;
//                        this.isPaid = r.success;
                        this.exceptions = r.exceptions;
                        if (r.success) {
                            this.isPopupActive = false;
                            this.$emit('bonusPaid');
                        }
                    })
                    .catch(e => {
                        this.loading = false;
                        return new Error(e)
                    });
            }
        }
    }
</script>  