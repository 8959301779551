<template>
    <div class="products">
        <div v-if="errorMessage">
            <h4>Ой йо йой!</h4>
            <p>Возникла проблема :( Если можете сообщите нам ошибку, которая написана ниже:</p>
            <code><pre v-text="errorMessage"></pre></code>
        </div>
        <div v-else-if="products.length == 0">Товары не найдены</div>
        <div class="products__content" v-else>
            <product-item :key="product.id" :data="product" :only-active-offers="false" v-for="product, index in products"></product-item>
            <show-more :init-page="page" :init-page-size="pageSize" :init-page-count="pageCount" :init-total-count="totalCount"></show-more>
        </div>

        <div class="category-loader" v-if="loading">
            <loader :loading="loading"></loader>
        </div>

        <pagination :pages="pages" :page="page" :support-state="true" ref="pagination" v-scroll-to="scrollTo"></pagination>
    </div>
</template>

<script type="text/babel">
    import { ActiveForm } from '../entities/active_form';
    import Pagination from '../components/pagination.vue';
    import MobileDetect from '../mixins/mobile_detect';
    import ShowMore from '../catalog/show_more.vue';

    export default {
        name: "ProductContainer",
        mixins: [ MobileDetect ],
        components: {
            Pagination,
            ShowMore,
        },
        props: {
            ajaxMode: {
                type: Boolean,
                default: false
            },
            scrollTo: {
                type: String,
                default: '.products'
            },
            initProducts: Array,
            initPages: Array,
            initPage: Number,
            initPageSize: Number,
            initPageCount: Number,
            initPageTotal: Number,
            initTotalCount: Number,
        },
        data() {
            return {
                init: false,
                loading: false,
                errorMessage: '',
                startPage: 0,
                products: this.initProducts,
                pages: this.initPages,
                page: this.initPage,
                pageSize: this.initPageSize,
                pageCount: this.initPageCount,
                totalCount: this.initTotalCount,
            }
        },
        computed: {},
        methods: {
            getData(url = window.location.href, userConfig = {}) {
                // clear error message
                this.errorMessage = '';

                const config = Object.assign({
                    appendProducts: false
                }, userConfig);

                if (! config.appendProducts)
                    this.loading = true;

                let form = new ActiveForm({
                    append: config.appendProducts,
                    startPage: this.startPage,
                });
                form.post(url)
                    .then(response => {
                        if (config.appendProducts) {
                            this.products = this.products.concat(response.products);
                        } else {
                            this.products = response.products;
                        }

                        this.pages = response.pages;
                        this.page = response.page;
                        this.pageSize = response.pageSize;
                        this.pageCount = response.pageCount;
                        this.totalCount = response.totalCount;

                        // задаем стартовую страницу
                        if (! this.init)
                            this.startPage = this.page;

                        this.init = true;

                        if (! config.appendProducts)
                            this.loading = false;
                    })
                    .catch(e => {
                        this.errorMessage = e;
                        this.loading = false;
                    });
            },
        },
        mounted() {
            if (this.ajaxMode)
                this.getData();
        },
        created() {
            Event.$on('pushState', (event) => {
                this.getData(event.url, event.config);
            });

            window.addEventListener('popstate', (event) => {
                this.getData(document.location.href);
            }, false);
        },
    }
</script>