<template>
    <div class="filter-price" :class="{ 'is-expand': isExpand }">
        <div class="filter-title" @click="toggleExpand" v-text="filterName"></div>
        <div class="filter-options" v-show="canShow">
            <!--:event-type="eventType"-->
            <vue-slider
                ref="slider"
                :lazy="true"
                :tooltip="false"
                :clickable="true"
                :min="minCost"
                :max="maxCost"
                :dot-size="20"
                :event-type="isMobile ? 'touch' : 'mouse'"
                :bg-style="{'backgroundColor': '#d7d7d7'}"
                :process-style="{'backgroundColor': '#fff'}"
                v-model="prices"
            >
            </vue-slider>
            <div class="grid">
                <div class="row">от <input type="number" v-model.lazy="prices[0]"></div>
                <div class="row">до <input type="number" v-model.lazy="prices[1]"></div>
            </div>
            <a :href="url" class="btn btn-secondary btn-block" @click.prevent="handleClick" :disabled="disabled">Применить</a>
        </div>
    </div>
</template>

<script type="text/babel">
    import vueSlider from 'vue-slider-component';
    import MobileDetect from 'mobile-detect';

    export default {
        name: "FilterPrice",
        components: {
            vueSlider
        },
        props: ['filterItem', 'initDisabled'],
        data() {
            return {
                isExpand: false,
                prices: this.filterItem.options[0].id,
            };
        },
        computed: {
            filterName() {
                return this.filterItem.name.toUpperCase();
            },
            canShow() {
                return this.isExpand;
            },
            option() {
                return this.filterItem.options[0];
            },
            minCost() {
                return Number(this.filterItem.min);
            },
            maxCost() {
                return Number(this.filterItem.max);
            },
            url() {
                const regexp = /p:\d+,\d+/;
                let newUrl;
                if (regexp.test(this.option.url)) {
                     newUrl = this.option.url.replace(regexp, 'p:' + this.prices.join(','));
                } else {
                    newUrl = this.option.url + 'p:' + this.prices.join(',') + '/';
                }
                return newUrl;
            },
            isMobile() {
                let md = new MobileDetect(window.navigator.userAgent);
                return Boolean(md.mobile());
            },
            disabled() {
                return this.initDisabled;
            }
        },
        methods: {
            toggleExpand() {
                this.isExpand = ! this.isExpand;
            },
            handleClick() {
                if (this.disabled) return;

                history.pushState(null, document.title, this.url);
                Event.$emit('pushState', {url: this.url});
            }
        },
        watch: {
            filterItem(val) {
                this.prices = val.options[0].id;
            }
        },
        created() {
            this.isExpand = this.filterItem.expand;

            // subscribe for event
            Event.$on('showFilter', () => {
                setTimeout(() => this.$refs.slider.refresh(), 300);
            });
        },
    }
</script>