<template>
    <div class="instagram-posts">
        <div class="instagram-posts__header" v-if="init">
            <div class="header">
                <div class="header__icon"><img :src="user.profile_picture"></div>
                <div class="header__text"><a :href="link" class="header__link" target="_blank">{{ user.full_name }}</a></div>
            </div>
            <div class="grid">
                <div class="info">
                    <div class="info__column">
                        <div class="info__count">{{ user.counts.media }}</div>
                        <div class="info__label">публикации</div>
                    </div>
                    <div class="info__column">
                        <div class="info__count">{{ user.counts.followed_by }}</div>
                        <div class="info__label">подписчики</div>
                    </div>
                    <div class="info__column">
                        <div class="info__count">{{ user.counts.follows }}</div>
                        <div class="info__label">подписки</div>
                    </div>
                </div>
                <div><a :href="link" class="btn btn-light-grey instagram-posts__follow-link" target="_blank">Подписаться</a></div>
            </div>
        </div>
        <carousel :per-page="3" :per-page-custom="[[320, 1], [768, 2], [992, 3]]"
                  :pagination-padding="4"
                  :pagination-size="10"
                  :pagination-active-color="'#4998cc'">
            <slide :key="index" v-for="post,index in posts" class="instagram-posts__slide">
                <a :href="post.link" class="instagram-posts__post-link" target="_blank">
                    <img :src="post.images.low_resolution.url" alt="">
                </a>
            </slide>
        </carousel>
    </div>
</template>

<script>
    export default {
        name: "InstagramPosts",
        props: {
            token: String
        },
        data() {
            return {
                init: false,
                user: {},
                posts: [],
            };
        },
        computed: {
            link() {
                if (this.init) {
                    return 'https://www.instagram.com/' + this.user.username;
                }
                return null;
            }
        },
        created() {
            const userRequest = this.$jsonp('https://api.instagram.com/v1/users/self', { access_token: this.token })
                .then(json => {
                    this.user = json.data;
                    this.init = true;
                    return new Promise((resolve => resolve()));
                })
                .catch(err => {
                    console.log(err);
                });

            userRequest.then(() => {
                // console.log('next promise');
            });

            this.$jsonp('https://api.instagram.com/v1/users/self/media/recent', { access_token: this.token })
                .then(json => {
                    // console.log(json);
                    this.posts = json.data;
                }).catch(err => {
                    console.log(err);
                });
        }
    }
</script>