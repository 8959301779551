<template>
    <div class="cart-product-viewed grid">
        <div class="image">
            <a :href="product.url">
                <img :src="offer.image.thumb">
            </a>
        </div>
        <!--<div class="grid">-->
            <div class="names">
                <div class="primary-name"><a :href="product.url">{{ product.name | decode }}</a></div>
                <div class="secondary-name">{{ originalName | decode }}</div>
            </div>
            <div class="actions">
                <div>
                    <div class="price-container" :class="{ 'with-discount': offer.hasDiscount }" v-if="offer.isAvailable">
                        <div class="price-current"><span v-html="offer.price.current | price"></span> грн</div>
                        <div class="price-old" v-if="offer.hasDiscount"><span v-html="offer.price.old | price"></span> грн</div>
                    </div>
                    <div class="unavailable-text" v-else>Нет в наличии</div>

                    <offers-chooser :current-offer-id="currentOfferId" :init-offers="product.offers" :names="names" @setOffer="setOffer" @addToCart="addToCart"></offers-chooser>

                    <button class="btn btn-small btn-orange btn-buy" @click="addToCart" v-if="offer.canBuy">
                        <span class="hidden-sm-down">В КОРЗИНУ</span>
                        <i class="icon icon-buy-mini hidden-md-up"></i>
                    </button>
                </div>
            </div>
        <!--</div>-->
    </div>
</template>

<script type="text/babel">
    import Config from '../../config';
    import OffersChooser from '../catalog/offers_chooser.vue';
    import { Product } from '../entities/catalog';
    import MobileDetect from '../mixins/mobile_detect';

    export default {
        name: "CartProductViewed",
        mixins: [MobileDetect],
        components: {
            OffersChooser
        },
        props: {
            data: {
                required: true,
                type: Object
            },
        },
        data() {
            return {
                product: new Product(this.data),
            };
        },
        computed: {
            offer() {
                return this.product.getOffer();
            },
            currentOfferId() {
                return this.product.currentOfferId;
            },
            originalName() {
                const property = this.product.getPropertyByCode('name2');
                if (property) {
                    return property.value;
                }
                return;
            },
            names() {
                return {
                    productName: this.product.name,
                    originalName: this.originalName,
                };
            },
        },
        methods: {
            setOffer(id) {
                this.product.setOffer(id);
            },
            addToCart() {
                Event.$emit('addToCart', this.offer.id);
                Event.$emit('removeViewed', this.product.id);
            },
        }
    }
</script>