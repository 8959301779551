<template>
    <div class="menu-toggle-container">
        <button class="btn btn-light-grey toggle-btn" @click.prevent="toggleMenu">
            <i class="icon icon-profile-menu-toggle"></i>
        </button>
        <div class="toggle-content" :class="{ 'is-show': isShow }">
            <slot></slot>
        </div>
    </div>
</template>

<script type="text/babel">
    export default {
        name: "ProfileMenu",
        props: {},
        data: () => ({
            isShow: false,
        }),
        computed: {},
        methods: {
            toggleMenu() {
                this.isShow = ! this.isShow;
            }
        },
        mounted() {
        
        },
        created() {
            
        },
    }
</script>