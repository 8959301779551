<template>
    <div class="order-make" :class="{ 'guest-mode': ! isLogged }">
        <header>
            <h5>ОФОРМЛЕНИЕ ЗАКАЗА</h5>
        </header>
        <template v-if="! init">
            <loader :loading="! init"></loader>
        </template>
        <template v-else>
            <div><img :src="'/build/images/pug_order_success.png' | static" alt=""></div>

            <h4>ВСЕ ПОЛУЧИЛОСЬ!</h4>
            <p class="greeting">Спасибо, <span class="fullname" v-text="order.firstname"></span>, Ваш заказ создан и ему присвоен <span class="order-id">№ {{ orderId }}</span></p>

            <template v-if="! order.isPaid">
                <div class="grid pay-info">
                    <div class="row" v-if="isLogged">
                        <div class="grid">
                            <div class="row label">БОНУСОВ ЗА ЗАКАЗ:</div>
                            <div class="row value">
                                <div class="icon-wrapper">
                                    <span>+{{ order.bonus }}</span>
                                    <i class="icon icon-bonus"></i>
                                </div>
                            </div>
                        </div>
                        <p class="hint">Бонусы начисляются только зарегистрированым пользователям</p>
                    </div>
                    <div class="row">
                        <div class="grid">
                            <div class="row label">СУММА:</div>
                            <div class="row value">{{ order.cost | currency }}</div>
                        </div>
                    </div>
                </div>
            </template>

            <div class="separator"></div>

            <template v-if="! order.isPaid">
                <user-budget-form :order-id="orderId" :user-budget="order.userBudget" @bonusPaid="getOrderInfo" v-if="order.canInnerPay"></user-budget-form>

                <div class="grid" v-if="! order.payments.isPaid && order.payments.isAcquiring">
                    <div class="row">
                        <order-pay :paysystems="order.payments.form"></order-pay>
                    </div>
                    <div class="row">
                        <order-payment-details :order-id="orderId" :has-recipient="order.hasRecipient"></order-payment-details>
                    </div>
                </div>
            </template>

            <div class="grid actions">
                <div class="row">
                    <a href="/" class="btn btn-light-grey btn-block">НА ГЛАВНУЮ</a>
                </div>
                <div class="row" v-if="isLogged">
                    <a href="/profile/orders/" class="btn btn-light-grey btn-block">В "МОИ ЗАКАЗЫ"</a>
                </div>
            </div>
        </template>
    </div>
</template>

<script type="text/babel">
    import { mapGetters } from 'vuex';
    import UserBudgetForm from './user_budget_form.vue';
    import OrderPay from './pay.vue';
    import OrderPaymentDetails from "./payment_details.vue";

    export default {
        components: {
            OrderPaymentDetails,
            OrderPay,
            UserBudgetForm,
        },
        name: "OrderMake",
        props: {
            orderId: {
                type: [ String, Number ],
                required: true,
            }
        },
        data: () => ({
            init: false,
            order: {},
        }),
        computed: {
            ...mapGetters(['isLogged'])
        },
        methods: {
            getOrderInfo() {
                this.axios.get(window.location.href)
                    .then(r => {
                        this.order = r.data;
                        this.init = true;
                    })
                    .catch(e => new Error(e.data));
            },

        },
        mounted() {
            this.getOrderInfo();
        },
        created() {
            
        }
    }
</script>