import config from '../../config';
import { formatPrice } from '../entities/helpers';
import {decode, encode} from '../entities/helpers';

export default {
    name: 'tooltip',
    config: {},
    install (Vue) {
        Vue.filter('currency', (value) => {
            return formatPrice(value) + ' грн';
        });
        // Vue.filter('price', (value) => formatPrice(value));
        Vue.filter('price', (value) => parseInt(value));
        Vue.filter('decode', decode);
        Vue.filter('encode', encode);
        Vue.filter('capitalize', (value) => {
            if (!value) return '';
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        });
        Vue.filter('uppercase', (value) => {
            if (!value) return '';
            return value.toUpperCase();
        });
        Vue.filter('lowercase', (value) => {
            if (!value) return '';
            return value.toLowerCase();
        });
        Vue.filter('stripslashes', (value) => {
            if (!value) return '';
            return value.replace(/\0/g, '0').replace(/\\(.)/g, "$1")
        });
        Vue.filter('quote', (value) => {
            if (!value) return '';
            return value.replace(/&bq;/gi, '"').replace(/&sq;/gi, '\'');
        });
        Vue.filter('static', (value) => {
            if (!value)
                return '';

            if (!config.hasOwnProperty('staticDomain'))
                return value;

            return config.staticDomain + value;
        });
    }
}