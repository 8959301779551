import { mapGetters } from 'vuex';
import _isEmpty from 'lodash/isEmpty';
export default {
    data() {
        return {
            citySearch: '',
            cities: [],
            cityValue: {}
        }
    },
    computed: {
        cityList() {
            if (this.citySearch)
                return this.cities;

            let formattedList = this.getCityPrimaryListFormatted;
            let cityValue;
            if (! _isEmpty(this.cityValue)) {
                cityValue = this.cityValue;
            } else {
                cityValue = this.$store.getters.cityValue;
            }

            if (! formattedList.find(item => item.value == cityValue.value))
                return formattedList.concat(cityValue);

            return formattedList;
        },
        ...mapGetters(['getCityPrimaryListFormatted'])
    },
    methods: {
        getCities(name) {
            this.citySearch = true;
            this.axios.get('/cities/', {
                params: {
                    by_name: name
                }
            }).then(r => {
                this.cities = r.data.map(item => ({
                    text: item.name,
                    value: item.id
                }));
            });
        },
        setCityName(value) {
            this.getCities(value);
        },
    },
}