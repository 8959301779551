<template>
    <div class="filter-panel">
        <a :href="option.url" class="filter-item"  @click.prevent="removeFilter(option)" v-for="option in options">
            {{ option.value | decode }} <span class="close">x</span>
        </a>
        <a :href="clearUrl" class="filter-item" v-if="options.length > 0" @click.prevent="clearFilter">
            Очистить фильтр <span class="close">x</span>
        </a>
        <a href="#" class="show-full" @click.prevent="showMore" v-if="canShowMore">Показать все выбранные</a>
    </div>
</template>

<script type="text/babel">
    import MobileDetect from 'mobile-detect';

    export default {
        name: "FilterPanel",
        props: ['optionsData'],
        data() {
            return {
                showFull: false,
            };
        },
        computed: {
            options() {
                let data = this.optionsData;
                if (this.isPhone && ! this.showFull) {
                    return data.slice(0, 4);
                }
                return data;
            },
            disabled() {
                return this.$parent.loading;
            },
            isPhone() {
                let md = new MobileDetect(window.navigator.userAgent);
                return Boolean(md.phone());
            },
            canShowMore() {
                return ! this.showFull && this.isPhone && ! this.disabled && this.optionsData.length > 4;
            },
            clearUrl() {
                let clearUrl = window.location.pathname
                    .split('/')
                    .filter(item => ! /:/.test(item))
                    .join('/');

                clearUrl += window.location.search;

                return clearUrl;
            },
        },
        methods: {
            removeFilter(option) {
                if (this.disabled) return;

                option.active = false;
                history.pushState(null, document.title, option.url);
                Event.$emit('pushState', {url: option.url});
            },
            clearFilter() {
                if (this.disabled) return;

                this.optionsData.forEach(option => option.active = false);

                history.pushState(null, document.title, this.clearUrl);
                Event.$emit('pushState', {url: this.clearUrl});
            },
            showMore() {
                this.showFull = true;
            }
        }
    }
</script>