<template>
    <div class="swipe-wrapper" :class="classNames">
        <div class="swipe-blocker" v-if="isActive" @click="emitClose"></div>
        <!--v-on-clickaway="emitClose"-->
        <div class="swipe-content">
            <slot></slot>
        </div>
    </div>
</template>

<script type="text/babel">
    export default {
        name: "Swipe",
        data() {
            return {
                active: false,
                browserWidth: 0,
                browserHeight: 0,
                swipeWidth: 0
            };
        },
        computed: {
            classNames() {
                if (this.isActive) {
                    return ['is-active', this.mainClass].join(' ');
                }
                return this.mainClass;
            }
        },
        props: {
            isActive: { required: true, type: Boolean },
            mainClass: { default: '' }
        },
        methods: {
            emitClose() {
                enableScroll();
                this.$emit('close');
            },
        },
        watch: {
            isActive() {
                if (this.isActive) {
                    disableScroll();
                } else {
                    enableScroll();
                }
                this.active = this.isActive;
            }
        },
        mounted() {
            // move element to root element
            document.getElementById('root').appendChild(this.$el);

            // set default active value
            this.active = this.isActive;
        },
        created() {

        }
    }
</script>