<template>
    <div class="profile-item" :class="{ 'is-active': profile.isActive }">
        <div class="primary-container">
            <custom-checkbox :value="profile.primary" @change="handleSetPrimary">{{ profile.name }}</custom-checkbox>
        </div>
        <div class="icon-wrapper delivery-type">
            <i class="icon icon-profile-delivery-type"></i>
            <span v-text="profile.deliveryName"></span>
        </div>
        <div class="icon-wrapper delivery-value">
            <i class="icon icon-profile-delivery-value"></i>
            <span v-text="profile.deliveryValue"></span>
        </div>
        <div class="text-center"><a href="#" class="link" @click.prevent="showEditProfile">Редактировать профиль</a></div>
    </div>
</template>

<script type="text/babel">
    import CustomCheckbox from '../components/custom_checkbox.vue';

    export default {
        components: {
            CustomCheckbox
        },
        name: "DeliveryProfile",
        props: {
            profile: Object
        },
        data: () => ({}),
        computed: {},
        methods: {
            handleSetPrimary() {
                this.$emit('setPrimary', this.profile.id);
            },
            showEditProfile() {
                this.$emit('showEditForm', this.profile);
            }
        },
        mounted() {
        
        },
        created() {
            
        },
    }
</script>