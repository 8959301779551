<template>
    <div>
        <div class="tabs" v-show="! simpleMode">
            <ul>
                <li v-for="tab in tabs" :class="{ 'is-active': tab.isActive }">
                    <a href="#" @click.prevent="selectTab(tab)" v-html="tab.name"></a>
                </li>
            </ul>
        </div>

        <div class="tabs-content">
            <slot></slot>
        </div>
    </div>
</template>

<script type="text/babel">
    import MobileDetect from '../mixins/mobile_detect';

    export default {
        name: "Tabs",
        mixins: [ MobileDetect ],
        data() {
            return {
                tabs: []
            };
        },
        computed: {
            simpleMode() {
                return this.isMobile && ! this.strictMode;
            }
        },
        methods: {
            selectTab(selectedTab) {
                this.tabs.forEach(tab => {
                    tab.isActive = (tab.name == selectedTab.name);
                });

                if (! this.isMobile)
                    this.$nextTick(() => this.$emit('tabSelected', selectedTab));
            },
            selectTabByIndex(index) {
                const tab = this.tabs[index];
                if (tab) {
                    this.selectTab(tab);
                }
            }
        },
        props: {
            /**
             * Disable mobile adaptation
             */
            strictMode: { type: Boolean, default: true }
        },
        created() {
            this.tabs = this.$children;
        }
    }
</script>