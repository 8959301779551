<template>
    <div class="cart-informer-container">
        <div class="cart-informer icon-wrapper is-active" @click="toggleCart" :class="{ 'is-modal-active': showCart }">
            <div class="count">
                <i class="icon icon-top-cart"></i>
                <span>{{ cartCount }}</span>
            </div>
            <div class="hidden-sm-down">
                {{ $t("cart.informer.title") }}
            </div>
        </div>

        <modal :init-modal="showCart" title="КОРЗИНА" :show-title="! isEmptyCart" class="modal-cart" @close="hideCart">
            <div>
                <div class="error-message" v-if="errorMessage" v-text="errorMessage"></div>

                <div class="empty-cart" v-if="isEmptyCart">
                    <div class="loader-wrapper">
                        <loader :loading="loading"></loader>
                    </div>
                    <div><i class="icon icon-empty-cart"></i></div>
                    <p>Корзина пуста</p>
                </div>
                <div class="cart-informer-content" v-else>
                    <div class="scroll-wrapper" v-item-height="showCart">
                        <div v-bar>
                            <div>
                                <div class="loader-wrapper" :class="{'is-loading': loading}">
                                    <loader :loading="loading"></loader>
                                </div>
                                <div class="inner-content">
                                    <cart-informer-block
                                            :item="item"
                                            :key="item.id"
                                            :loading.sync="loading"
                                            @remove="onRemove"
                                            @pushError="handleError"
                                            v-for="item in items"
                                            v-if="item.quantity > 0">
                                    </cart-informer-block>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="total-container">
                        <div class="grid info">
                            <div class="row discounts-wrapper">
                                <cart-coupon :init-coupon="coupon" @assigned="getItems"></cart-coupon>
                                <!--<cart-delivery-progress :init-cost="cartTotalFull"></cart-delivery-progress>-->
                            </div>
                            <div class="row stats">
                                <div>
                                    <table>
                                        <tr v-if="cartTotalWithoutDiscount != cartTotalFull">
                                            <td>Сумма без скидки:</td>
                                            <td>{{ cartTotalWithoutDiscount | currency }}</td>
                                        </tr>
                                        <tr>
                                            <td>Итого:</td>
                                            <td>{{ cartTotalFull | currency }}</td>
                                        </tr>
                                        <tr class="economy" v-if="cartTotalDiscount > 0">
                                            <td>Вы экономите:</td>
                                            <td>{{ cartTotalDiscount | currency }}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="grid actions">
                            <div class="row"><a href="#" class="btn btn-block btn-secondary" @click.prevent="hideCart">ПРОДОЛЖИТЬ ПОКУПКИ</a></div>
                            <div class="row"><a :href="orderLink" @click.prevent="showAverageProductsModal" class="btn btn-block btn-primary">ОФОРМИТЬ ЗАКАЗ</a></div>
                        </div>
                    </div>
                </div>
                <div class="products-viewed" v-if="productsViewed.length > 0">
                    <h3>НЕДАВНО ПРОСМОТРЕННЫЕ</h3>
                    <cart-product-viewed :key="productViewed.id" :data="productViewed" v-for="productViewed in productsViewed"></cart-product-viewed>
                </div>
            </div>
        </modal>

        <modal :init-modal="showAverageProducts" class="modal-average" title="ВОЗМОЖНО ВАМ ПОНАДОБИТСЯ" @close="showAverageProducts = false">
            <div class="average">
                <carousel :per-page-custom="[[768, 2], [992, 3], [1170, 4]]" :navigation-enabled="true" :pagination-enabled="false">
                    <slide :key="index" v-for="average,index in averageItems">
                        <!--<product-item :key="index" :data="average"></product-item>-->
                        <average-product-item :key="index" :data="average"></average-product-item>
                    </slide>
                </carousel>
            </div>
            <div class="controls">
                <button class="btn btn-link controls__button" @click="skip">Больше не показывать</button>
                <button class="btn btn-primary controls__button controls__button--last" @click="goToOrderPage">ПРОДОЛЖИТЬ ОФОРМЛЕНИЕ ЗАКАЗА</button>
            </div>
        </modal>
    </div>
</template>

<script type="text/babel">
    import { dimension } from '../entities/helpers';
    import { ActiveForm } from '../entities/active_form';

    import CartInformerBlock from './cart_informer_block.vue';
    import CartCoupon from './cart_coupon.vue';
    import CartDeliveryProgress from './cart_delivery_progress.vue';
    import CartProductViewed from './cart_product_viewed.vue';
    import AverageProductItem from './average_product_item.vue';

    export default {
        name: 'CartInformer',
        components: {
            CartProductViewed,
            CartDeliveryProgress,
            CartCoupon,
            CartInformerBlock,
            AverageProductItem,
        },
        props: {
            orderLink: { required: true },
            open: {
                type: [Boolean, Number],
                default: false,
            }
        },
        data() {
            return {
                init: false,
                showCart: Boolean(this.open),
                showAverageProducts: false,
                items: [],
                viewed: [],
                averageItems: [],
                loading: false,
                coupon: null,
                errorMessage: null,
            }
        },
        computed: {
            isEmptyCart() {
                return this.cartCount == 0;
            },
            cartCount() {
                let total = 0;
                if (this.items) {
                    this.items.forEach(item => total += parseInt(item.quantity));
                }
                return total;
            },
            cartTotalFull() {
                let price = 0;
                if (this.items) {
                    this.items.forEach(item => {
                        if (item.hasDiscount) {
                            price += parseInt(item.discountPrice * item.quantity);
                        } else {
                            price += parseInt(item.price * item.quantity);
                        }
                    });

//                    if (this.couponDiscount > 0) {
//                        price = price - (price * this.couponDiscount);
//                    }
                }
                return Math.ceil(price);
            },
            cartTotalWithoutDiscount() {
                let price = 0;
                if (this.items) {
                    this.items.forEach(item => {
                        price += parseInt(item.price * item.quantity);
                    });
                }
                return Math.ceil(price);
            },
            cartTotalDiscount() {
                return this.cartTotalWithoutDiscount - this.cartTotalFull;
            },
            productsViewed() {
                const cartItemsId = this.items.map(item => item.product_id);
                const viewedProducts = this.viewed.filter(item => cartItemsId.indexOf(item.id) == -1);
                if (viewedProducts.length > 2) {
                    return viewedProducts.slice(0, 2);
                }
                return viewedProducts;
            },
            canShowAverageProducts() {
                const valueFromCookie = this.$cookie.get('can_show_average_modal');
                return ! Boolean(valueFromCookie);
            }
        },
        methods: {
            getItems() {
                this.loading = true;
                this.axios.get('/cart/items/').then(r => {
                    this.loading = false;
                    this.items = r.data.items;
                    this.coupon = r.data.coupon;
                    // for add cart event
//                    if (this.init) {
//                        this.showCart = true;
//                    }
                    this.init = true;
                });
            },
            getProductsViewed() {
                this.axios.get('/product/viewed/').then(r => {
                    this.viewed = r.data;
                });
            },
            addItem(id, options) {
                let form = new ActiveForm({
                    offer_id: id,
                    quantity: 1,
                    stats: options.stats
                });
                this.loading = true;
                form.post('/cart/add/')
                    .then(r => {
                        if (r.success) {
                            this.getItems();
                        } else {
                            if (r.errors.quantity) {
                                this.handleError(r.errors.quantity[0], 3000);
                            }
                        }
                        this.loading = false;

                        // update average list
                        this.getAverageProducts();
                    })
                    .catch(e => {
                        this.handleError(e);
                    });
            },
            toggleCart() {
                if (! this.showCart)
                    fbq('track', 'InitiateCheckout');

                this.showCart = ! this.showCart;
            },
            hideCart() {
                this.showCart = false;
            },
            /**
             * Add this method to common interface
             * See MobileHotActions
             */
            showModal() {
                this.toggleCart();
            },
            /**
             * Remove from cart
             * @param id
             */
            onRemove(id) {
                this.items = this.items.filter(item => item.offer_id !== id);
            },
            handleError(errorMessage, duration) {
                this.errorMessage = errorMessage;

                duration = parseInt(duration);
                if (duration) {
                    setTimeout(() => this.errorMessage = null, duration);
                }
            },
//            applyDiscount(discount) {
//                this.couponDiscount = discount;
//            },
            getAverageProducts() {
                this.averageItems = [];
                this.axios.get('/cart/average-products/')
                    .then(r => {
                        this.averageItems = r.data;
                    });
            },
            showAverageProductsModal() {
                if (this.canShowAverageProducts) {
                    this.showAverageProducts = true;
                } else {
                    window.location.href = this.orderLink;
                }
            },
            skip() {
                this.$cookie.set('can_show_average_modal', 1);
                this.goToOrderPage();
            },
            goToOrderPage() {
                window.location.href = this.orderLink;
            }
        },
        directives: {
            itemHeight: {
                componentUpdated(el, binding) {
                    if (! binding.value) return;

                    let height = 0;
                    let content = el.getElementsByClassName('inner-content')[0];
                    if (content) {
                        if (content.childElementCount >= 2) {
                            for (let i = 0; i < 2; i++) {
                                height += dimension(content.childNodes[i]);
                            }
                        }
                    }

                    if (height  > 0) {
                        content.style['max-height'] = height + 'px';
                    }
                }
            },
        },
        created() {
            // get current cart
            this.getItems();

            this.getProductsViewed();

            this.getAverageProducts();

            // subscribe for add item to cart event
            Event.$on('addToCart', (id, options) => {
                const defaultOptions = {
                    showCart: true,
                    stats: {}
                };
                options = Object.assign({}, defaultOptions, options);

                this.addItem(id, options);
                this.showCart = options.showCart;
            });

            // subscribe for removing product from viewed
            Event.$on('removeViewed', (id) => {
                setTimeout(() => this.viewed = this.viewed.filter(item => item.id != id), 1);
            });
        }
    }
</script>