<template>
    <div>
        <button type="button" :class="btnClass" @click="showModal">
            <slot></slot>
        </button>

        <modal :init-modal="isModalActive" class="modal-available-request" title="Уведомить о наличии" @close="hideModal">
            <form @submit.prevent="handleSubmit" @keydown="model.errors.clear($event.target.name)" v-if="! formSend">
                <div class="form-group">
                    <label>Ваш email</label>
                    <input type="text" name="email" v-model="model.email" class="form-control" placeholder="Введите Ваш email">
                    <p class="help-block-error" v-if="model.errors.has('email')" v-text="model.errors.get('email')"></p>
                </div>
                <div>
                    <button-loading type="submit" :loading="loading" :disabled="loading || model.errors.any()" class="btn btn-block btn-primary">Уведомить</button-loading>
                </div>
            </form>
            <div class="text-center" v-else>Спасибо, мы Вам сообщим когда товар появится в наличии</div>
        </modal>
    </div>
</template>

<script type="text/babel">
    import { ActiveForm } from '../entities/active_form';

    export default {
        name: "AvailableInformer",
        props: {
            initOffer: {
                type: Object,
                required: true,
            },
            btnClass: String
        },
        components: {
        },
        data: () => ({
            loading: false,
            isModalActive: false,
            formSend: false,
            model: new ActiveForm({
                offer_id: '',
                email: '',
            })
        }),
        computed: {
            offer() {
                return this.initOffer;
            }
        },
        methods: {
            showModal() {
                this.isModalActive = true;
            },
            hideModal() {
                this.isModalActive = false;
            },
            handleSubmit() {
                this.loading = true;
                this.model.offer_id = this.offer.id;
                this.model.post('/product/reminder/add/')
                    .then(r => {
                        if (r.success) {
                            this.formSend = true;
                        }
                        this.loading = false;
                    })
            }
        },
        mounted() {
        
        },
        created() {
            
        },
    }
</script>