<template>
    <div class="spy-block">
        <slot></slot>
    </div>
</template>

<script type="text/babel">
    export default {
        name: "SpyBlock",
        props: {

        },
        data() {
            return {

            };
        },
        methods: {
            handleScrollEvent() {
                scroll();
            }
        },
        created() {
            this.$on('refresh', this.handleScrollEvent);
            window.addEventListener('scroll', scroll)
        }
    }

    function scroll() {
        let el = document.getElementsByClassName('spy-block')[0];
        let fixedHeader = document.getElementsByClassName('top-menu')[0];
        let top = parseInt(window.scrollY - el.parentNode.offsetTop + fixedHeader.offsetHeight);

        if (top < 0) {
            top = 0;
        }

        // prevent overflow in container
        let maxTop = el.parentNode.offsetTop + el.parentNode.offsetHeight - el.offsetHeight - fixedHeader.offsetHeight;
        if (window.scrollY > maxTop) {
            top = maxTop - el.parentNode.offsetTop + fixedHeader.offsetHeight;
        }

        el.style['top'] = top + 'px';
    }
</script>