<template>
    <div>
        <template v-if="! isLogged && ! isRegistrationSkipped">
            <form action="" @submit.prevent="findUser" @keydown="model.errors.clear($event.target.name)" v-on:keydown="initUser = false">
                <div class="grid center">
                    <div class="row">
                        <div class="form-group">
                            <!--<label for="user-anonymous-phone">Для определения пользователя:</label>-->
                            <div class="input-group">
                                <i class="icon icon-phone"></i>
                                <input type="tel"
                                       name="phone" id="user-anonymous-phone"
                                       v-mask="getConfig('phone.mask')" v-model="model.phone"
                                       :class="{'completed' : model.phone.length > 0}"
                                       class="form-control" placeholder="Введите Ваш номер телефона">
                            </div>
                            <p class="help-block-error" v-if="model.errors.has('phone')" v-text="model.errors.get('phone')"></p>
                            <p class="hint" v-if="initUser">
                                <span v-if="isUserFound"><span class="username">Такой пользователь уже существует</span></span>
                                <span v-else>Номер не найден</span>
                            </p>
                        </div>
                        <div class="form-group" :class="{ 'actions': ! initUser }">
                            <template v-if="! initUser || model.errors.has('phone')">
                                <button-loading class="btn btn-secondary btn-block continue" :loading="isLoading" :disabled="isLoading || model.errors.has('phone') || rawPhone.length == 0">ПРОДОЛЖИТЬ</button-loading>
                                <!--<button class="btn btn-secondary btn-block continue" :disabled="model.errors.has('phone')">ПРОДОЛЖИТЬ</button>-->
                            </template>
                            <template v-else>
                                <template v-if="isUserFound">
                                    <div class="form-group">
                                        <button type="button" class="btn btn-primary btn-block" @click="showAuthForm">ВОЙТИ</button>
                                    </div>
                                    <div class="form-group">
                                        <button-loading class="btn btn-secondary btn-block" @click="skipRegistration" :loading="isLoading" :disabled="isLoading">ПРОДОЛЖИТЬ БЕЗ АВТОРИЗАЦИИ</button-loading>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="form-group">
                                        <button-loading class="btn btn-primary btn-block" @click="skipRegistration" :loading="isLoading" :disabled="isLoading">ПРОДОЛЖИТЬ БЕЗ РЕГИСТРАЦИИ</button-loading>
                                    </div>
                                    <div class="form-group">
                                        <button type="button" class="btn btn-secondary btn-block" @click="goToSignupPage">ЗАРЕГИСТРИРОВАТЬСЯ</button>
                                    </div>
                                </template>

                                <div class="bonus-hint" v-if="bonus > 0">
                                    <p><span class="bonus-hint__mark">{{ isUserFound ? 'Авторизируйтесь' : 'Зарегистрируйтесь' }}</span>,</p>
                                    <p>чтобы не потерять <span class="bonus-hint__mark">{{ bonus }} бонусов</span></p>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </form>
        </template>
        <template v-else-if="isRegistrationSkipped">
            <form action="" @submit.prevent="onSubmit" @keydown="model.errors.clear($event.target.name)">
                <div class="grid center">
                    <div class="row">
                        <div class="form-group" @click="resetFirstStep">
                            <label>Ваши данные:</label>
                            <div class="input-group">
                                <i class="icon icon-phone"></i>
                                <input type="tel"
                                       name="phone"
                                       v-mask="getConfig('phone.mask')" v-model="model.phone"
                                       :class="{'completed' : model.phone.length > 0}"
                                       class="form-control" placeholder="Введите Ваш номер телефона*">
                            </div>
                            <p class="help-block-error" v-if="model.errors.has('phone')" v-text="model.errors.get('phone')"></p>
                        </div>
                        <div class="form-group">
                            <div class="input-group">
                                <i class="icon icon-phone"></i>
                                <input type="tel"
                                       name="alternativePhone"
                                       v-mask="getConfig('phone.mask')" v-model="model.alternativePhone"
                                       :class="{'completed' : model.alternativePhone.length > 0}"
                                       class="form-control" placeholder="Альтернативный номер телефона">
                            </div>
                            <p class="help-block-error" v-if="model.errors.has('alternativePhone')" v-text="model.errors.get('alternativePhone')"></p>
                            <p class="hint small">Вы можете указать дополнительный номер телефона для связи.<br><b>Смс сообщения о статусе заказа будут приходить только на основной номер</b></p>
                        </div>
                        <div class="form-group">
                            <div class="input-group">
                                <i class="icon icon-man"></i>
                                <input type="text" name="firstname" v-model="model.firstname" class="form-control" :class="{'completed' : model.firstname.length > 0}" placeholder="Введите Ваше имя*"  autocomplete="off">
                            </div>
                            <p class="help-block-error" v-if="model.errors.has('firstname')" v-text="model.errors.get('firstname')"></p>
                        </div>
                        <div class="form-group">
                            <div class="input-group">
                                <i class="icon icon-man"></i>
                                <input type="text" name="lastname" v-model="model.lastname" class="form-control" :class="{'completed' : model.lastname.length > 0}" placeholder="Введите Вашу фамилию*" autocomplete="off">
                            </div>
                            <p class="help-block-error" v-if="model.errors.has('lastname')" v-text="model.errors.get('lastname')"></p>
                        </div>
                        <div class="form-group m-t-20">
                            <div class="input-group">
                                <i class="icon icon-mail"></i>
                                <input type="text" name="email" v-model="model.email" class="form-control" :class="{'completed' : model.email.length > 0}" placeholder="Введите Ваш email" autocomplete="off">
                            </div>
                            <p class="help-block-error" v-if="model.errors.has('email')" v-text="model.errors.get('email')"></p>
                        </div>
                        <div class="icon-wrapper icon-wrapper__center">
                            <span>это поле необязательно</span>
                            <!--<i class="icon icon-info" v-tooltip="'но это не точно'"></i>-->
                        </div>

                        <div class="form-group actions">
                            <button-loading class="btn btn-secondary btn-block continue" :loading="isLoading" :disabled="isLoading || model.errors.any()">ПРОДОЛЖИТЬ</button-loading>
                            <!--<button class="btn btn-secondary btn-block continue" :disabled="model.errors.any()">ПРОДОЛЖИТЬ</button>-->
                        </div>
                    </div>
                </div>
            </form>
        </template>
        <template v-else-if="isLogged">
            <form action="" @submit.prevent="onSubmit" @keydown="model.errors.clear($event.target.name)">
                <div class="grid center">
                    <div class="row" v-on-clickaway="hideDisabledHint">
                        <div class="form-group" @click="showDisabledHint('phone')">
                            <label>Ваши данные:</label>
                            <div class="input-group">
                                <i class="icon icon-phone"></i>
                                <input type="tel" name="phone"
                                       v-mask="getConfig('phone.mask')" v-model="model.phone"
                                       :disabled="isDisabled('phone')"
                                       :class="{'completed' : model.phone.length > 0}"
                                       class="form-control" placeholder="Введите Ваш номер телефона">
                            </div>
                            <p class="help-block-error" v-if="model.errors.has('phone')" v-text="model.errors.get('phone')"></p>
                            <p class="help-block-error" v-if="isDisabledHint('phone')">Изменение данных возможно только через Личный кабинет</p>
                        </div>
                        <div class="form-group">
                            <div class="input-group">
                                <i class="icon icon-phone"></i>
                                <input type="tel"
                                       name="alternativePhone"
                                       v-mask="getConfig('phone.mask')" v-model="model.alternativePhone"
                                       :class="{'completed' : model.alternativePhone.length > 0}"
                                       class="form-control" placeholder="Альтернативный номер телефона">
                            </div>
                            <p class="help-block-error" v-if="model.errors.has('alternativePhone')" v-text="model.errors.get('alternativePhone')"></p>
                            <p class="hint small">Вы можете указать дополнительный номер телефона для связи.<br><b>Смс сообщения о статусе заказа будут приходить только на основной номер</b></p>
                        </div>
                        <div class="form-group" @click="showDisabledHint('firstname')">
                            <div class="input-group">
                                <i class="icon icon-man"></i>
                                <input type="text" name="firstname" v-model="model.firstname" class="form-control" :class="{'completed' : model.firstname.length > 0}" :disabled="isDisabled('firstname')" placeholder="Введите Ваше имя*" autocomplete="off">
                            </div>
                            <p class="help-block-error" v-if="model.errors.has('firstname')" v-text="model.errors.get('firstname')"></p>
                            <p class="help-block-error" v-if="isDisabledHint('firstname')">Изменение данных возможно только через Личный кабинет</p>
                        </div>
                        <div class="form-group" @click="showDisabledHint('lastname')">
                            <div class="input-group">
                                <i class="icon icon-man"></i>
                                <input type="text" name="lastname" v-model="model.lastname" class="form-control" :class="{'completed' : model.lastname.length > 0}" :disabled="isDisabled('lastname')" placeholder="Введите Вашу фамилию*" autocomplete="off">
                            </div>
                            <p class="help-block-error" v-if="model.errors.has('lastname')" v-text="model.errors.get('lastname')"></p>
                            <p class="help-block-error" v-if="isDisabledHint('lastname')">Изменение данных возможно только через Личный кабинет</p>
                        </div>
                        <div class="form-group" @click="showDisabledHint('email')">
                            <div class="input-group">
                                <i class="icon icon-mail"></i>
                                <input type="text" name="email" v-model="model.email" class="form-control" :class="{'completed' : model.email.length > 0}" :disabled="isDisabled('email')" placeholder="Введите Ваш email" autocomplete="off">
                            </div>
                            <p class="help-block-error" v-if="model.errors.has('email')" v-text="model.errors.get('email')"></p>
                            <p class="help-block-error" v-if="isDisabledHint('email')">Изменение данных возможно только через Личный кабинет</p>
                        </div>
                        <div class="checkbox-container">
                            <div class="grid">
                                <div class="row">
                                    <custom-checkbox v-model="model.isRecipientNotMe" @change="model.errors.clear()">Получателем будет другой человек</custom-checkbox>
                                </div>
                                <div class="row"><i class="icon icon-info"></i></div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="model.isRecipientNotMe">
                        <div class="form-group">
                            <label>Данные получателя:</label>
                            <input type="tel" name="recipientPhone"
                                   v-mask="getConfig('phone.mask')" v-model="model.recipientPhone"
                                   :class="{'completed' : model.recipientPhone.length > 0}"
                                   class="form-control" placeholder="Номер телефона получателя">
                            <p class="help-block-error" v-if="model.errors.has('recipientPhone')" v-text="model.errors.get('recipientPhone')"></p>
                        </div>
                        <div class="form-group">
                            <input type="text" name="recipientFirstname" v-model="model.recipientFirstname" class="form-control" :class="{'completed' : model.recipientFirstname.length > 0}" placeholder="Имя получателя*" autocomplete="off">
                            <p class="help-block-error" v-if="model.errors.has('recipientFirstname')" v-text="model.errors.get('recipientFirstname')"></p>
                        </div>
                        <div class="form-group">
                            <input type="text" name="recipientLastname" v-model="model.recipientLastname" class="form-control" :class="{'completed' : model.recipientLastname.length > 0}" placeholder="Фамилия получателя*" autocomplete="off">
                            <p class="help-block-error" v-if="model.errors.has('recipientLastname')" v-text="model.errors.get('recipientLastname')"></p>
                        </div>
                        <div class="checkbox-container">
                            <div class="grid">
                                <div class="row"><custom-checkbox v-model="model.isGift">это подарок для этого человека</custom-checkbox></div>
                                <div class="row"><i class="icon icon-info"></i></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="grid center">
                    <div class="row">
                        <div class="form-group actions">
                            <button-loading class="btn btn-secondary btn-block continue" :loading="isLoading" :disabled="isLoading || model.errors.any()">ДАЛЕЕ</button-loading>
                        </div>
                    </div>
                </div>
            </form>
        </template>
    </div>
</template>

<script type="text/babel">
    import Config from '../mixins/config';
    import OrderStep from '../mixins/order_step';
    import { mapGetters } from 'vuex';
    import { ActiveForm } from '../entities/active_form';
    import CustomCheckbox from '../components/custom_checkbox.vue';

    export default {
        name: "OrderUser",
        components: {
            CustomCheckbox,
        },
        mixins: [ Config, OrderStep ],
        data: () => ({
            loading: true,
            isRegistrationSkipped: false,
            initUser: false,
            isUserFound: false,
            profileDisabledFields: [],
            currentDisabledField: null,
            bonus: 0,
        }),
        computed: {
            rawPhone() {
                return this.model.phone.replace(/[^\d]/, '');
            },
            ...mapGetters(['isLogged'])
        },
        methods: {
            findUser() {
                this.loading = true;
                this.model.post('/order/api/find-user/')
                    .then(r => {
                        this.initUser = true;
                        this.isUserFound = r.success;
                        this.loading = false;
                    })
            },
            skipRegistration() {
                this.isRegistrationSkipped = true;
            },
            resetFirstStep() {
                this.initUser = false;
                this.isRegistrationSkipped = false;
            },
            showAuthForm() {
                Event.$emit('showAuthForm', this.model.phone);
            },
            goToSignupPage() {
                window.location.href = '/signup/';
            },
            isDisabled(field) {
                return this.profileDisabledFields.indexOf(field) > -1;
            },
            showDisabledHint(field) {
                if (this.isDisabled(field)) {
                    this.currentDisabledField = field;
                }
            },
            hideDisabledHint() {
                this.currentDisabledField = null;
            },
            isDisabledHint(field) {
                return this.currentDisabledField == field;
            },
            getTotals() {
                this.model.post('/order/api/bonus/')
                    .then(r => {
                        this.bonus = r.bonus;
                    })
                    .catch(e => console.log(e));
            },
        },
        mounted() {
            if (this.isLogged) {
                // загружаем данные пользователя
                this.axios.get('/order/api/user-data/')
                    .then(r => {
                        for (let key in r.data) {
                            if (r.data[key]) {
                                this.profileDisabledFields.push(key);
                            }
                        }
                        this.model = Object.assign(this.model, r.data);
                        this.loading = false;
                    });
            } else {
                this.loading = false;
            }
        },
        created() {
            this.getTotals();
        }
    }
</script>  