<template>
    <div>
        <div class="alert alert-info" v-if="isNeedToFill">Заполните Ваш профиль, пожалуйста</div>
        <div class="profile-container account-container">
            <header>ЛИЧНЫЕ ДАННЫЕ</header>
            <p class="label">Контактные данные</p>
            <div class="alert alert-success" v-if="profileUpdateMessage" v-text="profileUpdateMessage"></div>
            <form action="" @submit.prevent="handleAccountUpdate" @keydown="accountModel.errors.clear($event.target.name)">
                <div class="grid">
                    <div class="row">
                        <div class="form-group">
                            <label for="lastname">Ваша фамилия:</label>
                            <input type="text" id="lastname" name="lastname" v-model="accountModel.lastname" class="form-control" placeholder="Введите фамилию">
                            <p class="help-block-error" v-if="accountModel.errors.has('lastname')" v-text="accountModel.errors.get('lastname')"></p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group">
                            <label for="lastname">Ваше имя:</label>
                            <input type="text" id="firstname" name="lastname" v-model="accountModel.firstname" class="form-control" placeholder="Введите имя">
                            <p class="help-block-error" v-if="accountModel.errors.has('firstname')" v-text="accountModel.errors.get('firstname')"></p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group">
                            <label for="lastname">Ваш email:</label>
                            <input type="text" id="email" name="lastname" v-model="accountModel.email" class="form-control" placeholder="Введите email">
                            <p class="help-block-error" v-if="accountModel.errors.has('email')" v-text="accountModel.errors.get('email')"></p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group">
                            <label>Ваш пол:</label>
                            <custom-select :values="genderValues" v-model="accountModel.gender" @keydown="accountModel.errors.clear('gender')" class="form-control"></custom-select>
                            <p class="help-block-error" v-if="accountModel.errors.has('gender')" v-text="accountModel.errors.get('gender')"></p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group">
                            <label for="phone">Ваш номер телефона:</label>
                            <input type="tel" name="phone" v-mask="getConfig('phone.mask')" id="phone" v-model="accountModel.phone" class="form-control" placeholder="Номер телефона">
                            <p class="help-block-error" v-if="accountModel.errors.has('phone')" v-text="accountModel.errors.get('phone')"></p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group">
                            <label for="birthday">Дата рождения:</label>
                            <input type="tel" name="birthday" v-mask="'##.##.####'" id="birthday" v-model="accountModel.birthday" class="form-control" placeholder="дд.мм.гггг">
                            <p class="help-block-error" v-if="accountModel.errors.has('birthday')" v-text="accountModel.errors.get('birthday')"></p>
                        </div>
                    </div>
                    <div class="row">
                        <a href="#" class="link" @click.prevent="showAlternativePhoneInput = true" v-if="! showAlternativePhoneInput">Дополнительный номер телефона</a>
                        <div class="form-group" v-if="showAlternativePhoneInput">
                            <label for="alternativePhone">Ваш альтернативный номер телефона:</label>
                            <input type="tel" name="alternativePhone" v-mask="getConfig('phone.mask')" id="alternativePhone" v-model="accountModel.alternativePhone" class="form-control" placeholder="Альтернативный номер телефона">
                            <p class="help-block-error" v-if="accountModel.errors.has('alternativePhone')" v-text="accountModel.errors.get('alternativePhone')"></p>
                        </div>
                    </div>
                </div>
                <button-loading class="btn btn-primary btn-big btn-block btn-submit" :loading="accountLoading" :disabled="accountLoading">СОХРАНИТЬ</button-loading>
            </form>
        </div>

        <a href="#" class="link" @click.prevent="showPasswordChangeForm = true" v-if="! showPasswordChangeForm">Изменить пароль</a>
        <div class="profile-container password-container" v-if="showPasswordChangeForm">
            <div>
                <p class="label">Изменить пароль</p>
                <p class="text-center" v-if="passwordUpdateMessage" v-text="passwordUpdateMessage"></p>
                <form action="" @submit.prevent="handlePasswordUpdate" @keydown="passwordModel.errors.clear($event.target.name)">
                    <div class="grid">
                        <div class="row">
                            <div class="form-group">
                                <label for="currentPassword">Старый пароль:</label>
                                <input type="password" id="currentPassword" name="currentPassword" v-model="passwordModel.currentPassword" class="form-control">
                                <p class="help-block-error" v-if="passwordModel.errors.has('currentPassword')" v-text="passwordModel.errors.get('currentPassword')"></p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group">
                                <label for="newPassword">Новый пароль:</label>
                                <input type="password" id="newPassword" name="newPassword" v-model="passwordModel.newPassword" class="form-control">
                                <p class="help-block-error" v-if="passwordModel.errors.has('newPassword')" v-text="passwordModel.errors.get('newPassword')"></p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group">
                                <label for="confirmNewPassword">Подтвердите пароль:</label>
                                <input type="password" id="confirmNewPassword" name="confirmNewPassword" v-model="passwordModel.confirmNewPassword" class="form-control">
                                <p class="help-block-error" v-if="passwordModel.errors.has('confirmNewPassword')" v-text="passwordModel.errors.get('confirmNewPassword')"></p>
                            </div>
                        </div>
                    </div>
                    <button-loading class="btn btn-primary btn-big btn-block btn-submit" :loading="passwordLoading" :disabled="passwordLoading">ИЗМЕНИТЬ</button-loading>
                </form>
            </div>
        </div>
    </div>
</template>

<script type="text/babel">
    import { ActiveForm } from '../entities/active_form';
    import Config from '../mixins/config';

    export default {
        components: {
        },
        name: "ProfileAccount",
        mixins: [ Config ],
        props: {
            initNeedToFill: {
                type: [Boolean, Number],
                default: false,
            }
        },
        data() {
            return {
                isNeedToFill: this.initNeedToFill,
                genderValues: [
                    {text: 'Мужской', value: 1},
                    {text: 'Женский', value: 2},
                ],
                accountLoading: false,
                accountModel: new ActiveForm({
                    lastname: '',
                    firstname: '',
                    email: '',
                    gender: '',
                    phone: '',
                    birthday: '',
                    alternativePhone: '',
                }),
                passwordLoading: false,
                passwordModel: new ActiveForm({
                    currentPassword: '',
                    newPassword: '',
                    confirmNewPassword: '',
                }),
                profileUpdateMessage: null,
                passwordUpdateMessage: null,
                showAlternativePhoneInput: false,
                showPasswordChangeForm: false,
            };
        },
        computed: {},
        methods: {
            handleAccountUpdate() {
                this.profileUpdateMessage = '';
                this.accountLoading = true;
                this.accountModel.post('/profile/account/update/')
                    .then(r => {
                        this.isNeedToFill = r.isNeedToFill;
                        if (r.success) {
                            this.profileUpdateMessage = 'Профиль успешно сохранен';
                        } else {
                            // this.profileUpdateMessage = 'Ошибка при сохранении профиля';
                        }
                        this.accountLoading = false;
                    });
            },
            handlePasswordUpdate() {
                this.passwordUpdateMessage = '';
                this.passwordLoading = true;
                this.passwordModel.post('/profile/account/password/')
                    .then(r => {
                        if (r.success) {
                            this.passwordUpdateMessage = 'Пароль успешно обновлен';
                            this.passwordModel.currentPassword = '';
                            this.passwordModel.newPassword = '';
                            this.passwordModel.confirmNewPassword = '';
                        } else {
                            // this.passwordUpdateMessage = 'Ошибка при обновлении пароля';
                        }
                        this.passwordLoading = false;
                    });
            }
        },
        mounted() {
            this.axios.get(window.location.href)
                .then(r => {
                    this.accountModel = Object.assign(this.accountModel, r.data);

                    if (this.accountModel.alternativePhone)
                        this.showAlternativePhoneInput = true;
                });
        },
        created() {
            
        },
    }
</script>