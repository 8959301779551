<template>
    <div>
        <transition
                name="fade-custom"
                enter-active-class="animated bounceInRight"
                leave-active-class="animated bounceOutRight"
        >
            <a href="#" class="mobile-action hot-actions" :class="{ 'is-active': isActive }" @click.prevent="toggleActions" v-show="isShow">
                <i class="icon icon-mobile-bigmac"></i>
            </a>
        </transition>
            <transition
                    name="move-top-custom"
                    enter-active-class="animated bounceInUp"
                    leave-active-class="animated bounceOutDown"
            >
                <div class="mobile-bottom-menu" v-show="isActive">
                    <div class="grid">
                        <div class="row">
                            <a href="/dostavka-i-oplata/">
                                <span><i class="icon icon-mobile-delivery-small"></i></span>
                                <p class="hidden-xs-down">Доставка и оплата</p>
                                <p class="hidden-sm-up">Доставка</p>
                            </a>
                        </div>
                        <div class="row">
                            <a href="#" @click.prevent="show('requestCall')">
                                <span><i class="icon icon-mobile-request-call-small"></i></span>
                                <p class="hidden-xs-down">Заказ звонка</p>
                                <p class="hidden-sm-up">Звонок</p>
                            </a>
                        </div>
                        <div class="row">
                            <a href="#" @click.prevent="show('mobileSearch')">
                                <span><i class="icon icon-mobile-search-small"></i></span>
                                <p>Поиск</p>
                            </a>
                        </div>
                        <div class="row">
                            <a href="#" @click.prevent="show('cart')">
                                <span>
                                    <span v-text="cartCount"></span>
                                    <i class="icon icon-mobile-cart-small"></i>
                                </span>
                                <p>Корзина</p>
                            </a>
                        </div>
                    </div>
                </div>
            </transition>
    </div>
</template>

<script type="text/babel">
    export default {
        name: "MobileHotActions",
        data() {
            return {
                isShow: false,
                isActive: false
            };
        },
        computed: {
            cartCount() {
                return this.$root.$refs.cart.cartCount;
            }
        },
        methods: {
            checkShow() {
                this.isShow = (document.documentElement.scrollTop > 46);
            },
            showActions() {
                if (! this.isActive) Event.$emit('hotActionsToggle', ! this.isActive);
                this.isActive = true;
            },
            hideActions() {
                if (this.isActive) Event.$emit('hotActionsToggle', ! this.isActive);
                this.isActive = false;
            },
            toggleActions() {
                if (this.isActive) {
                    this.hideActions();
                } else {
                    this.showActions();
                }
//                this.isActive = ! this.isActive;
            },
            show(name) {
                let ref = this.$root.$refs[name]
                if (ref) ref.showModal();
            }
        },
        created() {
            this.checkShow();
            window.addEventListener('scroll', () => this.hideActions());
            window.addEventListener('scroll', () => this.checkShow());
        }
    }
</script>