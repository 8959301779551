<template>
    <div class="auth-form-container">
        <div class="profile-link hidden-sm-down" :class="{ 'is-modal-active': initActive }">
            <a href="/profile/" class="icon-wrapper" @click="showModal">
                <i :class="'icon icon-key' + skinSuffix"></i>
                <span v-if="isLogged">ЛИЧНЫЙ КАБИНЕТ</span>
                <span v-else>ВХОД / РЕГИСТРАЦИЯ</span>
            </a>
        </div>

        <modal :init-modal="initActive" class="modal-sticked" title="АВТОРИЗАЦИЯ" :sticked="true" @close="hideModal">
            <template v-if="! successAuth">
                <form id="auth-form" @submit.prevent="handleSubmitForm" @keydown="model.errors.clear($event.target.name)">
                    <div class="form-group">
                        <input type="tel" name="phone" v-mask="getConfig('phone.mask')" v-model="model.phone" class="form-control" placeholder="Номер телефона">
                        <p class="help-block-error" v-if="model.errors.has('phone')" v-text="model.errors.get('phone')"></p>
                    </div>
                    <div class="form-group">
                        <input type="password" name="password" v-model="model.password" class="form-control" placeholder="Пароль">
                        <p class="help-block-error" v-if="model.errors.has('password')" v-text="model.errors.get('password')"></p>
                    </div>
                    <button-loading :loading="loading" :disabled="loading" class="btn btn-primary btn-block btn-big">ВОЙТИ</button-loading>
                </form>
                <slot name="footer"></slot>
            </template>
            <div class="loader" v-else>
                <loader :loading="loading"></loader>
            </div>
        </modal>
    </div>
</template>

<script type="text/babel">
    import Config from '../mixins/config';
    import { ActiveForm } from '../entities/active_form';

    export default {
        name: "AuthForm",
        components: {
        },
        mixins: [ Config ],
        props: {
            isLogged: {
                type: Boolean,
                required: true
            },
            user: {
                type: Object,
                default: () => ({
                    firstname: '',
                    lastname: '',
                }),
                validator: (user) => {
                    return user.hasOwnProperty('firstname') && user.hasOwnProperty('lastname');
                }
            },
            skin: {
                default: ''
            }
        },
        data() {
            return {
                initActive: false,
                successAuth: false,
                loading: false,
                model: new ActiveForm({
                    phone: '',
                    password: '',
                })
            };
        },
        computed: {
            skinSuffix() {
                if (this.skin) {
                    return '-' + this.skin;
                }
                return '';
            }
        },
        methods: {
            showModal(event) {
                if (! this.isLogged) {
                    this.initActive = true;
                    event.preventDefault();
                }
            },
            hideModal() {
                this.initActive = false;
            },
            handleSubmitForm() {
                this.loading = true;
                this.model.post('/ajax-login/')
                    .then(r => {
                        if (r.success) {
                            this.successAuth = true;
                            window.location.href = r.redirect;
                        } else {
                            this.loading = false;
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        alert(error);
                        this.loading = false;
                    });
            }
        },
        mounted() {
            this.$store.commit('setLogged', this.isLogged);
            this.$store.commit('setUser', this.user);
        },
        created() {
            Event.$on('showAuthForm', (phone) => {
                if (phone) this.model.phone = phone;

                this.initActive = true;
            });
        }
    }
</script>