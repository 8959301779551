<template>
    <div>
        <a href="#" @click.prevent="" @mouseenter="showRemove" @mouseleave="hideRemove" v-if="! isMobile">
            <span class="remove-container" @click="remove" v-if="isActive">
                <i class="icon icon-cart-remove-hover"></i>
            </span>
            <img :src="attachment" alt="">
        </a>
        <a href="#" @click.prevent="showRemove" v-on-clickaway="hideRemove" v-else>
            <span class="remove-container" @click="remove" v-if="isActive">
                <i class="icon icon-cart-remove-hover"></i>
            </span>
            <img :src="attachment" alt="">
        </a>
    </div>
</template>

<script>
    import MobileDetect from '../mixins/mobile_detect';
    export default {
        name: "AttachmentItem",
        props: {
            attachment: String
        },
        mixins: [ MobileDetect ],
        data() {
            return {
                isActive: false
            };
        },
        methods: {
            showRemove() {
                this.isActive = true;
            },
            hideRemove() {
                this.isActive = false;
            },
            remove() {
                this.$emit('remove', this.attachment);
            }
        },
        created() {
            Event.$on('attachmentFix', () => this.isActive = false);
        }
    }
</script>