<template>
    <div class="custom-select" :class="{ 'is-active': showList, 'is-disabled': isDisabled }" v-on-clickaway="hideDropDown">
        <div class="current-value" @click="toggleDropDown" v-text="currentItem.text"></div>
        <transition name="custom-select">
            <div class="dropdown" v-show="showList">
                <div class="form-group autocomplete" v-if="autocomplete">
                    <input type="text" @input="triggerAutoComplete($event.target.value)" @keydown.enter.stop.prevent="handleChooseByEnter(list[0])" class="form-control" placeholder="Поиск" ref="autocomplete">
                </div>
                <div class="form-group live-search" v-else-if="liveSearch">
                    <input type="text" v-on:input="searchText = $event.target.value" v-bind:value="searchText" @keydown.enter.stop.prevent="handleChooseByEnter(searchList[0])" class="form-control" placeholder="Поиск" ref="liveSearch">
                </div>
                <div v-bar>
                    <div v-if="liveSearch">
                        <ul class="values" v-if="searchList.length > 0">
                            <li v-for="item,index in limitList(searchList)" v-text="item.text"
                                :class="{ 'is-active' : item.value == currentItem.value }"
                                @click="choose(item.value)"></li>
                        </ul>
                        <div class="empty-result" v-else-if="searchList.length == 0">Ничего не найдено</div>
                    </div>
                    <div v-else>
                        <ul class="values" v-if="list.length > 0">
                            <li v-for="item,index in list" v-text="item.text"
                                :class="{ 'is-active' : item.value == currentItem.value }"
                                @click="choose(item.value)"></li>
                        </ul>
                        <div class="empty-result" v-else-if="autocomplete && list.length == 0">Ничего не найдено</div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script type="text/babel">
    import _debounce from 'lodash/debounce';

    export default {
        name: "CustomSelect",
        model: {
            prop: 'selected',
            event: 'change'
        },
        props: {
            values: {
                required: true,
                type: Array,
                default() {
                    return [];
                },
            },
            value: {
                type: [String, Number],
                default: ''
            },
            placeholder: {
                type: String,
                default: 'Ничего не выбрано',
            },
            autoChoose: {
                type: Boolean,
                default: false,
            },
            selected: {
                default: -1
            },
            autocomplete: {
                type: Boolean,
                default: false
            },
            liveSearch: {
                type: Boolean,
                default: false
            },
            limit: {
                type: Number,
                default: 0
            },
            disabled: {
                type: Boolean,
                default: false,
            }
        },
        data() {
            return {
                showList: false,
                searchText: '',
                searchList: this.values,
            };
        },
        computed: {
            selectedValue() {
                // if (this.value && this.selected == -1)
                //     return this.value;

                return this.selected;
            },
            placeholderObject() {
                return {
                    text: this.placeholder,
                    value: -1,
                };
            },
            list() {
                return this.values;
            },
            currentItem() {
                // показываем вариант после выбора
                if (this.selectedValue != -1) {
                    const result = this.list.find(item => item.value == this.selectedValue);
                    if (result)
                        return result;
                }

                return this.placeholderObject;
            },
            isDisabled() {
                return this.disabled;
            }
        },
        methods: {
            hideDropDown() {
                this.showList = false;
            },
            toggleDropDown() {
                if (! this.isDisabled) {
                    this.showList = ! this.showList;
                }

                if (this.showList) {
                    if (this.autocomplete) {
                        this.$nextTick(() => this.$refs.autocomplete.focus())
                    }

                    if (this.liveSearch) {
                        this.$nextTick(() => this.$refs.liveSearch.focus())
                    }
                }
            },
            choose(value) {
                const field = this.list.find((item) => item.value == value);
                if (field) {
                    if (this.selected != value) {
                        this.$emit('change', value, this.values);
                        // fix for form errors clear method
                        this.$emit('keydown');
                    }
                }
                this.hideDropDown();
            },
            handleChooseByEnter(element) {
                if (this.list.length == 0)
                    return;

                if (element)
                    this.choose(element.value);
                else
                    this.hideDropDown();
            },
            triggerAutoComplete: _debounce(function(value) {
                this.$emit('autocomplete', value);
            }, 500),
            limitList(list) {
                if (this.limit == 0) {
                    return list;
                } else {
                    return list.slice(0, this.limit);
                }
            }
        },
        watch: {
            values(list) {
                if (this.autoChoose && this.currentItem.value == -1 && list.length > 0) {
                    this.choose(list[0].value);
                }

                if (this.liveSearch) {
                    this.searchList = this.values;
                }
            },
            searchText: _debounce(function(value) {
                // clear special chars
                value = value.replace(/[^a-zA-Zа-яА-Я0-9_-]/g, '');

                const regexp = new RegExp(value, 'i');
                this.searchList = this.list.filter(item => regexp.test(item.text));
            }, 500),
        },
        mounted() {
            if (this.value) {
                this.choose(this.value);
            } else if (this.autoChoose && this.currentItem.value == -1 && this.values.length > 0) {
                this.choose(this.values[0].value);
            }
        }
    }
</script>