<template>
    <div class="custom-checkbox" @click="toggleChecked">
        <div class="checkbox" :class="{ 'is-checked': isChecked }">
            <transition name="fade">
                <i class="icon" :class="'icon-' + icon" v-if="this.isChecked"></i>
            </transition>
        </div>
        <span>
            <slot></slot>
        </span>
    </div>
</template>

<script type="text/babel">
    export default {
        name: "CustomCheckbox",
        props: {
            value: {
                type: Boolean,
                default: false,
            },
            icon: {
                type: String,
                default: 'custom-checkbox-checked'
            }
        },
        data() {
            return {

            };
        },
        computed: {
            isChecked() {
                return this.value;
            },
            iconClass() {
                return this.isChecked ? ('icon-' + this.icon) : '';
            }
        },
        methods: {
            toggleChecked() {
                this.$emit('input', ! this.isChecked);
                this.$emit('change');
            }
        }
    }
</script>