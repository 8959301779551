<template>
    <div>
        <div class="hidden-lg-down">
            <div class="request-call icon-wrapper">
                <i class="icon icon-request-call"></i>
                <a href="#" @click.prevent="showModal()">Заказ звонка</a>
            </div>
        </div>

        <div class="request-call-mobile hidden-xl-up">
            <a href="#" @click.prevent="showModal()">
                <i class="icon icon-request-call-mobile"></i>
            </a>
        </div>

        <modal :init-modal="initActive" class="modal-request-call" title="ЗАКАЗ ЗВОНКА" @close="hideModal">
            <div class="phones hidden-lg-up">
                <a href="tel:0503379894" class="icon-wrapper"><i class="icon icon-vodafone"></i>(050) 337-98-94</a>
                <a href="tel:0678293955" class="icon-wrapper"><i class="icon icon-kyivstar"></i>(067) 829-39-55</a>
                <a href="tel:0633057792" class="icon-wrapper"><i class="icon icon-lifecell"></i>(063) 305-77-92</a>
                <a href="tel:0443383758" class="icon-wrapper"><i class="icon icon-cityphone"></i>(044) 338-37-58</a>
            </div>
            <form action="" @submit.prevent="handleSubmitForm" @keydown="model.errors.clear($event.target.name)" v-if="showForm">
                <div class="form-group">
                    <input type="text" name="name" v-model="model.name" class="form-control" placeholder="Ваше имя">
                    <p class="help-block-error" v-if="model.errors.has('name')">{{ model.errors.get('name') }}</p>
                </div>
                <div class="form-group">
                    <input type="tel" name="phone" v-mask="getConfig('phone.mask')" v-model="model.phone" class="form-control" placeholder="Ваш номер">
                    <p class="help-block-error" v-if="model.errors.has('phone')">{{ model.errors.get('phone') }}</p>
                </div>
                <button-loading :loading="loading" :disabled="loading" class="btn btn-primary btn-block">ОФОРМИТЬ</button-loading>
            </form>
            <p class="result" v-else>Спасибо! Вас запрос будет обработан менеджером.</p>
        </modal>
    </div>
</template>

<script type="text/babel">
    import Config from '../mixins/config';
    import { ActiveForm } from '../entities/active_form';

    export default {
        name: 'RequestCall',
        components: {
        },
        mixins: [ Config ],
        data() {
            return {
                loading: false,
                initActive: false,
                showForm: true,
                model: new ActiveForm({
                    name: '',
                    phone: '',
                    errors: []
                })
            };
        },
        computed: {},
        methods: {
            showModal() {
                this.initActive = true;
            },
            hideModal() {
                this.initActive = false;
            },
            handleSubmitForm() {
                this.loading = true;
                this.model.post('/request-call/')
                    .then(r => {
                        if (r.success) {
                            this.showForm = false;

                        }
                        this.loading = false;
                    })
                    .catch((error) => {
                        this.loading = false;
                        return new Error(error);
                    });
            }
        },
    }
</script>