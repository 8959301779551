<template>
    <transition
        name="fade-custom"
        enter-active-class="animated bounceInLeft"
        leave-active-class="animated bounceOutLeft"
    >
        <a href="#" class="mobile-action move-top" @click.prevent="moveTop" v-show="isActive"><i class="icon icon-move-top"></i></a>
    </transition>
</template>

<script type="text/babel">
    export default {
        name: "MobileMoveTop",
        data() {
            return {
                isActive: false
            };
        },
        methods: {
            checkActive() {
                this.isActive = (document.documentElement.scrollTop > 46);
            },
            moveTop() {
                scrollTo(0, 700);
            }
        },
        created() {
            this.checkActive();
            window.addEventListener('scroll', () => this.checkActive());
        }
    }
</script>