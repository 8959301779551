<template>
    <div>
        <form-wizard
                @on-complete="complete"
                @on-loading="setLoading"
                @on-change="handleChangeStep"
                :hide-buttons="true"
                :color="''"
                :error-color="''"
                ref="wizard">
            <template slot="title">
                <h4 class="text-center">РЕГИСТРАЦИЯ НОВОГО ПОЛЬЗОВАТЕЛЯ</h4>

                <div class="man-in-the-black" v-man="[wizardStepIndex, totalSteps]">
                    <div class="grid">
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                    </div>
                    <div class="man">
                        <i class="icon icon-signup-man"></i>
                    </div>
                </div>
            </template>

            <template slot="step" scope="props">
                <wizard-step :tab="props.tab"
                             :transition="props.transition"
                             :key="props.tab.title"
                             :index="props.index">
                </wizard-step>
            </template>

            <tab-content :title="isPhone ? 'Шаг 1' : 'Ввод телефона'" :before-change="validateStep">
                <form action="" @submit.prevent="nextTab" @keydown="model.errors.clear($event.target.name)">
                    <div class="text-center"><i class="icon icon-signup-phone"></i></div>
                    <div class="form-group">
                        <label for="signup-phone">Введите Ваш номер телефона</label>
                        <input type="tel" name="phone" v-mask="getConfig('phone.mask')" id="signup-phone" v-model="model.phone" class="form-control" placeholder="Ваш номер">
                        <p class="help-block-error" v-if="model.errors.has('phone')">{{ model.errors.get('phone') }}</p>
                    </div>
                    <div class="form-group">
                        <button-loading :loading="loadingWizard" :disabled="loadingWizard" class="btn btn-primary btn-big btn-block" @click="nextTab">ПОЛУЧИТЬ СМС</button-loading>
                    </div>
                    <div class="form-group">
                        <custom-checkbox :icon="'signup-checkbox'" v-model="model.confirm_rules" @input="model.errors.clear('confirm_rules')">
                            <div class="icon-wrapper">
                                <span>Я даю согласие на обработку личных данных</span>
                                <a href="#" class="icon">
                                    <i class="icon icon-signup-offer"></i>
                                </a>
                            </div>
                        </custom-checkbox>
                        <p class="help-block-error" v-if="model.errors.has('confirm_rules')">{{ model.errors.get('confirm_rules') }}</p>
                    </div>
                    <div class="links grid">
                        <div class="row"><a href="#" class="link" @click.prevent="showAuthForm">Вход</a></div>
                        <div class="row"><a href="/restore-password/" class="link">Восстановить пароль</a></div>
                    </div>
                </form>
            </tab-content>

            <tab-content :title="isPhone ? 'Шаг 2' : 'Подтверждение'" :before-change="validateStep">
                <form action="" @submit.prevent="nextTab" @keydown="model.errors.clear($event.target.name)">
                    <div class="text-center"><i class="icon icon-signup-code"></i></div>
                    <p class="support-text">Вы ввели номер: <span v-text="model.phone"></span> <a href="#" class="link" @click.prevent="prevTab">Изменить</a></p>
                    <div class="form-group">
                        <label for="signup-code">Введите код с СМС</label>
                        <input type="text" name="code" id="signup-code" v-model="model.code" class="form-control" placeholder="Код" maxlength="4" autocomplete="off">
                        <p class="help-block-error" v-if="model.errors.has('code')">{{ model.errors.get('code') }}</p>
                    </div>
                    <div class="form-group">
                        <button-loading :loading="loadingWizard" :disabled="loadingWizard" class="btn btn-primary btn-big btn-block" @click="nextTab">ПОДТВЕРДИТЬ</button-loading>
                    </div>
                    <div class="links send-code grid">
                        <div class="row">Не пришла СМС?</div>
                        <div class="row" v-if="canSendCodeError">
                            <div class="grid">
                                <div class="row"><a href="#" class="link" @click.prevent="sendCode" v-if="! isTimer">Отправить повторно</a></div>
                                <div class="row" v-if="isTimer">Повторить попытку через <span v-text="sendCodeTimer"></span> секунд</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <a href="#" class="link support-link" @click.prevent="showModal" v-if="! canSendCodeError">Связаться с тех.поддержкой</a>
                    </div>
                </form>
            </tab-content>

            <tab-content :title="isPhone ? 'Шаг 3' : 'Установка пароля'" :before-change="validateStep">
                <form action="" @submit.prevent="nextTab" @keydown="model.errors.clear($event.target.name)">
                    <div class="text-center"><i class="icon icon-signup-password"></i></div>
                    <div class="form-group">
                        <label for="signup-password">Установите пароль</label>
                        <input type="password" name="password" id="signup-password" v-model="model.password" class="form-control" placeholder="Пароль">
                        <p class="help-block-error" v-if="model.errors.has('password')">{{ model.errors.get('password') }}</p>
                    </div>
                    <div class="form-group">
                        <input type="password" name="password_repeat" v-model="model.password_repeat" class="form-control" placeholder="Подтвердите пароль">
                        <p class="help-block-error" v-if="model.errors.has('password_repeat')">{{ model.errors.get('password_repeat') }}</p>
                    </div>
                    <div class="form-group">
                        <button-loading :loading="loadingWizard" :disabled="loadingWizard" class="btn btn-primary btn-big btn-block" @click="nextTab">УСТАНОВИТЬ</button-loading>
                    </div>
                </form>
            </tab-content>
        </form-wizard>

        <modal :init-modal="isModalActive" class="modal-support-call" title="СВЯЗЬ С ТЕХПОДДЕРЖКОЙ" @close="hideModal">
            <form action="" @submit.prevent="handleSubmitForm" v-if="showForm" @keydown="form.errors.clear($event.target.name)">
                <div class="form-group">
                    <input type="text" name="name" v-model="form.name" class="form-control" placeholder="Ваше имя">
                    <p class="help-block-error" v-if="form.errors.has('name')">{{ form.errors.get('name') }}</p>
                </div>
                <div class="form-group">
                    <input type="text" name="email" v-model="form.email" class="form-control" placeholder="Ваш email">
                    <p class="help-block-error" v-if="form.errors.has('email')">{{ form.errors.get('email') }}</p>
                </div>
                <div class="form-group">
                    <textarea class="form-control" name="text" v-model="form.text" placeholder="Ваше сообщение"></textarea>
                    <p class="help-block-error" v-if="form.errors.has('text')">{{ form.errors.get('text') }}</p>
                </div>
                <button class="btn btn-primary btn-block" :disabled="form.errors.any()">ОТПРАВИТЬ</button>
            </form>
            <p class="result text-center" v-else>Спасибо! Вас запрос будет обработан технической поддержкой.</p>
        </modal>
    </div>
</template>

<script type="text/babel">
    import { FormWizard, WizardStep, TabContent } from 'vue-form-wizard';
    import { ActiveForm } from '../entities/active_form';
    import Config from '../mixins/config';
    import CustomCheckbox from '../components/custom_checkbox.vue';
    import MobileDetect from '../mixins/mobile_detect';

    export default {
        name: "SignupForm",
        components: {
            FormWizard,
            WizardStep,
            TabContent,
            CustomCheckbox,
        },
        mixins: [ Config, MobileDetect ],
        props: {

        },
        data() {
            return {
                wizardStepIndex: 0,
                totalSteps: 0,
                model: new ActiveForm({
                    phone: '',
                    code: '',
                    password: '',
                    password_repeat: '',
                    confirm_rules: false,
                    step: 1,
                }),
                loadingWizard: false,
                sendCodeTimer: 0,
                canSendCodeError: true,

                isModalActive: false,
                form: new ActiveForm({
                    name: '',
                    phone: '',
                    email: '',
                    text: '',
                }),
                showForm: true,
            };
        },
        computed: {
            isTimer() {
                return this.sendCodeTimer > 0;
            },
        },
        methods: {
            prevTab() {
                this.$refs.wizard.prevTab();
            },
            nextTab() {
                this.$refs.wizard.nextTab();
            },
            setLoading(value) {
                this.loadingWizard = value;
            },
            validateStep() {
                return new Promise((resolve, reject) => {
                    this.model.step = this.$refs.wizard.activeTabIndex + 1;
                    this.model.post('/signup/validate/').then(r => {
                        if (r.success) {
                            resolve(true);
                        } else {
                            reject('Исправьте ошибки')
                        }
                    })
                });
            },
            sendCode() {
                if (this.sendCodeTimer == 0) {
                    let query = new ActiveForm({
                        phone: this.model.phone
                    });

                    query.post('/signup/send-code/')
                        .then(r => {
                            if (r.success) {
                                this.sendCodeTimer = 30;
                                let timer = setInterval(() => {
                                    this.sendCodeTimer--;
                                }, 1000);

                                setTimeout(() => {
                                    clearInterval(timer);
                                }, 30010);
                            }
                            this.canSendCodeError = r.success;
                        })
                }
            },
            complete() {
                this.loadingWizard = true;
                this.model.post('/signup/confirm/')
                    .then(r => {
                        if (r.success) {
                            if (r.redirect) {
                                window.location.href = r.redirect;
                            }
                        } else {
                            this.loadingWizard = false;
                            return new Error('Произошла проблема при подтверждении пользовательской регистрации');
                        }
                    });
            },
            showAuthForm() {
                Event.$emit('showAuthForm');
            },
            handleChangeStep(prevIndex, nextIndex) {
                this.wizardStepIndex = nextIndex;
                if (prevIndex == 0 && nextIndex == 1) {
                    this.sendCode();
                }
            },
            handleSubmitForm() {
                this.form.phone = this.model.phone;
                this.form.post('/form/signup-support/')
                    .then(r => {
                        if (r.success) {
                            this.showForm = false;
                        }
                    })
            },
            showModal() {
                this.isModalActive = true;
            },
            hideModal() {
                this.isModalActive = false;
            }
        },
        mounted() {
            this.totalSteps = this.$refs.wizard.tabCount;
        },
        directives: {
            man: {
                update(el, binding) {
                    let [stepIndex, totalSteps] = binding.value;
                    let containerWidth = el.offsetWidth;
                    let man = el.getElementsByClassName('man')[0];

                    let margin = (containerWidth / (totalSteps - 1)) * stepIndex;
                    if (margin > 0 && margin < containerWidth) {
                        margin -= man.offsetWidth / 2;
                    } else if (margin == containerWidth) {
                        margin -= man.offsetWidth;
                    }
                    man.style.transform = 'translateX(' + margin + 'px)';
                }
            }
        }
    }
</script>