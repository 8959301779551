<template>
    <div class="loader text-center">
        <clip-loader :loading="isLoading" :color="'#4998cc'" :size="'10px'"></clip-loader>
    </div>
</template>

<script type="text/babel">
    export default {
        name: "Loader",
        props: {
            loading: {
                type: Boolean,
                default: false,
            },
        },
        data: () => ({
            
        }),
        computed: {
            isLoading() {
                return this.loading;
            }
        },
        methods: {
            
        }
    }
</script>  