<template>
    <div class="progress-bar">
        <div class="indicator" :style="'width: ' + percent + '%;'"></div>
    </div>
</template>

<script type="text/babel">
    export default {
        name: "Progressbar",
        props: {
            initPercent: {
                required: true,
                type: Number
            }
        },
        computed: {
            percent() {
                return this.initPercent;
            }
        }
    }
</script>