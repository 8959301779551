<template>
    <ul class="stars-rating" :class="{ 'read-only': readOnly }">
        <li v-for="rate in max" :class="{ 'is-active': currentRating >= rate }" @click="updateRating(rate)" @mouseenter="enableRating(rate)" @mouseleave="disableRating"></li>
    </ul>
</template>

<script type="text/babel">
    export default {
        name: "StarsRating",
        model: {
            prop: 'rating',
            event: 'rate'
        },
        props: {
            rating: {
                default: 0,
                type: Number
            },
            max: {
                default: 5,
                type: Number
            },
            readOnly: {
                default: false,
                type: Boolean
            },
        },
        data() {
            return {
                isHover: false,
                fakeRating: 0,
            };
        },
        computed: {
            currentRating() {
                return this.isHover ? this.fakeRating : Math.floor(this.rating);
            }
        },
        methods: {
            updateRating(rate) {
                if (! this.readOnly) {
                    this.$emit('rate', rate);
                }
            },
            enableRating(rate) {
                if (! this.readOnly) {
                    this.fakeRating = rate;
                    this.isHover = true;
                }
            },
            disableRating() {
                if (! this.readOnly) {
                    this.fakeRating = 0;
                    this.isHover = false;
                }
            }
        }
    }
</script>