<template>
    <div class="sort-panel">
        <a href="#" class="filter-btn icon-wrapper hidden-xl-up" @click.prevent="showFilter">
            <i class="icon icon-filter-button"></i>
            <span>ФИЛЬТР</span>
        </a>
        <a href="#" class="sort-btn hidden-md-up" @click.prevent="showSort">
            СОРТИРОВКА
        </a>
        <div class="hidden-sm-down">
            <div class="panel-text">Сортировать по:</div>

            <div class="sort-item" :class="{ 'is-active': sort.active }" v-for="sort in sortItems">
                <a :href="sort.url" class="sort-item-name" @click.prevent="handleClick(sort)">{{ sort.label }}</a>
                <div class="grid" v-if="sort.active">
                    <div class="sort-type" :class="{ 'is-active': sort.current == type}" v-for="type in types">
                        <a :href="getUrl(sort, type)" :class="type" @click.prevent="handleClick(sort, type)"></a>
                    </div>
                </div>
            </div>
        </div>
        <swipe :is-active="isSortSwipeActive" main-class="mobile-sort" @close="hideSort">
            <header class="grid">
                <div class="row logo"><img src="/build/images/logo-simple.png" alt=""></div>
                <div class="row swipe-close" @click="hideSort"><i class="icon icon-swipe-close"></i></div>
            </header>
            <div>
                <ul>
                    <li :class="{ 'is-active': sort.active }" v-for="sort in sortItems">
                        <a :href="sort.url" @click.prevent="handleClick(sort)">{{ sort.label }}</a>
                    </li>
                </ul>

                <ul>
                    <li class="sort-type" :class="{ 'is-active': activeSort.current == type}" v-for="type in types">
                        <a :href="getUrl(activeSort, type)" @click.prevent="handleClick(activeSort, type)">{{ typesLabels[type] }}</a>
                    </li>
                </ul>
            </div>
        </swipe>
    </div>
</template>

<script type="text/babel">
    import Swipe from "../components/swipe.vue";
    export default {
        components: {
            Swipe,
        },
        name: "SortPanel",
        props: ['data'],
        data() {
            return {
                types: ['asc', 'desc'],
                typesLabels: {
                    asc: 'По возрастанию',
                    desc: 'По убыванию'
                },
                isSortSwipeActive: false,
            };
        },
        computed: {
            sortItems() {
                return this.data;
            },
            activeSort() {
                return this.sortItems.filter(item => item.active)[0];
            }
        },
        methods: {
            handleClick(sort, type = null) {
                // hide swipe if it's active
                if (this.isSortSwipeActive)
                    this.isSortSwipeActive = false;

                let url;
                if (type) {
                    url = this.getUrl(sort, type);
                } else {
                    url = sort.url;
                }

                this.sortItems.map(item => item.active = false);
                sort.active = true;
                sort.current = sort.current == 'desc' ? 'asc' : 'desc';

                history.pushState({isAjax: false}, document.title, url);
                Event.$emit('pushState', {url: url});
            },
            isSortOptionActive(option, type) {
                return option.current == type;
            },
            getUrl(option, type) {
                if (! this.isSortOptionActive(option, type)) {
                    return option.url;
                }
                return;
            },
            showFilter() {
                Event.$emit('showFilter');
            },
            showSort() {
                this.isSortSwipeActive = true;
            },
            hideSort() {
                this.isSortSwipeActive = false;
            }
        },
    }
</script>