<template>
    <div class="payments-container">
        <div class="grid">
            <div class="row">Вы можете оплатить Ваш заказ</div>
            <div class="row" v-if="isMultiPayment" v-on-clickaway="hidePopup">
                <div class="popup-container">
                    <button class="btn btn-primary btn-small" @click="showPopup">Online</button>
                    <popup :is-active="isActivePopup" :auto-center="true">
                        <div class="payments">
                            <div class="payment" v-for="form,key in paysystems">
                                <a href="#" @click.prevent="sendForm(key)"><i class="icon" :class="'icon-' + key"></i></a>
                                <div class="form" v-html="form" :ref="'form' + key"></div>
                            </div>
                        </div>
                    </popup>
                </div>
            </div>
            <div class="row" v-else>
                <div v-html="this.paysystems" ref="singleForm" v-show="false"></div>
                <button class="btn btn-primary btn-small" @click="sendSingleForm">Online</button>
            </div>
        </div>
    </div>
</template>

<script type="text/babel">
    import Popup from '../components/popup.vue';
    import isObject from 'lodash/isObject';

    export default {
        components: {Popup},
        name: "OrderPay",
        props: {
            paysystems: {
                required: true,
            }
        },
        data: () => ({
            isActivePopup: false,
        }),
        computed: {
            isMultiPayment() {
                return isObject(this.paysystems);
            },
        },
        methods: {
            showPopup() {
                this.isActivePopup = true;
            },
            hidePopup() {
                this.isActivePopup = false;
            },
            sendForm(key) {
                const formName = 'form' + key;
                const form = this.$refs[formName][0].getElementsByTagName('form')[0];
                form.submit();
            },
            sendSingleForm() {
                const form = this.$refs.singleForm.getElementsByTagName('form')[0];
                form.submit();
            }
        }
    }
</script>  