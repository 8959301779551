import Tooltip from 'tooltip.js';

export default {
    name: 'tooltip',
    config: {},
    install (Vue) {
        Vue.directive('tooltip', {
            bind (el, binding, vnode) {
                let placement = 'bottom';

                if (binding.modifiers.left) {
                    placement = 'left';
                } else if (binding.modifiers.right) {
                    placement = 'right';
                } else if (binding.modifiers.top) {
                    placement = 'top';
                } else if (binding.modifiers.bottom) {
                    placement = 'bottom';
                }

                new Tooltip(el, {
                    placement: placement,
                    title: binding.value
                });
            }
        })
    }
}