<template>
    <div class="questions">
        <p class="questions__sorry-message" v-if="!hasQuestions && !loading">Еще никто не задавал вопросов по этому товару</p>
        <div class="questions__button-ask">
            <button type="button" class="btn btn-light-grey questions__button" @click="showModal">ЗАДАТЬ ВОПРОС</button>
        </div>
        <div class="questions__list">
            <question-item :question="question" :key="i" v-for="question,i in questions" @showForm="showModal"></question-item>
            <pagination :pages="pages" :page="page" ref="pagination" @updatePageIndex="updatePageIndex" v-scroll-to="'.questions__list'"></pagination>
        </div>

        <modal class="modal-add-review" :init-modal="initModal" title="ОСТАВЬТЕ СВОЙ ВОПРОС" @close="hideModal">
            <template v-if="! formSend">
                <form action="" @submit.prevent="handleSubmitForm" @keydown="form.errors.clear($event.target.name)">
                    <div class="grid">
                        <div class="row form-group">
                            <label for="question-name">Введите имя</label>
                            <input name="name" id="question-name" v-model="form.name" class="form-control" placeholder="Введите имя">
                            <p class="help-block-error" v-if="form.errors.has('name')" v-text="form.errors.get('name')"></p>
                        </div>
                        <div class="row form-group">
                            <label for="question-cityId">Ваш город</label>
                            <custom-select id="question-cityId" :auto-choose="true" class="form-control" :values="cityList" v-model="form.cityId"
                                           :autocomplete="true" @autocomplete="setCityName"
                                           @keydown="form.errors.clear('cityId')"></custom-select>
                            <p class="help-block-error" v-if="form.errors.has('cityId')"
                               v-text="form.errors.get('cityId')"></p>
                        </div>
                    </div>
                    <div class="grid">
                        <div class="row form-group">
                            <label for="question-email">Введите email</label>
                            <input name="email" id="question-email" v-model="form.email" class="form-control" placeholder="Введите email">
                            <p class="help-block-error" v-if="form.errors.has('email')" v-text="form.errors.get('email')"></p>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="question-text">Ваш вопрос</label>
                        <textarea name="text" id="question-text" v-model="form.text" class="form-control" placeholder="Опишите" v-expandable></textarea>
                        <p class="help-block-error" v-if="form.errors.has('text')"
                           v-text="form.errors.get('text')"></p>
                    </div>
                    <div class="form-group files">
                        <transition name="fade">
                            <progressbar :init-percent="uploadProgress" v-if="uploading"></progressbar>
                            <template v-else>
                                <a href="#" @click.prevent="openFiles" class="link icon-wrapper" v-if="canUpload">
                                    <span>Добавить изображение</span>
                                    <i class="icon icon-plus-green"></i>
                                </a>
                            </template>
                        </transition>
                        <div class="images" v-if="form.attachments.length">
                            <attachment-item :attachment="attachment" v-for="(attachment,i) in form.attachments" :key="i" @remove="removeAttachment"></attachment-item>
                        </div>
                        <p class="help-block-error" v-if="form.errors.has('attachments')" v-text="form.errors.get('attachments')"></p>
                        <input type="file" name="review-files[]" ref="files" @change="handleUploadFiles" accept="image/*" multiple>
                        <p class="help-block-error" v-if="uploadForm.errors.has('attachments')" v-text="uploadForm.errors.get('attachments')"></p>
                    </div>
                    <div>
                        <button-loading :loading="formSubmitLoading" class="btn btn-block btn-secondary" :disabled="form.errors.any()">
                            ОСТАВИТЬ ВОПРОС
                        </button-loading>
                    </div>
                </form>
            </template>
            <p class="text-center" v-else>Благодарим за вопрос. Он будет опубликован после ответа менеджера.</p>
        </modal>
    </div>
</template>

<script>
    import City from '../mixins/city';
    import { mapGetters } from 'vuex';
    import { ActiveForm } from '../entities/active_form';
    import QuestionItem from './question_item.vue';
    import Progressbar from '../components/progressbar.vue';
    import AttachmentItem from './attachment_item.vue';
    import Pagination from '../components/pagination.vue';

    export default {
        name: "Questions",
        components: {
            QuestionItem,
            Progressbar,
            AttachmentItem,
            Pagination
        },
        mixins: [ City ],
        props: {
            productId: {
                required: true
            }
        },
        data() {
            return {
                init: false,
                loading: false,
                initModal: false,
                questions: [],
                pages: [],
                page: 0,
                form: new ActiveForm({
                    parentId: '',
                    productId: this.productId,
                    name: '',
                    email: '',
                    cityId: 0,
                    text: '',
                    attachments: []
                }),
                formSend: false,
                formSubmitLoading: false,
                uploading: false,
                uploadProgress: 0,
                uploadForm: new ActiveForm({
                    attachments: []
                }),
            };
        },
        computed: {
            hasQuestions() {
                return this.questions.length > 0;
            },
            canUpload() {
                return this.form.attachments.length < 3;
            },
            ...mapGetters(['user'])
        },
        methods: {
            getQuestions(url) {
                const correctUrl = url ? url : '/questions/list/';
                this.loading = true;
                let query = new ActiveForm({
                    product_id: this.productId
                });

                query.get(correctUrl)
                    .then(r => {
                        this.questions = r.questions;
                        this.pages = r.pages;
                        this.loading = false;
                        if (!this.init) {
                            this.init = true;
                            this.$emit('init');
                        }
                    })
                    .catch(e => {
                        console.log(e);
                        this.loading = false;
                    });
            },
            showModal(questionId = '') {
                this.initModal = true;
                this.form.parentId = questionId;
            },
            hideModal() {
                this.initModal = false;
            },
            openFiles() {
                this.$refs.files.click();
            },
            handleUploadFiles(e) {
                let files = e.target.files || e.dataTransfer.files;

                if (files.length) {
                    this.uploadForm.errors.clear();
                    this.uploading = true;
                    this.uploadProgress = 0;

                    const config = {
                        onUploadProgress: (progressEvent) => {
                            var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
                            this.uploadProgress = percentCompleted;
                        }
                    }

                    this.uploadForm.attachments = files;
                    this.uploadForm.submit('post', '/questions/upload/', config)
                        .then(r => {
                            if (r.success) {
                                this.form.attachments = this.form.attachments.concat(r.attachments);
                            }
                            this.uploading = false;
                        })
                        .catch(err => {
                            console.log(err)
                            this.uploading = false;
                        })
                }
            },
            removeAttachment(value) {
                const index = this.form.attachments.indexOf(value);
                if (index > -1) {
                    this.form.attachments.splice(index, 1);
                    setTimeout(() => Event.$emit('attachmentFix'), 10);
                }
            },
            handleSubmitForm() {
                if (! this.formSubmitLoading) {
                    this.formSubmitLoading = true;
                    this.form.post('/questions/add/')
                        .then(r => {
                            this.formSubmitLoading = false;
                            if (r.success) {
                                this.formSend = true;
                                this.form.restore();
                                this.form.name = this.user.firstname;
                                setTimeout(() => {
                                    this.formSend = false;
                                    this.initModal = false;
                                }, 5000);
                            }
                        })
                        .catch(e => {
                            console.log(e);
                            this.formSubmitLoading = false;
                        });
                }
            },
            updatePageIndex(index) {
                this.page = index;
                const url = this.pages[this.page] ? this.pages[this.page].url : '';
                this.getQuestions(url);
            },
        },
        mounted() {
            this.form.name = this.user.firstname;
            // this.form.city_id = this.cityValue.value;
        },
        created() {
            this.getQuestions();
        }
    }
</script>