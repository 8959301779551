<template>
    <div class="cart-informer-block">
        <div class="grid">
            <div class="image"><a :href="item.url"><img :src="item.image.thumb" alt=""></a></div>
            <div class="name-container">
                <p class="name"><a :href="item.url">{{ item.name | decode }}</a></p>
                <p class="name-alias">{{ item.nameAlias | decode }}</p>
                <ul class="properties" v-if="item.props.length > 0">
                    <li v-for="prop in item.props">
                        <span class="name">{{ prop.name }}:</span> <span class="value">{{ prop.value | decode }}</span>
                    </li>
                </ul>
                <div class="need-wait-block" v-if="item.needWait">доставка в течении 2-3 дней</div>
            </div>
            <div class="quantity-container">
                <div>
                    <a href="#" @click.prevent="decrease" :class="{'is-disabled': ! canDecrease}">-</a>
                    <span class="quantity">{{ model.quantity }}</span>
                    <a href="#" @click.prevent="increase" :class="{'is-disabled': ! canIncrease}">+</a>
                </div>
                <p class="help-block-error" v-if="model.errors.has('quantity')" v-text="model.errors.get('quantity')"></p>
            </div>
            <div class="price-container" :class="{ 'has-discount': hasDiscount }">
                <template v-if="hasDiscount">
                    <div class="price">{{ totalDiscountPrice | currency }}</div>
                    <div class="old-price">{{ totalPrice | currency }}</div>
                </template>
                <template v-else>
                    <div class="price">{{ totalPrice | currency }}</div>
                </template>
            </div>
        </div>
        <a href="#" class="btn-remove" @click.prevent="removeConfirm" @mouseenter="removeHoverState = true" @mouseleave="removeHoverState = false" v-on-clickaway="away">
            <i :class="'icon icon-cart-remove' + (removeHoverState ? '-hover' : '')"></i>
            <popup :is-active="isRemovePopupActive" class="popup-cart-confirm">
                <div class="grid no-wrap">
                    <a href="#" class="action" @click.prevent="addToWishList" v-if="isLogged">
                        <span>
                            <span class="icon-cart-container"><i class="icon icon-cart-confirm-wishlist"></i></span>
                            <span class="text">Переместить в список желаний</span>
                        </span>
                    </a>
                    <a href="#" class="action" @click.prevent="remove">
                        <span>
                            <span class="icon-cart-container"><i class="icon icon-cart-confirm-remove"></i></span>
                            <span class="text">Удалить</span>
                        </span>
                    </a>
                </div>
            </popup>
        </a>
    </div>
</template>

<script type="text/babel">
    import Popup from '../components/popup.vue';
    import { mapGetters } from 'vuex';
    import { ActiveForm } from '../entities/active_form';

    export default {
        components: {Popup},
        name: 'CartInformerBlock',
        props: {
            item: { required: true },
            loading: {
                required: true,
                type: Boolean
            }
        },
        data() {
            return {
                showMe: true,
                removeHoverState: false,
                isRemovePopupActive: false,
                model: new ActiveForm({
                    offer_id: this.item.offer_id,
                    quantity: this.item.quantity,
                }),
                canIncrease: true,
            }
        },
        computed: {
            canDecrease() {
                return this.item.quantity > 1;
            },
            hasDiscount() {
                return this.item.hasDiscount;
            },
            totalPrice() {
                return this.item.price * this.item.quantity;
            },
            totalDiscountPrice() {
                return this.item.discountPrice * this.item.quantity;
            },
            ...mapGetters(['isLogged'])
        },
        methods: {
            setLoading(value) {
                this.$emit('update:loading', value);
            },
            increase() {
                if (this.loading || ! this.canIncrease) return;

                this.model.quantity++;
                this.setLoading(true);
                this.model.post('/cart/increase/')
                    .then(r => {
                        if (r.success) {
                            this.item.quantity++;
                            this.$emit('increase', this.item.offer_id);
                        } else {
                            this.canIncrease = false;
                            this.model.quantity--;
                        }
                        this.setLoading(false);
                    })
                    .catch(e => {
                        this.$emit('pushError', e);
                        this.setLoading(false);
                    });
            },
            decrease() {
                if (this.loading || ! this.canDecrease) return;

                this.setLoading(true);
                this.model.post('/cart/decrease/')
                    .then(r => {
                        if (r.success) {
                            this.item.quantity--;
                            this.canIncrease = true;
                            this.$emit('decrease', this.item.offer_id);
                        }
                        this.setLoading(false);
                    })
                    .catch(e => {
                        this.$emit('pushError', e);
                        this.setLoading(false);
                    });
            },
            removeConfirm() {
                if (this.loading) return;
                
                this.isRemovePopupActive = true;
            },
            addToWishList() {
                this.isRemovePopupActive = false;

                let query = new ActiveForm({
                    offer_id: this.item.offer_id
                });
                query.post('/profile/wishlist/add/')
                    .then(r => {
                        this.remove();
                    })
                    .catch(e => {
                        this.$emit('pushError', e);
                        this.setLoading(false);
                    });
            },
            remove() {
                if (this.loading) return;

                this.setLoading(true);
                this.model.post('/cart/remove/')
                    .then(r => {
                        if (r.success) {
                            this.$emit('remove', this.item.offer_id);
                        } else {
                            this.$emit('pushError', 'Невозможно удалить этот товар из корзины');
                        }
                        this.setLoading(false);
                    })
                    .catch(e => {
                        this.$emit('pushError', e);
                        this.setLoading(false);
                    });
            },
            away() {
                this.isRemovePopupActive = false;
            },
        },
        watch: {
            item: {
                handler(obj){
                    this.model.quantity = obj.quantity;
                    this.model.errors.clear();
                },
                deep: true
            }
        }
    }
</script>