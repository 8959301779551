<template>
    <div class="reviews-last">
        <div class="reviews-last__title" v-if="reviews.length > 0">ПОСЛЕДНИЕ ОТЗЫВЫ</div>
        <div class="reviews-last__total-rating" v-if="ratingAverage > 0">
            <stars-rating :rating="ratingAverage" :read-only="true"></stars-rating>
            <div>Рейтинг: {{ ratingAverage }} / 5 ({{ ratingCount }} отзывов)</div>
        </div>
        <carousel
                :per-page="2"
                :per-page-custom="[[768, 4], [1200, 6]]"
                :pagination-padding="4"
                :pagination-size="10"
                :pagination-active-color="'#4998cc'"
                :scroll-per-page="true"
                :infinite-loop="true">
            <slide :key="index" v-for="review,index in reviews">
                <reviews-last-item :key="index" :review="review"></reviews-last-item>
            </slide>
        </carousel>
    </div>
</template>

<script>
    import ReviewsLastItem from './reviews_last_item.vue';
    import StarsRating from './stars_rating.vue';

    export default {
        components: {
            StarsRating,
            ReviewsLastItem
        },
        name: "ReviewsLast",
        props: {
            link: {
                type: String,
                required: true
            },
            ratingAverage: {
                type: Number,
                default: 0
            },
            ratingCount: {
                type: Number,
                default: 0
            }
        },
        data: () => ({
           reviews: [],
        }),
        methods: {
            getReviews() {
                this.axios.get(this.link)
                    .then(r => this.reviews = r.data);
            }
        },
        created() {
            this.getReviews();
        }
    }
</script>