<template>
    <div class="filter-group" :class="{ 'is-expand': isExpand }">
        <div class="filter-title" @click="toggleExpand" v-text="filterName"></div>
        <div class="filter-options" v-show="canShow" v-bar v-scroll-class>
            <div>
                <ul>
                    <li v-for="option in this.optionsList">
                        <a :href="option.url" :class="{ 'is-active': option.active, 'is-disabled': option.disabled }" @click.prevent="toggleActive(option.id)" rel="nofollow">
                            <span class="checkbox"></span>
                            <span class="text">{{ option.name | decode }}</span>
                            <span class="gift" v-if="option.gift"><i class="icon icon-filter-gift"></i></span>
                        </a>
                    </li>
                </ul>
                <div class="text-center"><a href="#" class="show-more-link" @click.prevent="showFull" v-if="needFullList">Показать еще</a></div>
            </div>
        </div>
    </div>
</template>

<script type="text/babel">
    export default {
        name: "FilterGroup",
        props: ['filterItem', 'initDisabled'],
        data() {
            return {
                isFull: false,
                isExpand: false,
            };
        },
        computed: {
            filterName() {
                return this.filterItem.name.toUpperCase();
            },
            disabled() {
                return this.initDisabled;
            },
            options() {
                return this.filterItem.options;
            },
            activeOptions() {
                return this.options.filter(item => item.active);
            },
            hasActiveOptions() {
                return this.activeOptions.length > 0;
            },
            canShow() {
                return this.isExpand || this.hasActiveOptions;
            },
            optionsList() {
                if (! this.isExpand && this.hasActiveOptions) {
                    return this.activeOptions;
                }

                if (! this.isFull && this.options.length > 5) {
                    return this.options.slice(0, 5);
                }

                return this.options;
            },
            needFullList() {
                return this.isExpand && ! this.isFull && this.options.length > 5;
            }
        },
        methods: {
            toggleExpand() {
                this.isExpand = ! this.isExpand;
            },
            showFull() {
                this.isFull = true;
            },
            /**
             * Set active filter state
             * @param optionId
             */
            toggleActive(optionId) {
                if (this.disabled) return;

                let option = this.options.filter(item => item.id == optionId).shift();
                // for disabled option disable link
                if (option && ! option.disabled) {
                    option.active = ! option.active;
                    history.pushState({isAjax: false}, document.title, option.url);
                    Event.$emit('pushState', {url: option.url});
                }
            }

        },
        created() {
            this.isExpand = this.filterItem.expand;
        },
        directives: {
            scrollClass(el) {
                setTimeout(() => {
                    if (el.clientHeight >= 370) {
                        el.classList.add('is-scroll');
                    } else {
                        el.classList.remove('is-scroll');
                    }
                }, 10);
            }
        }
    }
</script>