<template>
    <div class="product-wrapper">
        <div class="product-item product-item--average">
            <a :href="url" target="_blank" class="product-item__image">
                <img :src="imageUrl" alt="">
            </a>
            <a :href="url" target="_blank" class="product-item__names">
                <span class="product-item__primary-name">{{ product.name | decode }}</span>
                <span class="product-item__alias-name">{{ product.nameAlias | decode }}</span>
            </a>
            <div class="product-price" :class="{ 'has-discount': offer.hasDiscount }" v-if="offer.isAvailable">
                <div class="product-price__current"><span v-html="offer.price.current | price"></span> грн</div>
                <div class="product-price__old" v-if="offer.hasDiscount"><span>{{ offer.price.old | price }}</span> грн</div>
            </div>
            <div class="product-item__unavailable-text" v-else>Нет в наличии</div>
            <offers-chooser
                    :current-offer-id="currentOfferId"
                    :init-offers="product.offers"
                    :names="names"
                    :rating="rating"
                    :only-active-offers="true"
                    @setOffer="setOffer"
                    @addToCart="addToCart">
            </offers-chooser>
            <div class="buy-container">
                <button type="button" class="btn btn-small btn-block btn-orange btn-buy" v-if="! isChoosed" @click="addToCart">ДОБАВИТЬ</button>
                <button type="button" class="btn btn-small btn-block btn-light-grey btn-buy" @click="addToCart" v-else>В КОРЗИНЕ</button>
            </div>
        </div>
    </div>
</template>

<script type="text/babel">
    import MobileDetect from '../mixins/mobile_detect';
    import { mapGetters } from 'vuex';
    import ProductMixin from '../mixins/product';

    export default {
        name: "AverageProductItem",
        mixins: [ MobileDetect, ProductMixin ],
        components: {
        },
        props: {
            onlyActiveOffers: {
                type: Boolean,
                default: true
            },
            previewEnabled: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                isOfferChanged: false,
                isChoosed: false,
            };
        },
        computed: {
            imageUrl() {
                return this.offer.image.thumb;
            },
            productCode() {
                const property = this.product.getPropertyByCode('code');
                if (property) {
                    return property.value;
                }
                return;
            },
            productClassnames() {
                return {
                    'is-active': this.isActive,
                    'is-unavailable': ! this.product.isAvailable
                };
            },
            showOverlay() {
                if (this.originalName) {
                    return this.originalName.length > 38;
                }
                return;
            },
            url() {
                let url = this.product.url;

                if (! this.isOfferChanged)
                    return url;

                let newUrl;
                if (/\/#\/offer\/\d+/.test(url)) {
                    newUrl = url.replace(/\/#\/offer\/\d+/, '/#/offer/' + this.offer.code);
                } else {
                    newUrl = url + '#/offer/' + this.offer.code;
                }
                return newUrl;
            },
            ...mapGetters(['isLogged'])
        },
        methods: {
            setOffer(id) {
                this.isOfferChanged = true;
                this.isChoosed = false;
                this.product.setOffer(id);
            },
            addToCart() {
                this.isChoosed = true;
                Event.$emit('addToCart', this.currentOfferId, {stats: {from: 'average_list'}});
            },
        },
        directives: {
            ovelay(el, binding, vnode) {
                const t = el.textContent;
                if (t.length > binding.value) {
                    let overlayDiv = document.createElement('div');
                    overlayDiv.classList.add('overlay')
                    el.appendChild(overlayDiv);
                }
            }
        },
        created() {

        }
    }
</script>