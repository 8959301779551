import {Offer, Product} from '../entities/catalog';
import { ActiveForm } from "../entities/active_form";
import RatingWidget from '../review/rating_widget.vue';
import OffersChooser from '../catalog/offers_chooser.vue';

export default {
    components: {
        RatingWidget,
        OffersChooser
    },
    props: {
        data: {
            required: true,
            type: Object
        },
    },
    data() {
        return {
            modalTimer: false,
            loaded: false,
            product: new Product(this.data)
        };
    },
    computed: {
        offer() {
            return this.product.getOffer();
        },
        currentOfferId() {
            return this.product.currentOfferId;
        },
        labelNew() {
            return this.product.getLabel('new');
        },
        labelDiscount() {
            return this.product.getLabel('discount');
        },
        labelTop() {
            return this.product.getLabel('top');
        },
        labelStock() {
            return this.product.getLabel('stock');
        },
        labelMarkdown() {
            return this.product.getLabel('markdown');
        },
        labelHasMarkdown() {
            return this.product.getLabel('hasMarkdown');
        },
        names() {
            return {
                productName: this.product.name,
                originalName: this.originalName,
            };
        },
        rating() {
            const stats = this.product.reviewsStats;
            return {
                stars: stats.totalRating,
                reviewsCount: stats.totalCount
            };
        },
        originalName() {
            return this.product.nameAlias;
        },
        bonus() {
            const property = this.offer.bonus;
            if (property) {
                return '+' + property;
            }
            return;
        },
    },
    methods: {
        addToCart() {
            // pixel track code
            fbq('track', 'AddToCart', {
                content_ids: (this.product.id + '_' + this.currentOfferId),
                content_type: 'product'
            });

            Event.$emit('addToCart', this.currentOfferId);
            Event.$emit('removeViewed', this.product.id);
        },
        toggleWishList() {
            //if (this.modalTimer) clearTimeout(this.modalTimer);

            //this.showWishlistModal = 1;
            //this.modalTimer = setTimeout(()=>{ this.showWishlistModal = 0; }, 4000);

            let link;
            if (this.offer.inWishList) {
                link = '/profile/wishlist/remove/';
            } else {
                link = '/profile/wishlist/add/'
            }

            let query = new ActiveForm({
                offer_id: this.offer.id
            });
            query.post(link)
                .then(r => {
                    if (r.success) {
                        //this.showWishlistModal = 2;
                        this.offer.inWishList = ! this.offer.inWishList;

                        //if (this.modalTimer) clearTimeout(this.modalTimer);
                        //this.modalTimer = setTimeout(()=>{ this.showWishlistModal = 0; }, 500);
                    }
                })
        }
    },
    created() {
        this.$on('activate', function (event) {

            if (!this.loaded) {
                this.loaded = true; // sync to avoid race condition
                this.$children[1].showSpinner();
                this.$http.get('/item/' + this.product.id + '/offers/').then((response) => {
                    if (Array.isArray(response.data)) {
                        this.product.offers = [];
                        response.data.forEach((offer) => {
                            this.product.offers.push(new Offer(offer));
                        });
                    }
                    this.$children[1].hideSpinner();
                });

            }
        });
    }
}