<template>
    <div class="countdown-container" v-if="isValid">
        <div class="countdown-title">ДО КОНЦА АКЦИИ ОСТАЛОСЬ</div>
        <div><span>ОСТАЛОСЬ {{ daysUntilEnd | uppercase }} {{ timeUntilEnd }}</span></div>
    </div>
</template>

<script type="text/babel">
    import moment from 'moment';
    import 'moment/src/locale/ru';

    export default {
        name: "StockCountdown",
        props: {
            dateTo: String
        },
        data: () => ({
            startDate: new Date(),
            endDate: null,
        }),
        computed: {
            durationUntilEnd() {
                let now = moment(this.startDate),
                    end = this.endDate;
                return moment.duration(end.diff(now));
            },
            daysUntilEnd() {
                let now = moment(this.startDate),
                    end = moment(this.dateTo);

                return now.to(end, true);
            },
            timeUntilEnd() {
                return moment(this.durationUntilEnd._data).format('HH:mm:ss');
            },
            isValid() {
                return this.durationUntilEnd._isValid;
            }
        },
        methods: {},
        mounted() {
            moment.locale('ru');
        },
        created() {
            setInterval(() => {
                this.startDate = new Date();
            }, 1000);

            this.endDate = moment(this.dateTo);
        },
    }
</script>