<template>
    <div class="brands">
        <ul>
            <li @mouseover="showSearchForm = false" v-for="brandData, brandLetter in brands" :class="{ 'empty-letter': ! brandData.length }">
                <a :href="'/category/brand/#' + brandLetter" v-text="brandLetter"></a>
                <ul>
                    <li v-for="brand in limitBrands(brandData)">
                        <a :href="brand.url">
                            <!--<img :src="brand.image" alt="">-->
                            <span class="brand-logo" :style="'background-image: url(' + brand.image + ')'"></span>
                            {{ brand.name }}
                        </a>
                    </li>
                </ul>
            </li>
        </ul>
        <div class="brand-items-wrapper" v-show="brandSearch.length && showSearchForm">
            <div class="brand-items">
                <div v-for="brand in brandSearch">
                    <a :href="brand.url">
                        <!--<img :src="brand.image" alt="">-->
                        <span class="brand-logo" :style="'background-image: url(' + brand.image + ')'"></span>
                        {{ brand.name }}
                    </a>
                </div>
            </div>
        </div>
        <div class="brand-search">
            <clip-loader :loading="loading" :color="'#4998cc'" :size="'10px'"></clip-loader>
            <div class="grid">
                <div class="row">
                    <div class="search-form">
                        <div class="form" v-if="showSearchForm">
                            <form @submit.prevent="handleSubmitForm">
                                <input type="text" name="q" placeholder="Введите название бренда" autocomplete="off" ref="searchInput" v-model="brandSearchName">
                                <input type="submit" name="s" value="">
                            </form>
                        </div>
                        <div class="placeholder icon-wrapper" v-else>
                            <i class="icon icon-search-mini"></i>
                            <div><a href="#" @click.prevent="showForm">Поиск по бренду</a></div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <a href="/category/brand/" class="btn btn-secondary brand-all-btn">ВСЕ БРЕНДЫ</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/babel">
    import {ActiveForm} from "../entities/active_form";

    export default {
        name: "BrandList",
        data() {
            return {
                showSearchForm: false,
                brandSearchName: '',
                brandSearch: [],
                loading: false
            };
        },
        computed: {

        },
        props: {
            brandSearchUrl: {},
            brands: { required: true }
        },
        methods: {
            showForm() {
                this.showSearchForm = true;
                setTimeout(() => this.$refs.searchInput.focus(), 10);
            },
            handleSubmitForm() {
                if (this.brandSearchName.length > 0) {
                    this.loading = true;
                    let query = new ActiveForm({
                        findByName: this.brandSearchName
                    });
                    query.post(this.brandSearchUrl)
                        .then(r => {
                            this.loading = false;
                            this.brandSearch = r;
                        })
                }
            },
            limitBrands(brandData) {
                return brandData.slice(0, 9);
            }
        },
        mounted() {
//            this.brands = this.data;
        }
    }
</script>