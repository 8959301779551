import MobileDetect from 'mobile-detect';
export default {
    data() {
        return {
            isMobile: false,
            isPhone: false,
            isTablet: false,
            isDesktop: false,
            os: '',
        };
    },
    created() {
        const md = new MobileDetect(window.navigator.userAgent);

        this.isMobile = Boolean(md.mobile());
        this.isPhone = Boolean(md.phone());
        this.isTablet = Boolean(md.tablet());
        this.isDesktop = ! this.isMobile;
        this.os = md.os();
    }
}
