<template>
    <div class="stock-full-container" :class="{ 'is-finished': stock.isFinished }">
        <div class="header" :class="{ 'has-gift': stock.isGiftType }">
            <div class="row">
                <div class="finished-label" v-if="stock.isFinished">
                    <span>Акция завершена</span>
                </div>
                <!--<img :src="stock.imageFull" alt="">-->
                <picture>
                    <source :srcset="stock.image" media="(max-width: 768px)">
                    <source :srcset="stock.imageFull">
                    <img :src="stock.imageFull" :srcset="stock.imageFull" alt="">
                </picture>
            </div>
            <div class="row" v-if="hasTimer && ! stock.isFinished">
                <stock-countdown :date-to="stock.dateTo"></stock-countdown>
            </div>
        </div>
        <div class="body" :class="{ 'has-gift': stock.isGiftType }">
            <div class="gift-container" v-if="stock.isGiftType">
                <header>
                    <p>ЧТОБЫ ПОЛУЧИТЬ В</p>
                    <p>ПОДАРОК</p>
                </header>
                <div class="gift-item-container">
                    <div class="gift-item">
                        <carousel
                                :per-page="1"
                                :autoplay="true"
                                :autoplay-timeout="4000"
                                :autoplay-hover-pause="true"
                                :navigation-enabled="true"
                                :pagination-enabled="false"
                                :infinite-loop="true">
                            <slide :key="index" v-for="gift,index in stock.gifts">
                                <div class="image-container">
                                    <img :src="gift.image" alt="">
                                </div>
                                <p>{{ gift.name | uppercase }}</p>
                            </slide>
                        </carousel>
                    </div>
                </div>
            </div>
            <div class="description-container editor-content">
                <slot name="description"></slot>
            </div>
        </div>
        <div class="category-loader" v-if="loading">
            <loader :loading="loading"></loader>
        </div>
        <div v-else-if="errorMessage">
            <h4>Ой йо йой!</h4>
            <p>Возникла проблема :( Если можете сообщите нам ошибку, которая написана ниже:</p>
            <code><pre v-text="errorMessage"></pre></code>
        </div>
        <template v-if="products.length > 0 && ! stock.isFinished">
            <div class="products">
                <header>ТОВАРЫ УЧАСТВУЮЩИЕ В АКЦИИ</header>
                <div class="products-container" v-if="! loading">
                    <product-item :data="product" :key="product.id" v-for="product in products"></product-item>
                </div>
            </div>

            <pagination :pages="pages" :page="page" :support-state="true"></pagination>
        </template>
    </div>
</template>

<script type="text/babel">
    import StockCountdown from './stock_countdown.vue';
    import { ActiveForm } from '../entities/active_form';
    import Pagination from '../components/pagination.vue';

    export default {
        components: {
            StockCountdown,
            Pagination,
        },
        name: "StockFull",
        props: {
            stock: Object
        },
        data: () => ({
            init: false,
            loading: false,
            errorMessage: '',
            products: [],
            pages: [],
            page: 0,
            pageSize: 0,
            pageCount: 0,
            totalCount: 0,
        }),
        computed: {
            hasTimer() {
                return this.stock.dateTo !== null;
            },
        },
        methods: {
            getData(url = window.location.href) {
                // clear error message
                this.errorMessage = '';

                this.loading = true;

                let form = new ActiveForm({});
                form.post(url)
                    .then(response => {
                        this.products = response.products;

                        this.pages = response.pages;
                        this.page = response.page;
                        this.pageSize = response.pageSize;
                        this.pageCount = response.pageCount;
                        this.totalCount = response.totalCount;

                        this.loading = false;

                        if (this.init) {
                            this.$nextTick(() => this.$scrollTo('.products'));
                        } else {
                            this.init = true;
                        }

                    })
                    .catch(e => {
                        this.errorMessage = e;
                        this.loading = false;
                    });
            },
        },
        mounted() {
        
        },
        created() {
            if (! this.stock.isFinished) {
                this.getData();
            }

            Event.$on('pushState', (event) => {
                this.getData(event.url);
            });

            window.addEventListener('popstate', (event) => {
                this.getData(document.location.href);
            }, false);
        },
    }
</script>