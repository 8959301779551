<template>
    <div>
        <tabs class="main-tabs" :strict-mode="false">
            <tab name="НОВИНКИ">
                <loader :loading="newLoading" v-if="newLoading"></loader>
                <carousel :per-page-custom="[[768, 2], [992, 3], [1170, 4]]" :navigation-enabled="true" :pagination-enabled="false" v-cloak v-else>
                    <slide :key="product.id" v-for="product in newProducts">
                        <product-item :data="product" :preview-enabled="false"></product-item>
                    </slide>
                    <slide v-if="false">
                        <div class="show-more new">
                            <a href="<?= Url::to(['/catalog/category/view', 'id' => 1]) ?>">
                                <p class="icon-wrapper"><i class="icon icon-plus-big"></i></p>
                                <p>ПОКАЗАТЬ БОЛЬШЕ</p>
                                <p>"НОВИНОК"</p>
                            </a>
                        </div>
                    </slide>
                </carousel>
            </tab>
            <tab name="ТОП ПРОДАЖ" :selected="true">
                <loader :loading="topLoading" v-if="topLoading"></loader>
                <carousel :per-page-custom="[[768, 2], [992, 3], [1170, 4]]" :navigation-enabled="true" :pagination-enabled="false" v-cloak v-else>
                    <slide :key="product.id" v-for="product in topProducts">
                        <product-item :data="product" :preview-enabled="false"></product-item>
                    </slide>
                    <slide v-if="false">
                        <div class="show-more top">
                            <a href="<?= Url::to(['/catalog/category/view', 'id' => 1]) ?>">
                                <p class="icon-wrapper"><i class="icon icon-plus-big"></i></p>
                                <p>ПОКАЗАТЬ БОЛЬШЕ</p>
                                <p>"ТОП ПРОДАЖ"</p>
                            </a>
                        </div>
                    </slide>
                </carousel>
            </tab>
            <tab name="СПЕЦПРЕДЛОЖЕНИЯ">
                <loader :loading="stockLoading" v-if="stockLoading"></loader>
                <carousel :per-page-custom="[[768, 2], [992, 3], [1170, 4]]" :navigation-enabled="true" :pagination-enabled="false" v-cloak v-else>
                    <slide :key="product.id" v-for="product in stockProducts">
                        <product-item :data="product" :preview-enabled="false"></product-item>
                    </slide>
                    <slide v-if="false">
                        <div class="show-more stock">
                            <a href="<?= Url::to(['/catalog/category/view', 'id' => 1]) ?>">
                                <p class="icon-wrapper"><i class="icon icon-plus-big"></i></p>
                                <p>ПОКАЗАТЬ БОЛЬШЕ</p>
                                <p>"СПЕЦПРЕДЛОЖЕНИЙ"</p>
                            </a>
                        </div>
                    </slide>
                </carousel>
            </tab>
        </tabs>
    </div>
</template>

<script type="text/babel">
    import Tabs from '../tabs/tabs.vue';
    import Tab from '../tabs/tab.vue';

    export default {
        name: "IndexPage",
        props: {},
        components: {
            Tabs,
            Tab,
        },
        data: () => ({
            topProducts: [],
            topLoading: true,
            newProducts: [],
            newLoading: true,
            stockProducts: [],
            stockLoading: true,
        }),
        computed: {},
        methods: {},
        mounted() {
            this.axios.get('/products/top/')
                .then(r => {
                    this.topLoading = false;
                    this.topProducts = r.data;
                });
            this.axios.get('/products/new/')
                .then(r => {
                    this.newLoading = false;
                    this.newProducts = r.data;
                });
            this.axios.get('/products/stock/')
                .then(r => {
                    this.stockLoading = false;
                    this.stockProducts = r.data;
                });
        },
        created() {
            
        },
    }
</script>