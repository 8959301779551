<template>
    <div>
        <div class="grid">
            <div class="row">
                <div class="icon-container"><i class="icon icon-contact-phones"></i></div>
                <div class="text-container">
                    <header>КОНТАКТЫ</header>
                    <p>МТС: <b>(050) 337-98-94</b></p>
                    <p>Лайф: <b>(063) 305-77-92</b></p>
                    <p>Киевстар: <b>(067) 829-39-55</b></p>
                    <p>Городской: <b>(044) 338-37-58</b></p>
                    <p>E-mail: <a href="mailto:support@avocado.ua" class="link">support@avocado.ua</a></p>
                </div>
            </div>
            <div class="row">
                <div class="icon-container"><i class="icon icon-contact-callcenter"></i></div>
                <div class="text-container">
                    <header>ГРАФИК РАБОТЫ CALL-ЦЕНТРА:</header>
                    <p>Пн-пт: <b>с 9 до 18:00</b></p>
                    <p>Сб: <b>с 10 до 16:00</b></p>
                    <p>Вс: <b>Выходной</b></p>
                </div>
            </div>
            <div class="row">
                <div class="icon-container"><i class="icon icon-contact-courier"></i></div>
                <div class="text-container">
                    <header>ГРАФИК РАБОТЫ КУРЬЕРСКОЙ ДОСТАВКИ:</header>
                    <p>Пн-пт: <b>с 10 до 18:00</b></p>
                    <p>Сб: <b>с 10 до 16:00</b></p>
                    <p>Вс: <b>Выходной</b></p>
                </div>
            </div>
            <div class="row">
                <div class="icon-container"><i class="icon icon-contact-questions"></i></div>
                <div class="text-container">
                    <header>ВОПРОСЫ, ПОЖЕЛАНИЯ?</header>
                    <p>Создайте обращение, которое будет рассмотрено администрацией в течение 24 часов.</p>
                    <p><a href="#" class="link" @click.prevent="showAbuseModal">Связаться с директором.</a></p>
                </div>
            </div>
            <div class="row">
                <div class="icon-container"><i class="icon icon-contact-pickup"></i></div>
                <div class="text-container">
                    <header>ПУНКТЫ САМОВЫВОЗА:</header>
                    <p>г. Киев, ул. Коллекторная, 38/40</p>
                    <p>г. Киев, ул. Новодарницкая, 23/7</p>
                    <p>г. Киев, ул. Никольско-Слободская, 6-Б</p>
                    <p>г. Киев, ул. Белорусская, 30</p>
                </div>
            </div>
        </div>

        <modal :init-modal="isModalActive" class="modal-contact" title="ПУНКТ САМОВЫВОЗА" @close="hideModal">
            <p class="label"><b>г. Киев, ул. Княжий Затон, д. 21</b></p>
            <p>Вход в магазин с ортопедического салона "Bauerfeind", большая вывеска со стороны Днепровской Набережной.</p>
            <p class="label"><b>График работы магазина:</b></p>
            <br>
            <p>Пн-пт: <b>с 9 до 20:00</b></p>
            <p>Сб: <b>с 10 до 17:00</b></p>
            <p>Тел: <b>(095) 455-55-17</b></p>
            <br>
            <p>Самовывоз заказов доступен только по предварительному согласованию с менеджером по телефону!</p>
            <br>
            <p><img src="/build/images/pickup-map.png" alt=""></p>
        </modal>
    </div>
</template>

<script type="text/babel">
    export default {
        name: "ContactsPage",
        props: {},
        data: () => ({
            isModalActive: false,
        }),
        computed: {},
        methods: {
            showAbuseModal() {
                Event.$emit('showAbuseModal');
            },
            showModal() {
                this.isModalActive = true;
            },
            hideModal() {
                this.isModalActive = false;
            }
        },
        mounted() {
        
        },
        created() {
            
        },
    }
</script>