<template>
    <div v-if="links.length > 0">
        <ul>
            <li class="home" v-if="! isPhone">
                <a href="/"><i class="icon icon-home"></i></a>
            </li>
            <template v-if="isTablet && activeLinks.length > 2">
                <li>
                    <a :href="activeLinks[0].url">
                        <span>{{ activeLinks[0].label | decode }}</span>
                    </a>
                </li>
                <li class="more" v-if="moreLinks.length != this.links.length - 1"><a href="#" @click.prevent="showMore">...</a></li>
                <li v-for="link in moreLinks">
                    <a :href="link.url" v-if="link.hasOwnProperty('url')">
                        <span>{{ link.label | decode }}</span>
                    </a>
                    <span v-else>{{ link | decode }}</span>
                </li>
            </template>
            <template v-else-if="isPhone">
                <li>
                    <a :href="lastLink.url" v-if="lastLink.hasOwnProperty('url')">
                        <span>{{ lastLink.label | decode }}</span>
                    </a>
                    <span v-else>{{ lastLink | decode }}</span>
                </li>
            </template>
            <template v-else>
                <li v-for="link in activeLinks">
                    <a :href="link.url" v-if="link.hasOwnProperty('url')">
                        <span>{{ link.label | decode }}</span>
                    </a>
                    <span v-else>{{ link | decode }}</span>
                </li>
            </template>
        </ul>
    </div>
</template>

<script type="text/babel">
    export default {
        name: "Breadcrumbs",
        props: {
            links: {
                required: true,
                type: Array
            }
        },
        data() {
            return {
                windowWidth: 0,
                activeLinksCount: 0
            };
        },
        computed: {
            isTablet() {
                return this.windowWidth < 768 && this.windowWidth >= 460;
            },
            isPhone() {
                return this.windowWidth < 460;
            },
            isDesktop() {
                return ! this.isTablet && ! this.isPhone;
            },
            activeLinks() {
                if (this.isTablet) {

                }

                return this.links;
            },
            moreLinks() {
                console.log(this.links.length);
                let data = [];
                let lastIndex = this.links.length - this.activeLinksCount;

                for (let i = this.links.length; i >= lastIndex; i--) {
                    data.unshift(this.links[i - 1]);
                }

                return data;
            },
            lastLink() {
                return this.links[this.links.length - 1];
            }
        },
        methods: {
            setWindowWidth() {
                this.windowWidth = window.innerWidth;
            },
            showMore() {
                this.activeLinksCount++;
            }
        },
        created() {
            this.setWindowWidth();
            window.addEventListener('resize', this.setWindowWidth);
        }
    }
</script>