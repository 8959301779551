<template>
    <div class="offer-item">
        <div class="grid">
            <div class="row">
                <custom-checkbox v-model="offer.isMark"></custom-checkbox>
            </div>
            <div class="row">
                <a href="#" @click.prevent="handleRemove"><i class="icon icon-cart-remove"></i></a>
            </div>
        </div>
        <a :href="offer.url" class="image">
            <img v-lazy="imageUrl">
        </a>
        <div class="rating-widget text-center">
            <rating-widget :rating="offer.productRating.totalRating" :reviews-count="offer.productRating.totalCount" :url="url"></rating-widget>
        </div>
        <div class="code">Код товара: <span v-text="offer.code"></span></div>
        <div class="names">
            <div class="primary-name"><a :href="offer.url">{{ offer.name | decode }}</a></div>
            <div class="secondary-name">
                <div class="overlay" v-if="showOverlay"></div>
                {{ offer.productName | decode | uppercase }}
            </div>
        </div>
        <div class="price-container" :class="{ 'with-discount': offer.hasDiscount }" v-if="offer.isAvailable">
            <div class="price-current"><span>{{ offer.price.current | price }}</span> грн</div>
            <div class="price-old" v-if="offer.hasDiscount"><span>{{ offer.price.old | price }}</span> грн</div>
        </div>
        <div class="unavailable-text" v-else>Нет в наличии</div>

        <div v-if="offer.isAvailable">
            <div class="buy-container">
                <button class="btn btn-small btn-block btn-orange btn-buy" @click="addToCart">В КОРЗИНУ</button>
            </div>
        </div>
        <div class="available-reminder-container" v-else>
            <available-informer :init-offer="offer" btn-class="btn btn-small btn-block btn-light-grey text-center">Уведомить о появлении</available-informer>
        </div>
    </div>
</template>

<script type="text/babel">
    import RatingWidget from '../review/rating_widget.vue';
    import CustomCheckbox from '../components/custom_checkbox.vue';
    import { ActiveForm } from '../entities/active_form';
    import AvailableInformer from './available_informer.vue';

    export default {
        name: "OfferItem",
        components: {
            AvailableInformer,
            CustomCheckbox,
            RatingWidget
        },
        props: {
            offer: Object
        },
        data: () => ({

        }),
        computed: {
            imageUrl() {
                return this.offer.image.thumb;
            }
        },
        methods: {
            addToCart() {
                // pixel track code
                fbq('track', 'AddToCart', {
                    content_ids: (this.offer.product_id + '_' + this.offer.id),
                    content_type: 'product'
                });

                Event.$emit('addToCart', this.offer.id);
                Event.$emit('removeViewed', this.offer.product_id);
            },
            handleRemove() {
                if (confirm('Вы уверенны что хотите удалить этот товар из избранного?')) {
                    let query = new ActiveForm({
                        offer_id: this.offer.id
                    });

                    query.post('/profile/wishlist/remove/')
                        .then(r => {
                            if (r.success) {
                                this.offer.isRemoved = true;
                            }
                        })
                }

            }
        },
        mounted() {
        
        },
        created() {
            
        },
    }
</script>