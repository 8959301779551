
export default {
    components: {
    },
    props: ['model', 'initLoading'],
    data() {
        return {
            loading: false
        };
    },
    computed: {
        isLoading() {
            return this.initLoading || this.loading;
        },
    },
    methods: {
        onSubmit() {
            this.$emit('submit');
        },
        back() {
            this.$emit('back');
        }
    }
}