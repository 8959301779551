<template>
    <div class="stock-item" :class="{ 'is-finished': stock.isFinished }">
        <div class="condition countdown-container">
            <template v-if="hasTimer && ! stock.isFinished">
                <stock-countdown :date-to="stock.dateTo"></stock-countdown>
            </template>
            <template v-else-if="! hasTimer">
                <div><span>ДО ПОСЛЕДНЕГО ПОДАРКА</span></div>
            </template>
        </div>
        <a :href="stock.url">
            <span class="finished-label" v-if="stock.isFinished">
                <span>Акция завершена</span>
            </span>
            <img :src="stock.image" alt="">
            <!--<img v-lazy="stock.image">-->
        </a>
        <p class="title">{{ stock.name | decode | uppercase }}</p>
        <div class="preview-text">{{ stock.descriptionShort | decode }}</div>
        <a :href="stock.url" class="detail icon-wrapper">
            <span>Подробнее</span>
            <i class="icon-arrow-right"></i>
        </a>
    </div>
</template>

<script type="text/babel">
    import StockCountdown from './stock_countdown.vue';

    export default {
        name: "StockItem",
        components: {
            StockCountdown
        },
        props: {
            stock: Object
        },
        data: () => ({

        }),
        computed: {
            hasTimer() {
                return this.stock.dateTo !== null;
            },
        },
        methods: {},
        mounted() {

        },
        created() {

        },
    }
</script>