<template>
    <div class="review-last-item">
        <div class="review-last-item__image">
            <a :href="review.product.url" class="review-last-item__image-link">
                <img :src="review.product.image" alt="">
            </a>
        </div>
        <div class="review-last-item__rating">
            <stars-rating :rating="review.rating" :read-only="true"></stars-rating>
        </div>
        <div>
            <a :href="review.product.url" class="review-last-item__link">
                <span class="review-last-item__name">{{ review.product.name | decode }}</span>
                <span class="review-last-item__name-alias">{{ review.product.nameAlias | decode }}</span>
            </a>
        </div>
        <div class="review-last-item__content" v-html="decode(review.content)"></div>
        <div class="review-last-item__footer">
            <p>{{ review.created }},</p>
            <p>{{ review.name | decode }} {{ review.city }}</p>
        </div>
    </div>
</template>

<script>
    import StarsRating from './stars_rating.vue';
    import {decode} from '../entities/helpers';

    export default {
        name: "ReviewsLastItem",
        components: {
            StarsRating
        },
        props: {
            review: {
                type: Object,
                required: true,
            }
        },
        methods: {
            decode
        }
    }
</script>