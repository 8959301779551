<template>
    <div class="answer-item-container">
        <div class="grid">
            <div class="answer-icon">
                <i class="icon icon-answer-float"></i>
            </div>
            <div class="answer-item">
                <header class="grid">
                    <div class="row name" :class="{ 'is-admin': answer.isAdmin }">
                        Ответ от <span v-text="username"></span>
                    </div>
                    <div class="row date" v-html="answer.created"></div>
                </header>
                <article>
                    <p v-html="decode(answer.content)"></p>
                    <p class="signature" v-if="answer.isAdmin">С уважением и благодарностью, коллектив Avocado.ua</p>
                </article>
                <footer v-if="canAnswer">
                    <a href="#" @click.prevent="toggleForm">Ответить</a>
                </footer>
            </div>
        </div>

        <answer-form :reviews-id="reviewId" :key="answer.id" :answer-id="answer.id" :show-form="isFormShown" @close="hideForm" v-if="canAnswer"></answer-form>

        <div class="children" v-if="hasChildren">
            <answer-item :review-id="reviewId" :key="child.id" :answer="child" v-for="child in childrenList" :level="newLevel" :init-child-show="initChildShow"></answer-item>
            <div class="show-more-child" v-if="canShowFullChildrenList">
                <a href="#" class="icon-wrapper" @click.prevent="nextPage">
                    <span>Показать еще</span>
                    <i class="icon icon-product-offer-choose"></i>
                </a>
            </div>
        </div>
    </div>
</template>

<script type="text/babel">
    import f from 'sprintf-js';
    import AnswerForm from './answer_form.vue';
    import {decode} from '../entities/helpers';

    export default {
        name: "AnswerItem",
        components: {
            AnswerForm
        },
        props: {
            reviewId: {
                required: true,
                type: Number
            },
            answer: {
                required: true,
                type: Object
            },
            level: {
                type: Number,
                default: 1
            },
            initChildShow: {
                type: Number,
                default: 6
            }
        },
        data() {
            return {
                isFormShown: false,
                isFullChildrenList: false,
                page: 1
            };
        },
        computed: {
            username() {
                if (this.answer.isAdmin)
                    return 'Avocado.ua';

                return f.vsprintf('%s, %s', [this.answer.name.toUpperCase(), this.answer.city.toUpperCase()]);
            },
            hasChildren() {
                return this.answer.children.length > 0;
            },
            canAnswer() {
                return this.level < 3;
            },
            newLevel() {
                return parseInt(this.level + 1);
            },
            limit() {
                return this.page * this.initChildShow;
            },
            canShowFullChildrenList() {
                return this.answer.children.length > this.limit;
            },
            childrenList() {
                if (this.canShowFullChildrenList && ! this.isFullChildrenList) {
                    return this.answer.children.slice(0, this.limit);
                }
                return this.answer.children;
            }
        },
        methods: {
            showForm() {
                this.isFormShown = true;
            },
            hideForm() {
                this.isFormShown = false;
            },
            toggleForm() {
                this.isFormShown = ! this.isFormShown;
            },
            nextPage() {
                this.page++;
            },
            decode
        }
    }
</script>