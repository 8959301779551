<template>
    <div class="product-informer grid">
        <div class="image"><a :href="product.url"><img :src="offer.image.thumb" alt=""></a></div>
        <div class="description">
            <p class="title">
                <a :href="product.url">
                    <span class="name">{{ product.name | decode | uppercase }}</span>
                    <span class="name-alias">{{ product.nameAlias | decode | uppercase }}</span>
                </a>
            </p>
            <div class="grid">
                <div class="row price"><span>{{ offer.price.current | price }}</span> грн</div>
                <div class="row offer-value"><span v-text="currentOfferValue"></span></div>
            </div>
        </div>
    </div>
</template>

<script type="text/babel">
    import { Product } from '../entities/catalog';

    export default {
        name: "ProductInformer",
        props: {
            data: {
                required: true,
                type: Object
            }
        },
        data() {
            return {
                product: new Product(this.data),
            };
        },
        computed: {
            offer() {
                return this.product.getOffer();
            },
            variant() {
                let firstOffer = this.offer;
                if (! firstOffer) {
                    throw new Error('Не могу получить первое торговое предложение');
                }

                return firstOffer.cartVariant;
            },
            currentOfferValue() {
                let cartProperty = this.offer.cartProperty;
                if (cartProperty) {
                    const value = cartProperty.value;
                    if (! this.variant.simple) {
                        const match = /\w+/.exec(value);
                        if (match)
                            return match[0];
                    }
                    return value;
                }
                return 'null';
            }
        }
    }
</script>