<template>
    <div class="review-item" :class="{ 'review-item--simple': simple }">
        <header class="review-item__header">
            <div class="review-item__name">
                <span>{{ username | decode }}</span>
            </div>
            <div class="review-item__rating"><stars-rating :rating="review.rating" :read-only="true"></stars-rating></div>
            <div class="review-item__recommendations" v-if="! simple">
                <div class="review-item__recommendations-content">
                    <div class="review-item__recommendations-row review-item__recommendations-row--first">
                        <div class="icon-wrapper" v-if="hasrecommendations">
                            <i :class="'icon icon-thumb-' + (isRecommended ? 'up' : 'down')"></i>
                            <span :class="isRecommended ? 'up' : 'down'">{{ isRecommended ? 'Рекомендую' : 'Не рекомендую' }}</span>
                        </div>
                    </div>
                    <div class="review-item__recommendations-row review-item__recommendations-row--last icon-wrapper" v-if="! simple">
                        <div class="icon-wrapper" v-if="review.buyApproved">
                            <i class="icon icon-buy-approved"></i>
                            <span>Покупка подтверждена</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="review-item__date" v-html="review.created"></div>
        </header>
        <article class="review-item__content">
            <div class="review-item__parameters" v-if="! simple && hasParams">
                <div class="review-item__parameters-row" v-if="review.timeType"><span class="label">Время использования:</span> {{ review.timeType }}</div>
                <div class="review-item__parameters-row" v-if="review.skinType"><span class="label">Тип кожи:</span> {{ review.skinType }}</div>
                <div class="review-item__parameters-row" v-if="review.ageType"><span class="label">Возраст:</span> {{ review.ageType }}</div>
            </div>
            <div>
                <div v-html="decode(fullContent)"></div>
                <div v-if="canShowFullContent"><a href="#" @click.prevent="toggleFullContent">{{ isFullContent ? 'Скрыть' : 'Показать польностью' }}</a></div>
            </div>
            <div class="review-item__advantages" v-if="! simple && review.advantages"><span class="label">Достоинства:</span> <span v-html="decode(review.advantages)"></span></div>
            <div class="review-item__limitations" v-if="! simple && review.limitations"><span class="label">Недостатки:</span> <span v-html="decode(review.limitations)"></span></div>
            <div class="review-item__files" v-if="! simple && hasAttachments">
                <div><span class="label">Прикрепленные изображения:</span></div>
                <div class="review-item__files-list">
                    <a :href="file.original" v-for="file in review.attachments" target="_blank">
                        <img :src="file.thumb" >
                    </a>
                </div>
            </div>
        </article>
        <footer class="review-item__footer" v-if="! simple">
            <div class="grid">
                <div><a href="#" @click.prevent="toggleForm" class="review-item__answer-button">Ответить</a></div>
                <div class="review-item__recommendation-label" v-if="hasStatsrecommendations">{{ statsrecommendationsPercent }}% пользователей считают этот отзыв полезным</div>
                <div class="review-item__stats">
                    <div class="review-item__stats-row">
                        <a href="#" class="review-item__stats-action icon-wrapper" :class="{ 'is-disabled': ! canRate}" @click.prevent="rateUp">
                            <span v-text="review.stats.positive"></span>
                            <i class="icon icon-thumb-up"></i>
                        </a>
                    </div>
                    <div class="row">
                        <a href="#" class="review-item__stats-action icon-wrapper" :class="{ 'is-disabled': ! canRate}" @click.prevent="rateDown">
                            <i class="icon icon-thumb-down"></i>
                            <span v-text="review.stats.negative"></span>
                        </a>
                    </div>
                </div>
            </div>
        </footer>

        <answer-form :reviews-id="review.id" :show-form="isFormShown" @close="hideForm" v-if="! simple"></answer-form>
        <answers :review-id="review.id" :answers="review.answers" v-if="! simple"></answers>
    </div>
</template>

<script type="text/babel">
    import f from 'sprintf-js';
    import StarsRating from './stars_rating.vue';
    import AnswerForm from './answer_form.vue';
    import Answers from './answers.vue';
    import {ActiveForm} from '../entities/active_form';
    import {decode} from '../entities/helpers';

    export default {
        components: {
            Answers,
            AnswerForm,
            StarsRating
        },
        name: "ReviewItem",
        props: {
            review: {
                required: true,
                type: Object
            },
            simple: {
                type: Boolean,
                default: false
            },
            contentLimit: {
                type: Number,
                default: 300,
            },
        },
        data() {
            return {
                isFormShown: false,
                rateLoading: false,
                isFullContent: false,
            };
        },
        computed: {
            username() {
                return f.vsprintf('%s, %s', [this.review.name.toUpperCase(), this.review.city.toUpperCase()]);
            },
            fullContent() {
                if (! this.isFullContent && this.simple) {
                    return this.review.content.substr(0, this.contentLimit) + '...';
                }

                return this.review.content;
            },
            canShowFullContent() {
                return this.simple && this.review.content.length > this.contentLimit;
            },
            hasrecommendations() {
                return this.review.recommendation !== null;
            },
            isRecommended() {
                return this.review.recommendation;
            },
            hasParams() {
                return Boolean(this.review.timeType || this.review.skinType || this.review.ageType);
            },
            hasAttachments() {
                return this.review.attachments.length > 0;
            },
            hasStatsrecommendations() {
                return this.review.stats.positive > 0;
            },
            statsrecommendationsPercent() {
                if (! this.hasStatsrecommendations) return 0;
                let stats = this.review.stats;
                return Math.round(stats.positive / (stats.positive + stats.negative) * 100);
            },
            canRate() {
                return this.review.canRate;
            }
        },
        methods: {
            rate(value) {
                this.review.canRate = false;
                let query = new ActiveForm({
                    review_id: this.review.id,
                    rate: value,
                });

                query.post('/reviews/rate/')
                    .catch(e => console.log(e));
            },
            rateUp() {
                if (this.canRate) {
                    this.review.stats.positive++;
                    this.rate(1);
                }
            },
            rateDown() {
                if (this.canRate) {
                    this.review.stats.negative++;
                    this.rate(-1);
                }
            },
            showForm() {
                this.isFormShown = true;
            },
            hideForm() {
                this.isFormShown = false;
            },
            toggleForm() {
                this.isFormShown = ! this.isFormShown;
            },
            toggleFullContent() {
                this.isFullContent = ! this.isFullContent;
            },
            decode,
        }
    }
</script>