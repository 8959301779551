<template>
    <div class="delivery-page-container">
        <p class="text-center">Сотрудничество с наиболее известными службами доставки, а также использование собственной курьерской службы позволит доставить Ваш заказ максимально быстро.
            Товар, приобретенный в магазине, Вы можете оплатить любым удобным для Вас способом из представленных ниже.</p>

        <div class="grid search-form">
            <div class="row">В Вашем городе </div>
            <div class="row form-group">
                <custom-select :values="cityList" v-model="model.cityId" @keydown="updateVariants" ref="deliverySelect"
                               :autocomplete="true" :auto-choose="true" @autocomplete="setCityName" class="form-control"></custom-select>
            </div>
            <div class="row">доступны такие способы доставки:</div>
        </div>

        <div class="grid deliveries">
            <div class="row" v-for="delivery in deliveries" @click="showModal(delivery.id)">
                <p><i :class="'icon icon-delivery-page-delivery-' + delivery.key"></i></p>
                <p><b>{{ delivery.name }}</b></p>
                <p><span v-if="delivery.title">({{ delivery.title }})</span></p>
            </div>
        </div>

        <header>Способы оплаты</header>
        <div class="grid paysystems">
            <div class="row" v-for="paysystem in paysystems">
                <p><i :class="'icon icon-delivery-page-paysystem-' + paysystem.key"></i></p>
                <p><b>{{ paysystem.name }}</b></p>
                <p><span v-if="paysystem.title">({{ paysystem.title }})</span></p>
            </div>
        </div>

        <modal :init-modal="isModalActive" class="modal-delivery" title="Доставка" @close="hideModal">
            <template v-if="currentDelivery">
                <p class="icon-container"><i :class="'icon icon-delivery-page-delivery-' + currentDelivery.key"></i></p>
                <p class="label"><b>{{ currentDelivery.name }}</b></p>
                <p><b>Детали доставки:</b></p>
                <div v-html="currentDelivery.description"></div>
            </template>
        </modal>
    </div>
</template>

<script type="text/babel">
    import City from '../mixins/city';
    import { ActiveForm } from '../entities/active_form';
    import { mapGetters } from 'vuex';

    export default {
        components: {
        },
        name: "DeliveryPage",
        mixins: [ City ],
        props: {},
        data: () => ({
            isModalActive: false,
            deliveries: [],
            paysystems: [],
            deliveryId: 0,
            model: new ActiveForm({
                cityId: '',
            })
        }),
        computed: {
            currentDelivery() {
                let delivery = this.deliveries.find(item => item.id == this.deliveryId);
                if (delivery) {
                    return delivery;
                }
            },
            ...mapGetters(['city'])
        },
        methods: {
            updateVariants() {
                this.model.post(window.location.href)
                    .then(r => {
                        this.deliveries = r.deliveries;
                        this.paysystems = r.paysystems;
                    });
            },
            showModal(deliveryId) {
                this.deliveryId = deliveryId;
                this.isModalActive = true;
            },
            hideModal() {
                this.isModalActive = false;
            }
        },
        watch: {
            city(object) {
                this.cityValue = {
                    value: object.id,
                    text: object.name
                };
                this.model.cityId = object.id;
                this.$refs.deliverySelect.$emit('keydown');
            }
        },
        mounted() {

        },
        created() {

        },
    }
</script>