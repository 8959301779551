// root import
import Vue from 'vue';
// import Raven from 'raven-js';
// import RavenVue from 'raven-js/plugins/vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueCookie from 'vue-cookie';
// import VueAjax from './vue_components/directives/ajax';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import VueCarousel from 'vue-carousel';
import VueTooltip from './vue_components/directives/tooltip';
import VueLazyload from 'vue-lazyload';
import VueI18n from 'vue-i18n';
import Vuebar from 'vuebar';
import VueFilters from './vue_components/filters/index';
import VueMask from 'v-mask';
import VueJsonp from 'vue-jsonp';

// import vuex store
import {store} from './store';

// directives
import { directive as onClickaway } from 'vue-clickaway';
import Expandable from './vue_components/directives/expandable';
import VueScrollTo from 'vue-scrollto';

// own components
import CityChooser from './vue_components/main/city_chooser.vue';
import CartInformer from './vue_components/cart/cart_informer.vue';
import RequestCall from './vue_components/main/request_call.vue';
import AuthForm from './vue_components/main/auth.vue';
import Slider from './vue_components/slider/slider.vue';
import BrandList from './vue_components/main/brands.vue';
import Search from './vue_components/main/search.vue';
import SeoText from './vue_components/main/seo_text.vue';
import Abuse from './vue_components/main/abuse.vue';
import MobileMenu from './vue_components/mobile/menu.vue';
import MobileSearch from './vue_components/mobile/search.vue';
import MobileMoveTop from './vue_components/mobile/move_top.vue';
import MobileHotActions from './vue_components/mobile/hot_actions.vue';
import Category from './vue_components/catalog/category.vue';
import Breadcrumbs from './vue_components/main/breadcrumbs.vue';
import ProductPage from './vue_components/product/product_full_item.vue';
import ProductContainer from './vue_components/components/product_container.vue';
import ProductItem from './vue_components/catalog/product_item.vue';
import SignupForm from './vue_components/main/signup.vue';
import RecoveryForm from './vue_components/main/restore_password.vue';
import OrderForm from './vue_components/order/form.vue';
import OrderMake from './vue_components/order/make.vue';
import ProfileOrders from './vue_components/profile/orders.vue';
import ProfileAccount from './vue_components/profile/account.vue';
import ProfileAddressbook from './vue_components/profile/addressbook.vue';
import ProfileWishlist from './vue_components/profile/wishlist.vue';
import ProfileMenu from './vue_components/profile/menu.vue';
import Stock from './vue_components/stock/stock.vue';
import StockFull from './vue_components/stock/stock_full.vue';
import ContactsPage from './vue_components/pages/contacts.vue';
import DeliveryPage from './vue_components/pages/delivery.vue';
import IndexPage from './vue_components/pages/index.vue';
import Modal from './vue_components/components/modal.vue';
import Loader from './vue_components/components/loader.vue';
import ButtonLoading from './vue_components/components/loading_btn.vue';
import CustomSelect from './vue_components/components/custom_select.vue';
import Subscribe from './vue_components/main/subscribe.vue';
import InstagramPosts from './vue_components/components/instagram_posts.vue';
import ProductInformer from './vue_components/product/product_informer.vue';
import ReviewsLast from './vue_components/review/reviews_last.vue';
import Lottery from './vue_components/trigger/lottery.vue';

// localization data
import messages from './localization/messages';

window.Event = new Vue();

// install plugins
Vue.use(VueAxios, axios);
Vue.use(VueCookie);
// Vue.use(VueAjax);
Vue.use(VueCarousel);
Vue.use(VueTooltip);
Vue.use(VueLazyload, {
    preLoad: 1.3,
    error: '/build/images/preloader_error.png',
    loading: '/build/images/preloader.png',
    attempt: 1
});
Vue.use(VueI18n);
Vue.use(Vuebar);
Vue.use(VueFilters);
Vue.use(VueScrollTo, {
    offset: -48
});
Vue.use(VueMask);
Vue.use(VueJsonp);

// add custom component
Vue.component('clip-loader', PulseLoader);
Vue.component('modal', Modal);
Vue.component('loader', Loader);
Vue.component('button-loading', ButtonLoading);
Vue.component('custom-select', CustomSelect);
Vue.component('product-container', ProductContainer);
Vue.component('product-item', ProductItem);
Vue.component('instagram-posts', InstagramPosts);
Vue.component('product-informer', ProductInformer);

// directives
Vue.directive('on-clickaway', onClickaway);
Vue.use(Expandable);

// Raven
//     .config('https://d88dcf3dcdae48f885d679838403bec9@sentry.io/481150')
//     .addPlugin(RavenVue, Vue)
//     .install();

const currentLocale = document.querySelector('html').getAttribute('lang');

const i18n = new VueI18n({
    locale: currentLocale,
    messages
});

new Vue({
    i18n,
    store,
    el: '#root',
    data: {
        lotteryActive: false,
        lotteryComplete: false,
        loaded: true,
        screenWidth: 0,
    },
    components: {
        CityChooser,
        CartInformer,
        RequestCall,
        AuthForm,
        Slider,
        BrandList,
        Search,
        SeoText,
        Abuse,
        MobileMenu,
        MobileSearch,
        MobileMoveTop,
        MobileHotActions,
        Category,
        Breadcrumbs,
        ProductPage,
        SignupForm,
        RecoveryForm,
        OrderForm,
        OrderMake,
        ProfileOrders,
        ProfileAccount,
        ProfileAddressbook,
        ProfileWishlist,
        ProfileMenu,
        Stock,
        StockFull,
        ContactsPage,
        DeliveryPage,
        IndexPage,
        Subscribe,
        ReviewsLast,
        Lottery,
    },
    computed: {
        isMobile() {
            // return window.innerWidth < 768;
            return this.getScreenWidth() < 768;
        }
    },
    methods: {
        getScreenWidth() {
            this.screenWidth = window.innerWidth;
            return this.screenWidth;
        },
        showAuth(e) {
            this.$refs.auth.showModal(e);
        },
        toggleMobileActions(isActive) {
            let bottomMargin = null;
            let duration = 700;
            if (isActive) {
                bottomMargin = '110px';
                duration = 10;
            }

            setTimeout(() => {
                this.$refs.hotActions.$el.firstChild.style.bottom = bottomMargin;
                this.$refs.moveTop.$el.style.bottom = bottomMargin;
            }, duration);
        },
        hideLottery() {
            this.lotteryActive = false;
            this.completeLottery();
        },
        completeLottery() {
            this.lotteryComplete = true;
            this.$cookie.set('lotteryComplete', 1);
        }
    },
    mounted() {
        // console.log('mounted');
        this.axios.get('/cities/')
            .then(r => {
                this.$store.dispatch('setCityPrimaryList', r.data);
            });

        this.lotteryComplete = Boolean(this.$cookie.get('lotteryComplete'));
    },
    updated() {
        // enableScroll();
        this.loaded = true;
        // console.log('updated');
    },
    created() {
        this.getScreenWidth();
        window.addEventListener('resize', () => this.getScreenWidth());

        // disableScroll();
        this.axios.defaults.headers.common['X-REQUESTED-WITH'] = 'XMLHttpRequest';

        // mobile hot actions position update
        Event.$on('hotActionsToggle', (isActive) => this.toggleMobileActions(isActive));

        document.addEventListener('mouseleave', () => {
            if (!this.lotteryComplete && !this.$refs.auth.isLogged) {
                this.lotteryActive = true;
            }
        })
    },
});
