<template>
    <button @click="handleClick">
        <template v-if="isLoading">
            <clip-loader :loading="loading" :color="color" :size="size"></clip-loader>
        </template>
        <template v-else>
            <slot></slot>
        </template>
    </button>
</template>

<script type="text/babel">
    export default {
        name: "ButtonLoading",
        props: {
            loading: {
                required: true,
                type: Boolean
            },
            color: {
                default: '#fff'
            },
            size: {
                default: '8px'
            }
        },
        data() {
            return {

            };
        },
        computed: {
            isLoading() {
                return this.loading;
            }
        },
        methods: {
            handleClick() {
                if (! this.isLoading)
                    this.$emit('click');
            }
        }
    }
</script>