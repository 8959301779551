<template>
    <!--<transition name="modal">-->
        <div class="modal" v-show="isActive" :style="'z-index: ' + zIndex">
            <!--:class="{ 'transparent': $root.modalCount > 1}"-->
            <div class="modal-background" @click="hideModal"></div>
            <div class="modal-content">
                <!--<div class="modal-close" v-if="withClose || isMobile" @click="hideModal"><i class="icon icon-modal-close"></i></div>-->
                <div class="modal-close" v-if="withClose || isMobile" @click="hideModal"><span class="close"></span></div>
                <div class="modal-title" v-if="title.length > 0 && isShowTitle" v-text="title"></div>
                <slot></slot>
            </div>
        </div>
    <!--</transition>-->
</template>

<script type="text/babel">
    import { mapGetters } from 'vuex';

    export default {
        name: "Modal",
        data() {
            return {
                modalCurrentCount: 0,
                isActive: this.initModal
            }
        },
        props: {
            withClose: {
                type: Boolean,
                default: true
            },
            initModal: {
                type: Boolean,
                default: false
            },
            title: {
                default: ''
            },
            showTitle: {
                type: Boolean,
                default: true
            },
            sticked: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            isShowTitle() {
                return this.showTitle;
            },
            isMobile() {
                return this.$root.isMobile;
            },
            zIndex() {
                return this.modalCurrentCount + 50;
            },
            ...mapGetters(['modalTotalCount'])
        },
        methods: {
            hideModal() {
                this.$emit('close');
//                if (! this.isMobile) enableScroll();
//                Event.$emit('closeModal', this.name);
            }
        },
        created() {
//            Event.$on('closeModal', (name) => { if (name === this.name) this.isActive = false; });
        },
        mounted() {
            // move element to root element
            if (! this.sticked) {
                document.getElementById('root').appendChild(this.$el);
            }
        },
        watch: {
            initModal() {
                this.isActive = this.initModal;
//                if (! this.isMobile) {
                    let modalCount = this.modalTotalCount;
                    if (this.isActive) {
                        modalCount++;
//                        disableScroll();
                    } else {
                        if (modalCount > 0)
                            modalCount--;
//                        enableScroll();
                    }

                    this.$store.commit('updateModalTotalCount', modalCount);
                    this.modalCurrentCount = modalCount;

                    if (modalCount == 0) {
                        enableScroll();
                    } else {
                        disableScroll();
                    }
//                }
            }
        }
    }
</script>