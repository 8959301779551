import axios from 'axios';

export class Errors {
    /**
     * Create a new Errors instance.
     */
    constructor(fields) {
        this.errors = {};
        for (let field in fields) {
            this.errors[field] = [];
        }
    }

    /**
     * Determine if an errors exists for the given field.
     *
     * @param {string} field
     */
    has(field) {
        // return this.errors.hasOwnProperty(field);
        if (this.errors[field])
            return this.errors[field].length > 0;

        return false;
    }


    /**
     * Determine if we have any errors.
     */
    any() {
        let count = 0;
        for (let field in this.errors) {
            count += this.errors[field].length;
        }
        return count > 0;
    }


    /**
     * Retrieve the error message for a field.
     *
     * @param {string} field
     */
    get(field) {
        if (this.errors[field]) {
            return this.errors[field][0];
        }
    }

    /**
     *
     * @param {string} field
     * @param {string} message
     */
    add(field, message) {
        // if (this.errors[field]) {
            this.errors[field].push(message);
        // }
    }


    /**
     * Record the new errors.
     *
     * @param {object} errors
     */
    record(errors) {
        this.clear();
        for (let field in errors) {
            this.errors[field] = errors[field];
        }
    }


    /**
     * Clear one or all error fields.
     *
     * @param {string|null} field
     */
    clear(field = null) {
        if (field) {
            this.errors[field] = [];

            return;
        }

        for (let field in this.errors) {
            this.errors[field] = [];
        }
    }
}


export class ActiveForm {
    /**
     * Create a new Form instance.
     *
     * @param {object} fields
     */
    constructor(fields) {
        if (! fields) throw new Error('Empty form fields');

        this.originalData = fields;

        // vue-validator (default without validator)
        this.validator = null;

        for (let field in fields) {
            this[field] = fields[field];
        }

        this.errors = new Errors(fields);

        this.exception = null;
    }

    /**
     * Add vue-validator to form
     *
     * @param {vue-validate} validator
     */
    addValidator(validator) {
        this.validator = validator;
    }

    /**
     * Checks for exception
     *
     * @returns {boolean}
     */
    hasException() {
        return Boolean(this.exception);
    }

    /*
     *  Get CSRF param name
     */
    getCsrfParam() {
        return document.querySelector('meta[name="csrf-param"]').content;
    }

    /*
     * Get CSRF token value
     */
    getCsrfToken() {
        return document.querySelector('meta[name="csrf-token"]').content;
    }

    /**
     * Add field to originalData
     * @param {string} name
     * @param {string} value
     */
    addField(name, value = '') {
        if (! this.originalData.hasOwnProperty(name)) {
            this.originalData[name] = value;
        }
    }

    /**
     * Remove field from originalData
     * @param {string} name
     */
    removeField(name) {
        if (this.originalData.hasOwnProperty(name)) {
            delete this.originalData[name];
        }
    }

    /*
     * Get data for form send
     */
    data() {
        let data = new FormData();

        for (let property in this.originalData) {
            if (this[property] === null) {
                data.append(property, '');
            } else if (this[property] instanceof FileList) {
                const count = this[property].length;
                for (let i = 0; i < count; i++) {
                    // // let file = this[property].item(i);
                    let file = this[property][i];
                    data.append(property + '[' + i + ']', file, file.name);
                }
            } else if (Array.isArray(this[property])) {
                this[property].forEach((value, index) => data.append(property + '[' + index + ']', value));
            } else if (typeof this[property] === 'boolean') {
                data.append(property, Number(this[property]));
            } else if (typeof this[property] === 'object') {
                data.append(property, JSON.stringify(this[property]));
            } else {
                data.append(property, this[property]);
            }
        }

        return data;
    }

    params() {
        let data = {};
        for (let property in this.originalData) {
            if (typeof this[property] === 'boolean') {
                data[property] = Number(this[property]);
            } else if (this[property] === null) {
                data[property] = '';
            } else {
                data[property] = this[property];
            }
        }
        return data;
    }

    /*
     * Form restore initial values
     */
    restore() {
        for (let property in this.originalData) {
            this[property] = this.originalData[property];
        }
    }

    /*
     * Form reset
     */
    reset() {
        for (let property in this.originalData) {
            this[property] = '';
        }
    }

    /**
     * Send a POST request to the given URL.
     * .
     * @param {string} url
     */
    post(url) {
        return this.submit('POST', url);
    }

    /**
     * Send a GET request to the given URL.
     * .
     * @param {string} url
     */
    get(url) {
        return this.submit('GET', url);
    }


    /**
     * Submit the form.
     *
     * @param {string} requestType
     * @param {string} url
     * @param {object} userConfig
     */
    submit(requestType, url, userConfig = {}) {
        return new Promise((resolve, reject) => {
            let config = Object.assign({}, {
                method: requestType,
                url: url,
                headers: {
                    'X-REQUESTED-WITH': 'XMLHttpRequest'
                }
            }, userConfig);

            if (requestType == 'GET') {
                config = Object.assign({}, config, {params: this.params()});
            } else {
                config = Object.assign({}, config, {
                    data: this.data(),
                    headers: {
                        'X-CSRF-TOKEN': this.getCsrfToken(),
                        'content-type': 'multipart/form-data'
                    }
                });
            }

            this.exception = null;
            axios(config)
                .then(response => {
                    this.onSuccess(response.data);
                    this.exception = response.data.exception;

                    resolve(response.data);
                })
                .catch(error => {
                    this.onFail(error.response.data);
                    this.exception = response.data.exception;

                    reject(error.response.data);
                });
        });

    }

    /**
     * Success callback for form send
     *
     * @param {object} response
     */
    onSuccess(response) {
        if (! response.success) {
            if (this.validator) {
                let errorBag = this.validator.getErrors();
                for (let field in response.errors) {
                    response.errors[field].forEach(item => {
                        errorBag.add(field, item);
                    });
                }
            } else {
                this.errors.record(response.errors);
            }
        } else {
            // this.reset();
        }
    }

    /**
     * Fail callback for form send
     *
     * @param {object} data
     */
    onFail(data) {

    }
}