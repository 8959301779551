<template>
    <div class="order-item">
        <div class="order-panel" @click="toggleInfo">
            <div class="id">
                <span class="label">Заказ:</span>
                <span class="value" v-text="order.id"></span>
            </div>
            <div class="date">
                <span class="label">Дата:</span>
                <span class="value" v-text="order.createdAt"></span>
            </div>
            <div class="total">
                <span class="label">Всего:</span>
                <span class="value"><b v-text="order.total"></b> грн</span>
            </div>
            <div class="paid">
                <span class="label">Оплата:</span>
                <span class="value" :class="{ 'is-paid': order.paid }"><span v-if="order.payStatus">{{ order.paid ? 'Оплачено' : 'Ожидание оплаты' }}</span></span>
            </div>
            <div class="completed">
                <span class="label">Статус:</span>
                <template v-if="order.canceled">
                    <span class="value" :class="{ 'is-complete': false }">Отменен</span>
                </template>
                <template v-else>
                    <span class="value" :class="{ 'is-complete': order.completed }">{{ order.completed ? 'Выполнен' : 'Не выполнен' }}</span>
                </template>
            </div>
            <div class="details">
                <div class="icon-wrapper">
                    <span>подробнее</span>
                    <i class="icon" :class="{ 'icon-arrow-down': ! isActive, 'icon-arrow-up': isActive }"></i>
                </div>
            </div>
        </div>
        <div class="order-info" v-if="isActive">
            <div class="delivery">
                <header>Информация по доставке</header>
                <div class="grid">
                    <div class="row">
                        <div class="icon-wrapper delivery-type">
                            <i class="icon icon-profile-delivery-type"></i>
                            <span v-text="order.delivery.type"></span>
                        </div>
                        <div class="icon-wrapper delivery-value">
                            <i class="icon icon-profile-delivery-value"></i>
                            <span v-text="order.delivery.value"></span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="icon-wrapper manager-info" v-if="order.manager">
                            <i class="icon icon-profile-manager"></i>
                            <span>Ваш менеджер: <span v-text="order.manager"></span></span>
                        </div>
                        <!--<div class="icon-wrapper manager-message">-->
                            <!--<i class="icon icon-profile-manager-message"></i>-->
                            <!--<span><a href="#">НАПИСАТЬ МЕНЕДЖЕРУ</a></span>-->
                        <!--</div>-->
                    </div>
                </div>
            </div>
            <div class="order-statuses">
                <div class="order-status" :class="{ 'is-completed': status.isComplete }" v-for="status in activeStatusList">
                    <div class="inner">
                        <div class="icon-container">
                            <i class="icon icon-order-checked" v-if="status.isComplete"></i>
                            <i :class="'status-icon icon icon-order-status-' + status.code"></i>
                        </div>
                        <div class="date" v-if="status.isComplete">{{ status.createdAt }}</div>
                        <div class="name">
                            {{ status.name }}
                            <b v-if="status.text">{{ status.text }}</b>
                        </div>
                    </div>
                </div>
            </div>
            <div class="cart-items table-view">
                <table v-if="! isMobile">
                    <thead>
                    <tr>
                        <th>Товар</th>
                        <th>Цена</th>
                        <th>Количество</th>
                        <th>Сумма</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="cart-item" v-for="cartItem in order.cartItems">
                        <td>
                            <div class="grid">
                                <div class="row image"><img :src="cartItem.image.preview" alt=""></div>
                                <div class="row"><a :href="cartItem.url" v-text="cartItem.name"></a></div>
                            </div>
                        </td>
                        <td>{{ cartItem.price | currency }}</td>
                        <td>{{ cartItem.quantity }} шт</td>
                        <td>{{ cartItem.total | currency }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="cart-items grid-view">
                <header>Товар</header>
                <div class="cart-item" v-for="cartItem in order.cartItems">
                    <div class="info">
                        <div class="row image"><img :src="cartItem.image.preview" alt=""></div>
                        <div class="row name"><a :href="cartItem.url" v-text="cartItem.name"></a></div>
                    </div>
                    <div class="price">
                        <div class="row">
                            {{ cartItem.quantity }} шт <span>x</span> {{ cartItem.price | currency }}
                        </div>
                        <div class="row"><b>Сумма:</b> {{ cartItem.total | currency }}</div>
                    </div>
                    <div class="separator">&nbsp;</div>
                </div>
            </div>
            <div class="order-rating" :class="{ 'is-active': activeRating }">
                <div class="grid">
                    <div class="row"><b>Оцените заказ:</b></div>
                    <div class="row rating-item" :class="[{ 'is-active': type.value == currentRating }, type.code]" v-for="type,index in ratingTypes" @click="setRating(type.value)">
                        <div class="text-center"><i :class="'icon icon-order-rating-' + type.code"></i></div>
                        <span v-text="type.label"></span>
                    </div>
                </div>
                <div class="success-message" v-if="isRateSuccess">СПАСИБО ЗА ОЦЕНКУ!</div>
                <div class="rating-comment" v-if="canRate && activeRating">
                    <textarea name="comment" v-model="ratingModel.comment" class="form-control" rows="1" placeholder="Ваш комментарий к оценке" v-expandable></textarea>
                    <button type="button" class="btn btn-primary" @click="handleRate(order)">ПОДТВЕРДИТЬ ОЦЕНКУ</button>
                </div>
                <div class="rating-comment-content" v-else-if="hasRateComment">
                    <p><b>Ваш отзыв:</b></p>
                    <p v-text="order.ratingComment"></p>
                </div>
            </div>
            <div class="actions-container">
                <div class="grid">
                    <div class="gifts">
                        <template v-if="hasGifts">
                            <p><b>Подарок</b></p>
                            <table>
                                <tbody>
                                <tr v-for="cartGift in order.cartGifts">
                                    <td v-text="cartGift.name"></td>
                                    <td class="quantity">{{ cartGift.total | currency }}</td>
                                    <td>{{ cartGift.quantity }} шт</td>
                                </tr>
                                </tbody>
                            </table>
                        </template>
                        <div class="bonus-container">
                            <div class="icon-wrapper">
                                <span><b>Бонусов: </b> <span>+{{ order.bonus }}</span></span>
                                <i class="icon icon-bonus-mini"></i>
                            </div>
                        </div>
                    </div>
                    <div class="actions" v-if="order.canPaid || order.canCancel">
                        <template v-if="order.canPaid">
                            <template v-if="isMultiPayment">
                                <div class="pay-container popup-container"  v-on-clickaway="hidePopup">
                                    <a href="#" class="icon-wrapper" @click.prevent="showPopup">
                                        <i class="icon icon-profile-order-pay"></i>
                                        <span>ОПЛАТИТЬ ЗАКАЗ</span>
                                    </a>
                                    <popup :is-active="isActivePopup" :auto-center="true">
                                        <div class="payments">
                                            <div class="payment" v-for="form,key in order.payments.form">
                                                <a href="#" @click.prevent="sendForm(key)"><i class="icon" :class="'icon-' + key"></i></a>
                                                <div class="form" v-html="form" :ref="'form' + key"></div>
                                            </div>
                                        </div>
                                    </popup>
                                </div>
                            </template>
                            <template v-else>
                                <a href="#" class="icon-wrapper" @click.prevent="sendSingleForm">
                                    <i class="icon icon-profile-order-pay"></i>
                                    <span>ОПЛАТИТЬ ЗАКАЗ</span>
                                </a>
                                <div v-html="order.payments.form" ref="singleForm" v-show="false"></div>
                            </template>
                        </template>

                        <div class="cancel-container" v-if="order.canCancel">
                            <a href="#" class="icon-wrapper" @click.prevent="cancelOrder(order)">
                                <i class="icon icon-profile-order-cancel"></i>
                                <span>ОТМЕНИТЬ ЗАКАЗ</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/babel">
    import { ActiveForm } from '../entities/active_form';
    import Popup from '../components/popup.vue';
    import MobileDetect from '../mixins/mobile_detect';
    import isObject from 'lodash/isObject';

    export default {
        name: "ProfileOrderItem",
        components: {Popup},
        mixins: [ MobileDetect ],
        props: {
            order: Object,
            required: true,
        },
        data: () => ({
            isActive: false,
            isRateSuccess: false,
            ratingTypes: [
                {label: 'Неприемлимо', code: 'unacceptable', value: 1},
                {label: 'Плохо', code: 'bad', value: 2},
                {label: 'Нормально', code: 'normal', value: 3},
                {label: 'Хорошо', code: 'good', value: 4},
                {label: 'Отлично', code: 'excellent', value: 5}
            ],
            ratingModel: new ActiveForm({
                orderId: null,
                rating: 0,
                comment: '',
            }),
            isActivePopup: false,
        }),
        computed: {
            activeRating() {
                return this.ratingModel.rating > 0 || this.order.rating > 0
            },
            currentRating() {
                return this.canRate ? this.ratingModel.rating : this.order.rating;
            },
            canRate() {
                return this.order.rating == 0;
            },
            hasRateComment() {
                return this.order.ratingComment;
            },
            hasGifts() {
                return this.order.cartGifts.length > 0;
            },
            isMultiPayment() {
                return isObject(this.order.payments.form);
            },
            activeStatusList() {
                if (this.isMobile || this.order.canceled) {
                    return this.order.statusList.filter(item => item.isComplete);
                }
                return this.order.statusList;
            }
        },
        methods: {
            toggleInfo() {
                this.isActive = ! this.isActive;
            },
            setRating(rating) {
                if (this.canRate) {
                    this.ratingModel.rating = rating;
                }
            },
            handleRate(order) {
                this.ratingModel.orderId = order.id;
                this.ratingModel.post('/profile/orders/rate/')
                    .then(r => {
                        if (r.success) {
                            order.rating = this.ratingModel.rating;
                            order.ratingComment = this.ratingModel.comment;
                            this.isRateSuccess = true;
                        } else {
                            alert('Произошла ошибка');
                        }
                    })
            },
            showPopup() {
                this.isActivePopup = true;
            },
            hidePopup() {
                this.isActivePopup = false;
            },
            sendForm(key) {
                const formName = 'form' + key;
                const form = this.$refs[formName][0].getElementsByTagName('form')[0];
                form.submit();
            },
            sendSingleForm() {
                const form = this.$refs.singleForm.getElementsByTagName('form')[0];
                form.submit();
            },
            cancelOrder(order) {
                if (confirm('Вы уверены что хотите отменить заказ')) {
                    let query = new ActiveForm({
                        order_id: this.order.id
                    });

                    query.post('/profile/orders/cancel/')
                        .then(r => {
                            if (r.success) {
                                order.canPaid = false;
                                order.canCancel = false;
                                order.canceled = true;
                            }
                        });
                }
            }
        },
        mounted() {

        },
        created() {
            
        },
    }
</script>