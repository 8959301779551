<template>
    <div class="profile-container orders-container">
        <header>СПИСОК ЗАКАЗОВ</header>

        <div class="filters" v-if="! isPhone">
            <a :href="filter.url" @click.prevent="applyFilter(filter.url)" v-for="filter in filters">{{ filter.label }}</a>
        </div>
        <div class="filters-select" v-else>
            <div class="form-group">
                <custom-select :values="filterList" v-model="currentFilter" @change="applyFilter" class="form-control"></custom-select>
            </div>
        </div>

        <div class="category-loader" v-if="loading">
            <loader :loading="loading"></loader>
        </div>
        <div v-else-if="errorMessage">
            <h4>Ой йо йой!</h4>
            <p>Возникла проблема :( Если можете сообщите нам ошибку, которая написана ниже:</p>
            <code><pre v-text="errorMessage"></pre></code>
        </div>

        <div class="orders-list">
            <profile-order-item :key="order.id" :order="order" v-for="order in orders"></profile-order-item>
        </div>

        <pagination :pages="pages" :page="page" :support-state="true" ref="pagination"></pagination>

        <div class="empty-orders text-center" v-if="orders.length == 0 && ! loading">
            Заказы не найдены
        </div>
    </div>
</template>

<script type="text/babel">
    import { ActiveForm } from '../entities/active_form';
    import ProfileOrderItem from './order_item.vue';
    import Pagination from '../components/pagination.vue';
    import MobileDetect from '../mixins/mobile_detect';
    import CustomCheckbox from "../components/custom_checkbox.vue";

    export default {
        name: "ProfileOrders",
        mixins: [ MobileDetect ],
        components: {
            CustomCheckbox,
            ProfileOrderItem,
            Pagination,
        },
        props: {
            filters: Array
        },
        data: () => ({
            loading: false,
            orders: [],
            pages: [],
            page: 0,
            pageSize: 0,
            pageCount: 0,
            totalCount: 0,
            errorMessage: '',
            currentFilter: '/profile/orders/',
        }),
        computed: {
            filterList() {
                return this.filters.map(item => ({
                    value: item.url,
                    text: item.label
                }));
            }
        },
        methods: {
            getData(url = window.location.href) {
                // clear error message
                this.errorMessage = '';

                this.loading = true;

                let form = new ActiveForm({});
                form.post(url)
                    .then(response => {
                        this.orders = response.orders;

                        this.pages = response.pages;
                        this.page = response.page;
                        this.pageSize = response.pageSize;
                        this.pageCount = response.pageCount;
                        this.totalCount = response.totalCount;

                        this.loading = false;
                    })
                    .catch(e => {
                        this.errorMessage = e;
                        this.loading = false;
                    });
            },
            applyFilter(url) {
                history.pushState(null, document.title, url);
                Event.$emit('pushState', {url: url});
            }
        },
        mounted() {

        },
        created() {
            this.getData();

            Event.$on('pushState', (event) => {
                this.getData(event.url);
            });

            window.addEventListener('popstate', (event) => {
                this.getData(document.location.href);
            }, false);
        },
    }
</script>