<template>
    <div v-on-clickaway="hidePopup">
        <form action="/search/query/" method="post" ref="form" @submit="checkSubmit">
            <input type="text" name="q" class="search-input" placeholder="Поиск среди 10 000 наименований товаров" autocomplete="off" v-model.trim="model.searchText" @click.prevent="showPopup">
            <input type="submit" value="" class="search-submit">
        </form>

        <popup :is-active="showSearchResults" class="popup-search-results">
            <loader :loading="loading"></loader>
            <div class="search-list">
                <div class="search-list__categories" v-if="!!brands.length || !!lines.length">
                    <template v-if="!!brands.length">
                        <div class="search-list__title">Бренды:</div>
                        <div class="search-list__category-item" v-for="brand in brands">
                            <a :href="brand.url"><img :src="brand.image" alt="" class="search-list__brand-logo"></a>
                        </div>
                    </template>

                    <template v-if="!!lines.length">
                        <div class="search-list__title">Линии:</div>
                        <div class="search-list__category-item" v-for="line in lines">
                            <a :href="line.url">{{ line.name | decode }}</a>
                        </div>
                    </template>
                </div>
                <div class="search-list__products" v-if="!!products.length">
                    <div class="search-list__title">Товары:</div>
                    <div class="search-list__product-item" v-for="product in products">
                        <a :href="product.url">
                            <div class="grid">
                                <div class="row search-list__product-image">
                                    <img :src="product.image" alt="">
                                </div>
                                <div class="row search-list__product-names">
                                    <p class="search-list__product-name">{{ product.name | decode }}</p>
                                    <p class="search-list__product-name-alias">{{ product.name_alias | decode }}</p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <!--<a :href="result.link" class="search-result-item grid" v-for="result in searchResults">-->
                <!--<div class="row image"><img :src="result.image" alt=""></div>-->
                <!--<div class="row content">-->
                    <!--<p v-html="result.name"></p>-->
                    <!--<p class="second-name" v-if="result.secondName">{{ result.secondName | uppercase }}</p>-->
                <!--</div>-->
            <!--</a>-->
            <p v-if="notFound">Ничего не найдено</p>
            <!--<button class="btn btn-primary btn-block" @click="submitForm" v-show="! loading">ПОЛНЫЙ ПОИСК</button>-->
        </popup>
    </div>
</template>

<script type="text/babel">
    import Popup from '../components/popup.vue';
    import _debounce from 'lodash/debounce';
    import { ActiveForm } from '../entities/active_form';

    export default {
        name: "Search",
        components: {
            Popup
        },
        data() {
            return {
                brands: [],
                lines: [],
                products: [],
                model: new ActiveForm({
                    searchText: '',
                }),
                loading: false,
                showSearchResults: false,
            };
        },
        computed: {
            notFound() {
                let itemsLength = this.brands.length + this.lines.length + this.products.length;
                return ! this.loading && itemsLength == 0 && this.model.searchText.length > 0;
            },
        },
        methods: {
            handleHint: _debounce(function() {
                if (this.model.searchText.length > 0) {
                    this.showSearchResults = true;
                    this.loading = true;
                    this.brands = [];
                    this.lines = [];
                    this.products = [];
                    let vm = this;

                    this.model.post(vm.link)
                        .then(r => {
                            if (r.hasOwnProperty('redirect')) {
                                vm.showSearchResults = false;
                                window.location.href = r.redirect;
                            } else {
                                vm.brands = r.brands;
                                vm.lines = r.lines;
                                vm.products = r.products;

                            }
                            vm.loading = false;
                        })
                }
            }, 500),
            showPopup() {
                this.showSearchResults = (this.model.searchText.length > 0);
            },
            hidePopup() {
                this.showSearchResults = false;
            },
            submitForm() {
                this.$refs.form.submit();
            },
            checkSubmit(e) {
                if (this.model.searchText.length == 0) {
                    e.preventDefault();
                }
            }
        },
        watch: {
            'model.searchText': function() {
                this.handleHint();
            }
        },
        props: {
            link: { required: true, type: String }
        },
        mounted() {
            this.loading = true;
        },
        created() {

        }
    }
</script>