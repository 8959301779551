<template>
    <form action="" @submit.prevent="onSubmit" @keydown="model.errors.clear($event.target.name)">
        <div class="loader-wrapper" :class="{'is-loading': deliveryFieldsLoading}">
            <loader :loading="deliveryFieldsLoading"></loader>
        </div>
        <div class="grid center delivery-step-content">
            <div class="row">
                <div class="form-group" v-show="hasDeliveryProfiles">
                    <label>Профиль доставки:</label>
                    <custom-select :values="profileListCustom" v-model="model.deliveryProfileId"
                                   @change="getProfileData"
                                   class="form-control" :class="{'completed' : model.deliveryProfileId != ''}" @keydown="model.errors.clear('deliveryProfileId')"></custom-select>
                    <p class="help-block-error" v-if="model.errors.has('deliveryProfileId')" v-text="model.errors.get('deliveryProfileId')"></p>
                </div>
                <template v-if="canShowDeliveryBasicParams">
                    <div class="form-group">
                        <label>Город:</label>
                        <custom-select :values="cityList" :autocomplete="true" @autocomplete="setCityName"
                                       v-model="model.cityId" class="form-control" :class="{'completed' : model.cityId != ''}"
                                       v-on:keydown="model.errors.clear('cityId')"
                                       @change="getDeliveryList" v-on:change="triggerDeliveryChange" ref="citySelect"></custom-select>
                        <p class="help-block-error" v-if="model.errors.has('cityId')" v-text="model.errors.get('cityId')"></p>
                    </div>
                    <div class="form-group">
                        <label>Способ доставки:</label>
                        <custom-select :values="deliveryList" v-model="model.deliveryId"
                                       class="form-control" :class="{'completed' : model.deliveryId != ''}" @keydown="model.errors.clear('deliveryId')"
                                       v-on:keydown="getDeliveryFields" @change="getPaysystemList"
                                       :disabled="! model.cityId" ref="deliverySelect"></custom-select>
                        <p class="help-block-error" v-if="model.errors.has('deliveryId')" v-text="model.errors.get('deliveryId')"></p>
                    </div>
                    <div class="form-group">
                        <label>Способ оплаты:</label>
                        <custom-select :values="paysystemList" v-model="model.paysystemId"
                                       @change="updateTotals"
                                       class="form-control" :class="{'completed' : model.paysystemId != ''}" @keydown="model.errors.clear('paysystemId')"
                                       :disabled="! model.deliveryId" :auto-choose="true"></custom-select>
                        <p class="help-block-error" v-if="model.errors.has('paysystemId')" v-text="model.errors.get('paysystemId')"></p>
                        <div class="paysystem-description" v-html="paysystem.description" v-if="paysystem && paysystem.description && model.cityId != 417"></div>
                    </div>
                </template>
            </div>
            <div class="row" v-if="canShowDeliveryDynamicFields">
                <template v-for="field in deliveryFields">
                    <div class="form-group" :class="'field-' + field.name + '-container'" v-show="! field.hidden">
                        <label v-if="field.label">{{ field.label }}<span class="required" v-if="field.required">*</span>:</label>

                        <template v-if="field.type == 'text'">
                            <input type="text" :name="field.name" v-model="model[field.name]" class="form-control" :class="{'completed' : model[field.name].length > 0}" :placeholder="field.placeholder">
                        </template>
                        <template v-else-if="field.type == 'select'">
                            <template v-if="field.name == 'pickup_address'">
                                <custom-select :values="field.values" v-model="model[field.name]" class="form-control" :class="{'completed' : model[field.name] != ''}" :auto-choose="field.options.autoChoose" :placeholder="field.placeholder" @keydown="model.errors.clear(field.name)" @change="updateMap"></custom-select>
                                <div class="pickup-address-description" v-if="pickupAddressDescription">* {{ pickupAddressDescription }}</div>
                                <div id="pickup-map"></div>
                            </template>
                            <template v-else>
                                <custom-select :values="field.values" v-model="model[field.name]" class="form-control" :class="{'completed' : model[field.name] != ''}" :auto-choose="field.options.autoChoose" :live-search="field.options.liveSearch" :limit="field.options.limit" :placeholder="field.placeholder" @keydown="model.errors.clear(field.name)"></custom-select>
                            </template>
                        </template>

                        <p class="help-block-error" v-if="model.errors.has(field.name)" v-text="model.errors.get(field.name)"></p>
                    </div>
                </template>
                <div class="form-group"><a href="#" @click.prevent="showComment = true" class="link" v-if="! showComment">Комментарий к заказу</a></div>
                <div class="form-group" v-if="showComment">
                    <textarea v-model="model.deliveryComment" rows="5" class="form-control" :class="{'completed' : model.deliveryComment != ''}" placeholder="Комментарий"></textarea>
                </div>
            </div>
        </div>
        <div class="grid center" v-if="canNotCallback">
            <custom-checkbox v-model="model.notCallback" @change="model.errors.clear()">Можете мне не перезванивать</custom-checkbox>
        </div>
        <!--<div class="grid center actions">-->
            <!--<div class="row">-->
                <!--<button type="button" class="btn btn-block btn-link" @click="back">-->
                    <!--<span class="icon-wrapper">-->
                        <!--<i class="icon icon-order-back"></i>-->
                        <!--<span>Вернуться назад</span>-->
                    <!--</span>-->
                <!--</button>-->
            <!--</div>-->
            <!--<div class="row">-->
                <!--<div class="form-group">-->
                    <!--<button-loading class="btn btn-secondary btn-block continue" :loading="isLoading" :disabled="isLoading || model.errors.any()">ДАЛЕЕ</button-loading>-->
                <!--</div>-->
            <!--</div>-->
        <!--</div>-->

        <div class="grid center confirm">
            <div class="row">
                <div class="form-group actions">
                    <button-loading type="button" class="btn btn-secondary btn-block continue" :loading="isLoading" :disabled="isLoading || deliveryFieldsLoading" @click="onSubmit">ПОДТВЕРДИТЬ ЗАКАЗ</button-loading>
                    <button type="button" class="btn btn-block btn-link" @click="back">Вернуться назад</button>
                    <p class="offer-text">Подтверждая заказ Вы подтверждаете своё ознакомление с <a href="/oferta" target="_blank">Публичной Офертой</a> на сайте http://avocado.ua</p>
                </div>
            </div>
        </div>
    </form>
</template>

<script type="text/babel">
    import { ActiveForm } from '../entities/active_form';
    import _isEmpty from 'lodash/isEmpty';
    import _first from 'lodash/first';
    import OrderStep from '../mixins/order_step';
    import City from '../mixins/city';
    import { mapGetters } from 'vuex';
    import CustomCheckbox from '../components/custom_checkbox.vue';

    export default {
        name: "OrderDeliveryPayment",
        components: {
            CustomCheckbox,
        },
        mixins: [ OrderStep, City ],
        data() {
            return {
                showComment: false,
                cityId: '',
                profileList: [],
                deliveryList: [],
                paysystemList: [],
                deliveryFields: [],
                deliveryFieldsLoading: false,
                fieldMap: null,
                pickupAddressDescription: '',
            };
        },
        computed: {
            hasDeliveryProfiles() {
                return this.profileList.length > 0;
            },
            profileListCustom() {
                let addNewProfile = [
                    {
                        value: 0,
                        text: 'Добавить новый профиль'
                    }
                ];
                return this.profileList.concat(addNewProfile);
            },
            canShowDeliveryBasicParams() {
                return parseInt(this.model.deliveryProfileId) >= 0;
            },
            canShowDeliveryDynamicFields() {
                return this.deliveryFields.length > 0;
            },
            paysystem() {
                if (this.model.paysystemId) {
                    return this.paysystemList.find(paysystem => paysystem.value == this.model.paysystemId);
                }

                return false;
            },
            canNotCallback() {
                const validDelivery = [3, 5];
                const result = this.isLogged && validDelivery.indexOf(this.model.deliveryId) > -1;
                if (! result)
                    this.model.notCallback = false;

                return result;
            },
            ...mapGetters(['isLogged', 'city'])
        },
        methods: {
            addFieldsToModel(fields) {
                fields.forEach(f => {
                    this.model.addField(f.name);
                    this.$set(this.model, f.name, f.value);
                });
            },
            removeFieldsFromModel(fields) {
                fields.forEach(f => {
                    this.model.removeField(f.name);
                    this.$delete(this.model, f.name);
                });
            },
            getDeliveryList(autoChoose = false) {
                let query = new ActiveForm({
                    cityId: this.model.cityId,
                });
                return query.post('/order/api/delivery-list/')
                    .then(r => {
                        this.deliveryList = r;

                        const delivery = _first(r);
                        if (delivery && autoChoose) {
                            this.model.deliveryId = delivery.value;
                            this.getPaysystemList();
                            return this.getDeliveryFields();
                        }

                        return new Promise(resolve => resolve(r));
                    });
            },
            getDeliveryFields() {
                this.deliveryFieldsLoading = true;
                Event.$emit('updateOrderTotals');

                return this.model.post('/order/api/delivery/')
                    .then(r => {
                        this.removeFieldsFromModel(this.deliveryFields);
                        this.addFieldsToModel(r);
                        this.deliveryFields = r;

                        this.initExtraDelivery();
                        this.deliveryFieldsLoading = false;

                        return new Promise(resolve => resolve(r));
                    });
            },
            getPaysystemList() {
                let query = new ActiveForm({
                    deliveryId: this.model.deliveryId,
                });
                query.post('/order/api/paysystem-list/')
                    .then(r => this.paysystemList = r);
            },
            getProfileData(id) {
                this.loading = true;
                let query = new ActiveForm({
                    profileId: id
                });

                query.post('/order/api/delivery-profile-fields/')
                    .then(r => {
                        if (! _isEmpty(r)) {
                            this.cityValue = r.cityValue;
                            this.model.cityId = r.cityId;
                            // this.$refs.citySelect.$emit('change', r.cityId);
                            this.getDeliveryList()
                                .then(() => {
                                    this.model.deliveryId = r.deliveryId;
                                    this.getPaysystemList();
                                    return this.getDeliveryFields();
                                })
                                .then(() => {
                                    this.removeFieldsFromModel(this.deliveryFields);
                                    this.addFieldsToModel(r.fields);
                                    this.deliveryFields = r.fields;
                                    this.initExtraDelivery();
                                })
                        } else {
                            this.cityValue = {};
                            this.model.cityId = this.city.id;
                            this.$refs.citySelect.$emit('change', this.model.cityId);
                            // this.getDeliveryList();
                            this.model.deliveryId = -1;
                            this.$refs.deliverySelect.$emit('change', this.model.deliveryId);
//                            this.getPaysystemList();
                            this.removeFieldsFromModel(this.deliveryFields);
                            this.deliveryFields = [];
                            this.initExtraDelivery();
                        }

                        Event.$emit('updateOrderTotals');
                        this.loading = false;
                    });
            },
            updateTotals() {
                Event.$emit('updateOrderTotals');
            },
            triggerDeliveryChange() {
                this.$refs.deliverySelect.$emit('keydown');
            },

            // specific methods here
            initExtraDelivery() {
                // pickup delivery method
                if (this.model.deliveryId == 2) {
                    let pickup = this.initPickupDelivery();

                    if (this.model.hasOwnProperty('pickup_address')) {
                        if (this.model.pickup_address > 0) {
                            pickup.then(() => {
                                this.updateMap(this.model.pickup_address);
                            });
                        }
                    }
                }
            },
            initPickupDelivery() {
                return new Promise((resolve, reject) => {
                    setTimeout(() => {
                        this.pickupAddressDescription = '';
                        let kiev = {lat: 50.4168656, lng: 30.6285952};
                        this.fieldMap = new google.maps.Map(document.getElementById('pickup-map'), {
                            zoom: 17,
                            center: kiev
                        });

                        this.deliveryFields.forEach(field => {
                            if (field.name == 'pickup_address') {
                                field.values.forEach(value => {
                                    let marker = new google.maps.Marker({
                                        position: {lat: value.options.lat, lng: value.options.lng},
                                        map: this.fieldMap
                                    });
                                });
                            }
                        });

                        resolve();
                    }, 50)
                })
            },
            updateMap(value) {
                setTimeout(() => {
                    const field = this.deliveryFields.find(item => item.name == 'pickup_address');
                    if (field) {
                        const fieldValue = field.values.find(fieldValue => fieldValue.value == value);

                        this.pickupAddressDescription = fieldValue.options.description;
                        google.maps.event.trigger(this.fieldMap, "resize");
                        this.fieldMap.setCenter({lat: fieldValue.options.lat, lng: fieldValue.options.lng});
                    }
                }, 100);
            }
        },
        watch: {
            city(value) {
                this.cityValue = {
                    value: value.id,
                    text: value.name,
                    primary: 0,
                };

                // если при старте оформления заказа нет выбранного профиля то зададим первые значения в поля
                if (this.model.deliveryProfileId == 0) {
                    this.model.cityId = value.id;
                    this.getDeliveryList(true);
                }
            }
        },
        mounted() {
            if (this.isLogged) {
                // загружаем список профилей доставки
                this.axios.get('/order/api/delivery-profiles/')
                    .then(r => {
                        this.profileList = r.data;
                        if (! this.hasDeliveryProfiles) {
                            this.model.deliveryProfileId = 0;
                        } else {
                            const primaryProfile = this.profileList.find(profile => profile.primary);
                            if (primaryProfile)
                                this.model.deliveryProfileId = primaryProfile.value;
                                this.getProfileData(primaryProfile.value);
                        }
                    });
            } else {
                this.model.deliveryProfileId = 0;
            }

            // console.log(this.model.deliveryProfileId);
            // console.log(this.model.cityId);
            // console.log(this.cityValue);
            // console.log(this.city);
        }
    }
</script>