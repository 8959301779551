<template>
    <div>
        <div class="fix-wrapper">
            <div class="seo-text" :class="{ 'is-active': showFull }">
                <slot></slot>
            </div>
            <div class="overlay" v-if="! showFull"></div>
        </div>
        <div class="show-more-container"><a href="#" @click.prevent="handleShow" v-text="showText"></a></div>
    </div>
</template>

<script type="text/babel">
    export default {
        name: "SeoText",
        data() {
            return {
                showFull: false
            };
        },
        computed: {
            showText() {
                return ! this.showFull ? 'ПОКАЗАТЬ БОЛЬШЕ' : 'СКРЫТЬ';
            }
        },
        methods: {
            handleShow() {
                this.showFull = ! this.showFull;
            }
        }
    }
</script>