<template>
    <div :class="{'is-loading': data.length == 0 && disabled}">
        <filter-group v-for="filter in groupFilters" :filter-item="filter" :key="filter.id" :init-disabled="disabled"></filter-group>
        <filter-price v-for="filter in priceFilters" :filter-item="filter" :key="filter.id" :init-disabled="disabled"></filter-price>
    </div>
</template>

<script type="text/babel">
    import FilterGroup from './filter_group.vue';
    import FilterPrice from './filter_price.vue';

    export default {
        name: "CategoryFilter",
        components: {
            FilterPrice,
            FilterGroup
        },
        props: ['data', 'initDisabled'],
        computed: {
            groupFilters() {
                return this.data.filter(item => item.id != 'p');
            },
            priceFilters() {
                return this.data.filter(item => item.id == 'p');
            },
            disabled() {
                return this.initDisabled;
            },
        }
    }
</script>