import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        city: {
            id: null,
            name: '...'
        },
        cityPrimaryList: [],
        modalTotalCount: 0,
        isLogged: false,
        user: {
            firstname: null,
            lastname: null,
        }
    },
    getters: {
        city(state) {
            return state.city;
        },
        cityValue(state) {
            return {
                value: state.city.id,
                text: state.city.name,
            };
        },
        getCityPrimaryList(state) {
            return state.cityPrimaryList;
        },
        getCityPrimaryListFormatted(state) {
            return state.cityPrimaryList.map((item) => ({
                text: item.name,
                value: item.id
            }));
        },
        modalTotalCount(state) {
            return state.modalTotalCount;
        },
        isLogged(state) {
            return state.isLogged;
        },
        user(state) {
            return state.user;
        }
    },
    mutations: {
        setCity(state, city) {
            state.city = city;
        },
        setCityPrimaryList(state, list) {
            state.cityPrimaryList = list;
        },
        updateModalTotalCount(state, value) {
            state.modalTotalCount = value;
        },
        setLogged(state, value) {
            state.isLogged = value;
        },
        setUser(state, value) {
            state.user = value;
        }
    },
    actions: {
        setCity({ commit }, city) {
            commit('setCity', city);
        },
        setCityPrimaryList({ commit }, list) {
            commit('setCityPrimaryList', list);
        }
    }
});