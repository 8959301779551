<template>
    <div>
        <modal class="modal-lottery" :init-modal="isActive" @close="dismiss">
            <div class="result" :class="{ 'result--success': youAreLucky }" v-if="completed">
                <div>
                    <template v-if="youAreLucky">
                        <p>Поздравляем! Сегодня Ваш день!</p>
                        <br>
                        <p>На почту полетела скидочка!</p>
                    </template>
                    <template v-else>
                        <p>К сожалению Вам не повезло!</p>
                        <p>Но не расстраивайтесь!</p>
                        <p>То, что Вы зашли в наш магазин и вскоре станете нашим клиентом - это уже победа ;)</p>
                    </template>
                </div>
            </div>
            <template v-else>
                <div class="roulette">
                    <img :src="'/build/images/roulette.png' | static" class="roulette__spinner" ref="spinner">
                    <img :src="'/build/images/pointer.png' | static" class="roulette__pointer">
                </div>
                <div class="lottery-form">
                    <p class="lottery-form__title">Мгновенная лотерея ;)</p>
                    <p class="lottery-form__title-mini">Получите скидку на покупку</p>

                    <form @submit.prevent="play" @keydown="model.errors.clear($event.target.name)" v-if="!success">
                        <div class="form-group">
                            <input type="text" name="email" v-model="model.email" class="form-control" placeholder="Email">
                            <p class="help-block-error" v-if="model.errors.has('email')" v-text="model.errors.get('email')"></p>
                            <p class="help-block-error" v-if="model.hasException()" v-text="model.exception"></p>
                        </div>

                        <div class="form-group">
                            <button-loading :loading="loading" class="btn btn-primary btn-block">ВРАЩАТЬ БАРАБАН</button-loading>
                        </div>
                    </form>
                </div>
            </template>
        </modal>
    </div>
</template>

<script>
    import { ActiveForm } from '../entities/active_form';
    import Velocity from 'velocity-animate';

    export default {
        name: "Lottery",
        props: {
            initActive: {
                type: Boolean,
                default: false,
            }
        },
        data() {
            return {
                completed: false,
                success: false,
                loading: false,
                youAreLucky: false,
                model: new ActiveForm({
                    email: ''
                }),
            };
        },
        computed: {
            isActive() {
                return this.initActive;
            }
        },
        methods: {
            play() {
                this.loading = true;
                this.model.post('/trigger/lottery/')
                    .then(r => {
                        if (r.success) {
                            this.success = r.success;
                            Velocity(this.$refs.spinner,
                                { rotateZ: '+=' + r.rotateDeg },
                                {
                                    easing: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
                                    duration: 2000,
                                    complete: () => {
                                        setTimeout(() => {
                                            this.completed = r.success;
                                            this.youAreLucky = r.lucky;

                                            this.$emit('complete');
                                        }, 1000);
                                    }
                                }
                            );
                        }
                        this.loading = false;
                    })
                    .catch(e => {
                        console.log(e);
                        this.loading = false;
                    })
            },
            dismiss() {
                this.$emit('dismiss');
            }
        }
    }
</script>