<template>
    <div class="profile-container wishlist-container">
        <header>СПИСОК ЖЕЛАНИЙ</header>
        <div class="grid actions">
            <div class="row">
                <custom-checkbox @input="handleChangeMarkAll" :value="isCheckedAll"> ВЫБРАТЬ ВСЕ</custom-checkbox>
            </div>
            <div class="row askbuy">
                <a href="#" class="icon-wrapper" @click.prevent="showModal" :class="{ 'is-disabled': ! activeActions }">
                    <i class="icon icon-profile-wishlist-askbuy"></i>
                    <span>ПОПРОСИТЬ В ПОДАРОК</span>
                    <i class="icon icon-info" v-tooltip.top="'Отправить список желаемых товаров своим родным и близким с просьбой о приобретении'"></i>
                </a>
            </div>
            <div class="row remove">
                <a href="#" class="icon-wrapper" @click.prevent="removeAll" :class="{ 'is-disabled': ! activeActions }">
                    <i class="icon icon-profile-wishlist-remove"></i>
                    <span>УДАЛИТЬ</span>
                </a>
            </div>
        </div>
        <div class="category-loader" v-if="loading">
            <loader :loading="loading"></loader>
        </div>
        <div class="offers-list-container">
            <div class="offers-list">
                <offer-item :offer="offer" :key="offer.id" v-for="offer in activeOffers" @changeMark=""></offer-item>
            </div>
        </div>

        <modal class="modal-ask-buy" :init-modal="isModalActive" title="ПОПРОСИТЬ КУПИТЬ" @close="hideModal">
            <form action="" @submit.prevent="handleAskbuySubmit" @keydown="askbuyForm.errors.clear($event.target.name)" v-if="! isAskComplete">
                <div class="form-group">
                    <label for="email">Ваше имя:</label>
                    <input type="text" id="firstname" name="firstname" v-model="askbuyForm.firstname" class="form-control" placeholder="Введите имя">
                    <p class="help-block-error" v-if="askbuyForm.errors.has('firstname')" v-text="askbuyForm.errors.get('firstname')"></p>
                </div>
                <div class="form-group">
                    <label for="email">Email получателя:</label>
                    <input type="text" id="email" name="email" v-model="askbuyForm.email" class="form-control" placeholder="Введите email">
                    <p class="help-block-error" v-if="askbuyForm.errors.has('email')" v-text="askbuyForm.errors.get('email')"></p>
                </div>
                <div class="form-group">
                    <label for="comment">Ваш комментарий:</label>
                    <textarea name="comment" id="comment" v-model="askbuyForm.comment" class="form-control" placeholder="Введите комментарий" cols="30" rows="10"></textarea>
                    <p class="help-block-error" v-if="askbuyForm.errors.has('comment')" v-text="askbuyForm.errors.get('comment')"></p>
                </div>
                <div><button-loading :loading="isAskLoading" :disabled="isAskLoading || askbuyForm.errors.any()" class="btn btn-primary btn-block">ОТПРАВИТЬ</button-loading></div>
            </form>
            <div class="text-center" v-else>Ваша просьба была отправлена адресату</div>
        </modal>
    </div>
</template>

<script type="text/babel">
    import CustomCheckbox from '../components/custom_checkbox.vue';
    import OfferItem from '../catalog/offer_item.vue';
    import { ActiveForm } from '../entities/active_form';

    export default {
        components: {
            OfferItem,
            CustomCheckbox,
        },
        name: "ProfileWishlist",
        props: {},
        data: () => ({
            loading: false,
            offers: [],
            isModalActive: false,
            isAskComplete: false,
            isAskLoading: false,
            askbuyForm: new ActiveForm({
                firstname: '',
                email: '',
                comment: '',
                offers: [],
            })
        }),
        computed: {
            activeOffers() {
                return this.offers.filter(item => ! item.isRemoved);
            },
            checkedOffers() {
                return this.activeOffers.filter(item => item.isMark);
            },
            activeActions() {
                return this.checkedOffers.length > 0;
            },
            isCheckedAll() {
                const checkedCount = this.checkedOffers.length;
                const activeCount = this.activeOffers.length
                return (checkedCount > 0 && checkedCount == activeCount);
            }
        },
        methods: {
            showModal() {
                if (this.activeActions) {
                    this.isModalActive = true;
                }
            },
            hideModal() {
                this.isModalActive = false;
            },
            handleAskbuySubmit() {
                this.isAskLoading = true;
                this.askbuyForm.offers = this.checkedOffers.map(offer => offer.id);
                this.askbuyForm.post('/profile/wishlist/ask-buy/')
                    .then(r => {
                        this.isAskComplete = r.success;
                        this.handleChangeMarkAll(false);
                        this.isAskLoading = false;
                    })
                    .catch(e => {
                        console.log(e);
                        this.isAskLoading = false;
                    })
            },
            handleChangeMarkAll(value) {
                this.activeOffers.forEach(item => item.isMark = value);
            },
            removeAll() {
                if (confirm('Вы уверенны что хотите удалить все товары из избранного?')) {
                    let ids = this.checkedOffers.map(item => item.id);
                    let query = new ActiveForm({
                        offer_id: ids
                    });

                    query.post('/profile/wishlist/remove/')
                        .then(r => {
                            if (r.success) {
                                this.checkedOffers.forEach(item => item.isRemoved = true);
                                this.handleChangeMarkAll(false);
                            }
                        });
                }
            }
        },
        mounted() {
            this.loading = true;
            this.axios.get(window.location.href)
                .then(r => {
                    this.offers = r.data.map(item => {
                        item.isRemoved = false;
                        return item;
                    });
                    this.loading = false;
                });
        },
        created() {
            
        },
    }
</script>