<template>
    <div>
        <div class="filters">
            <div class="form-group" v-for="filter in filters">
                <custom-select :values="filter.values" :selected="filter.value"  @change="applyFilter" class="form-control"></custom-select>
            </div>
        </div>

        <div class="category-loader" v-if="loading">
            <loader :loading="loading"></loader>
        </div>
        <div v-else-if="errorMessage">
            <h4>Ой йо йой!</h4>
            <p>Возникла проблема :( Если можете сообщите нам ошибку, которая написана ниже:</p>
            <code><pre v-text="errorMessage"></pre></code>
        </div>
        <div v-else-if="stocks.length == 0">Акции не найдены</div>

        <div class="stock-list-container">
            <stock-item :stock="stock" :key="stock.id" v-for="stock in stocks"></stock-item>
        </div>

        <pagination :pages="pages" :page="page" :support-state="true" ref="pagination"></pagination>
    </div>
</template>

<script type="text/babel">
    import { ActiveForm } from '../entities/active_form';
    import Pagination from '../components/pagination.vue';
    import MobileDetect from '../mixins/mobile_detect';
    import StockItem from './stock_item.vue';

    export default {
        name: "Stock",
        mixins: [ MobileDetect ],
        components: {
            StockItem,
            Pagination,
        },
        props: {
        },
        data: () => ({
            loading: false,
            errorMessage: '',
            filters: [],
            stocks: [],
            pages: [],
            page: 0,
            pageSize: 0,
            pageCount: 0,
            totalCount: 0,
        }),
        computed: {},
        methods: {
            getData(url = window.location.href) {
                // clear error message
                this.errorMessage = '';

                this.loading = true;

                let form = new ActiveForm({});
                form.post(url)
                    .then(response => {
                        this.stocks = response.stocks;
                        this.filters = response.filters;

                        this.pages = response.pages;
                        this.page = response.page;
                        this.pageSize = response.pageSize;
                        this.pageCount = response.pageCount;
                        this.totalCount = response.totalCount;

                        this.loading = false;
                    })
                    .catch(e => {
                        this.errorMessage = e;
                        this.loading = false;
                    });
            },
            applyFilter(value, values) {
                const object = values.find(item => item.value == value);
                const url = object.options.url;

                history.pushState(null, document.title, url);
                Event.$emit('pushState', {url: url});
            }
        },
        mounted() {
            this.getData();
        },
        created() {
            // this.initFilters.forEach(f => {
            //     this.$set(this.model, f.name, f.value);
            // })
            // this.filters = this.initFilters;

            Event.$on('pushState', (event) => {
                this.getData(event.url);
            });

            window.addEventListener('popstate', (event) => {
                this.getData(document.location.href);
            }, false);
        },
    }
</script>