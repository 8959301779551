<template>
    <div class="review-item" :id="'question-' + question.id">
        <header class="review-item__header">
            <div class="review-item__name">
                <span>{{ username | decode }}</span>
            </div>
            <div class="review-item__date" v-html="question.createdAt"></div>
        </header>
        <article class="review-item__content">
            <div>
                <div v-html="question.text"></div>
            </div>
            <div class="review-item__files" v-if="hasAttachments">
                <div><span class="label">Прикрепленные изображения:</span></div>
                <div class="review-item__files-list">
                    <a :href="attachment.original" v-for="attachment in question.attachments" target="_blank">
                        <img :src="attachment.thumb" >
                    </a>
                </div>
            </div>
        </article>

        <div class="answers">
            <div class="answer-item-container">
                <div class="grid">
                    <div class="answer-icon">
                        <i class="icon icon-answer-float"></i>
                    </div>
                    <div class="answer-item">
                        <header class="grid">
                            <div class="row name is-admin">
                                Ответ от <span>Avocado.ua</span>
                            </div>
                            <div class="row date"></div>
                        </header>
                        <article>
                            <p v-html="question.answer"></p>
                            <p class="signature">С уважением и благодарностью, коллектив Avocado.ua</p>
                        </article>
                        <footer>
                            <a href="#" @click.prevent="showForm">Ответить</a>
                        </footer>
                    </div>
                </div>
            </div>

            <question-item :question="question.children" v-if="question.hasChildren"></question-item>
        </div>
    </div>
</template>

<script>
    import f from 'sprintf-js';

    export default {
        name: "QuestionItem",
        props: {
            question: {
                required: true,
                type: Object
            }
        },
        data() {
            return {

            };
        },
        computed: {
            username() {
                return f.vsprintf('%s, %s', [this.question.name.toUpperCase(), this.question.city.toUpperCase()]);
            },
            hasAttachments() {
                return this.question.attachments.length > 0;
            },
        },
        methods: {
            showForm() {
                this.$emit('showForm', this.question.id);
            }
        }
    }
</script>

<style scoped>

</style>